import './EmailVerification.css'
import React from 'react';
import EmailVerificationForm from "components/shared/forms/authForms/EmailVerificationForm";

const SignUpPage = () => {

    return (
        <div className={'email-verification-page'}>
            <EmailVerificationForm/>
        </div>
    );
}

export default SignUpPage;