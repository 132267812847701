import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={800}
        height={800}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M22 16v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-4h2v4h16v-4zm-9-3.414 3.293-3.293 1.414 1.414L12 16.414l-5.707-5.707 1.414-1.414L11 12.586V2h2z"
        />
    </svg>
);
export default SVGComponent;
