import {createAsyncThunk} from "@reduxjs/toolkit";
import openAIVectorStoreFileService from "services/openAIVectorStoreFileService";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";
import PQueue from "p-queue";
import {BlobServiceClient} from "@azure/storage-blob";
import {selectOpenAIVectorStoreFilesDataByVectorStoreId} from "./openAIVectorStoreFilesSelectors";
import {selectOpenAIThreadsDataByThreadId} from "features/entities/openAIThreads/openAIThreadsSelectors";
import {selectOpenAIAssistantDataById} from "features/entities/openAIAssistants/openAIAssistantsSelectors";

const getOpenAIVectorStoreFiles = createAsyncThunk(
    'entities/openAIVectorStoreFiles/getOpenAIVectorStoreFiles',
    async ({token, openAIVectorStoreId}, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIVectorStoreFileService.getVectorStoreFilesByVectorStoreIdForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            const openaiVectorStoreFiles = ResponseJson.openai_vector_store_files;
            return {
                openai_vector_store_id: openAIVectorStoreId,
                openai_vector_store_files: openaiVectorStoreFiles
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get vector stores files.',
                details: error.message
            });
        }
    }
);

const refreshOpenAIVectorFilesStatus = createAsyncThunk(
    'entities/openAIVectorStoreFiles/renewOpenAIVectorFilesStatus',
    async ({token, openAIVectorStoreId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Refreshing corpus files status");
        try {
            const startTime = new Date().getTime();

            const ResponseJson = await openAIVectorStoreFileService.getVectorStoreFilesByVectorStoreIdForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId
            })

            const endTime = new Date().getTime();

            // ensure at least 3 seconds elapsed, to avoid too frequent refresh
            if (endTime - startTime < 3000) {
                await new Promise(resolve => setTimeout(resolve, 3000 - (endTime - startTime)));
            }

            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to refresh corpus files status!", type: "error"});
                return rejectWithValue(ResponseJson);
            }

            const openaiVectorStoreFiles = ResponseJson.openai_vector_store_files;
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus files status refreshed!", type: "success"});

            return {
                openai_vector_store_id: openAIVectorStoreId,
                openai_vector_store_files: openaiVectorStoreFiles
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to refresh corpus files status!", type: "error"});
            return rejectWithValue({
                error: 'Fail to get vector stores.',
                details: error.message
            });
        }
    }
);

const createOpenAIVectorStoreFiles = createAsyncThunk(
    'entities/openAIVectorStoreFiles/createOpenAIVectorStoreFile',
    async ( {token, openAIVectorStoreId, files}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Uploading corpus files");
        try {
            const openAIVectorStoreFilesData = selectOpenAIVectorStoreFilesDataByVectorStoreId(getState(), openAIVectorStoreId);

            const fileNames = Array.from(files).map(file => file.name);

            if (openAIVectorStoreFilesData.length + fileNames.length > 10000) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Too many files!", type: "error"});
                return rejectWithValue({
                    error: 'Too many files.',
                    details: 'A vector store can only contain at most 10000 files.'
                });
            }

            const isFileNameExist = {}
            for (const file of openAIVectorStoreFilesData) {
                isFileNameExist[file.name] = true;
            }
            for (const fileName of fileNames) {
                if (isFileNameExist[fileName]) {
                    toast.update(toastId, {
                        ...ToastifyConfig.loadingToastUpdateOptions,
                        render: "Duplicate file names detected!",
                        type: "error"
                    });
                    return rejectWithValue({
                        error: 'Duplicate file names detected.',
                        details: 'Please make sure all file names are unique.'
                    });
                }
                isFileNameExist[fileName] = true;
            }

            let response = await openAIVectorStoreFileService.generateWriteAccessSASTokensForVectorStoreFilesForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId,
                fileNames: fileNames
            });

            if (response.error) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to get SAS tokens!", type: "error"});
                return rejectWithValue(response);
            }

            const data = response['data'];
            const blobBaseUrl = data['blob_base_url'];
            const containerName = data['container_name'];
            const virtualDirectory = data['virtual_directory'];
            const sasTokens = data['sas_tokens'];

            const queue = new PQueue({ concurrency: 10 }); // 设置并发数为10
            // eslint-disable-next-line no-unused-vars
            let completed = 0;

            const updateProgress = () => {
                completed++;
                // console.log(`上传进度: ${completed}/${files.length}`);
            };

            const uploadFile = async (file) => {
                const sasToken = sasTokens[file.name];
                const blobUrl = `${blobBaseUrl}?${sasToken}`;
                const blobServiceClient = new BlobServiceClient(blobUrl);
                const containerClient = blobServiceClient.getContainerClient(containerName);
                const blockBlobClient = containerClient.getBlockBlobClient(`${virtualDirectory}/${file.name}`);

                let maxRetries = 3;

                while (maxRetries > 0) {
                    try {
                        await blockBlobClient.uploadData(file);
                        updateProgress();
                        break;
                    } catch (error) {
                        maxRetries--;
                    }
                }
            };

            // 添加所有上传任务到队列
            for (const file of files) {
                queue.add(() => uploadFile(file));
            }

            // 等待所有任务完成
            await queue.onIdle();

            response = await openAIVectorStoreFileService.commitUploadedVectorStoreFilesForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId,
                fileNames: fileNames
            });

            const openai_vector_store_files = response.openai_vector_store_files;

            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus files uploaded successfully!", type: "success"});

            return {
                openai_vector_store_id: openAIVectorStoreId,
                openai_vector_store_files: openai_vector_store_files
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to create vector store!", type: "error"});
            return rejectWithValue({
                error: 'Fail to create vector store.',
                details: error.message
            });
        }
    }
);

const downloadOpenAIVectorStoreFile = createAsyncThunk(
    'entities/openAIVectorStoreFiles/downloadOpenAIVectorStoreFile',
    async ( {token, openAIVectorStoreId, openAIVectorStoreFileId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Downloading corpus file");
        try {
            const ResponseJson = await openAIVectorStoreFileService.generateReadAccessSASTokenForVectorStoreFilesForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId,
                openAIVectorStoreFileId: openAIVectorStoreFileId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to get SAS token!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            const data = ResponseJson['data'];
            const blobBaseUrl = data['blob_base_url'];
            const containerName = data['container_name'];
            const blobName = data['blob_name'];
            const sasToken = data['sas_token'];

            const a = document.createElement('a');
            a.href = blobBaseUrl + '/' + containerName + '/' + blobName + '?' + sasToken;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus file downloaded!", type: "success"});

        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to download corpus file!", type: "error"});
            return rejectWithValue({
                error: 'Fail to download corpus file.',
                details: error.message
            });
        }
    }
);

const downloadCitedOpenAIVectorStoreFile = createAsyncThunk(
    'entities/openAIVectorStoreFiles/downloadCitedOpenAIVectorStoreFile',
    async ({token, openAIThreadId, openAIVectorStoreFileInstanceId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Downloading cited file");
        try {
            const openAIThread = selectOpenAIThreadsDataByThreadId(getState(), openAIThreadId);
            const openAIAssistantId = openAIThread.openai_assistant_id;
            const openAIAssistant = selectOpenAIAssistantDataById(getState(), openAIAssistantId);
            const openAIVectorStoreId = openAIAssistant.openai_vector_store_id;

            const ResponseJson = await openAIVectorStoreFileService.generateReadAccessSASTokenForCitedVectorStoreFilesForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId,
                openAIVectorStoreFileInstanceId: openAIVectorStoreFileInstanceId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to get SAS token!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            const data = ResponseJson['data'];
            const blobBaseUrl = data['blob_base_url'];
            const containerName = data['container_name'];
            const blobName = data['blob_name'];
            const sasToken = data['sas_token'];

            const a = document.createElement('a');
            a.href = blobBaseUrl + '/' + containerName + '/' + blobName + '?' + sasToken;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Cited file downloaded!", type: "success"});

        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to download cited file!", type: "error"});
            return rejectWithValue({
                error: 'Fail to download cited file.',
                details: error.message
            });
        }
    }
);

const deleteOpenAIVectorStoreFile = createAsyncThunk(
    'entities/openAIVectorStoreFiles/deleteOpenAIVectorStoreFile',
    async ( {token, openAIVectorStoreId, openAIVectorStoreFile}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Deleting corpus file");
        try {
            const ResponseJson = await openAIVectorStoreFileService.deleteVectorStoreFileForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId,
                openAIVectorStoreFile: openAIVectorStoreFile
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete corpus file!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus file deleted!", type: "success"});
            return {
                openai_vector_store_id: openAIVectorStoreId,
                openai_vector_store_file_id: openAIVectorStoreFile.id
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete corpus file!", type: "error"});
            return rejectWithValue({
                error: 'Fail to delete corpus.',
                details: error.message
            });
        }
    }
);

export {
    getOpenAIVectorStoreFiles,
    refreshOpenAIVectorFilesStatus,
    createOpenAIVectorStoreFiles,
    downloadOpenAIVectorStoreFile,
    downloadCitedOpenAIVectorStoreFile,
    deleteOpenAIVectorStoreFile
};