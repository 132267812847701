import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getOpenAIVectorStores,
    createOpenAIVectorStore,
    updateOpenAIVectorStore,
    deleteOpenAIVectorStore,
} from "./openAIVectorStoresThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const openAIVectorStoresSlice = createSlice({
    name: 'entities/openAIVectorStores',
    initialState,
    reducers: {
        clearOpenAIVectorStoresNewFlag : (state) => {
            state.data = state.data.map(thread => {
                if (thread.hasOwnProperty('is_new')) {
                    delete thread.is_new;
                }
                return thread;
            });
        },
        clearOpenAIVectorStores : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOpenAIVectorStores.pending, handlePending)
            .addCase(getOpenAIVectorStores.fulfilled, (state, action) => {
                state.data = action.payload.openai_vector_stores;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getOpenAIVectorStores.rejected, handleRejected)

            .addCase(createOpenAIVectorStore.pending, handlePending)
            .addCase(createOpenAIVectorStore.fulfilled, (state, action) => {
                const openai_vector_store = action.payload.openai_vector_store;
                openai_vector_store.is_new = true;
                state.data = [ ...state.data, openai_vector_store ];
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(createOpenAIVectorStore.rejected, handleRejected)

            .addCase(updateOpenAIVectorStore.pending, handlePending)
            .addCase(updateOpenAIVectorStore.fulfilled, (state, action) => {
                const updatedVectorStore = action.payload.openai_vector_store;
                state.data = state.data.map(store => {
                    if (store.id === updatedVectorStore.id) {
                        return updatedVectorStore;
                    }
                    return store;
                });
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(updateOpenAIVectorStore.rejected, handleRejected)

            .addCase(deleteOpenAIVectorStore.pending, handlePending)
            .addCase(deleteOpenAIVectorStore.fulfilled, (state, action) => {
                state.data = state.data.filter(store => store.id !== action.payload.openai_vector_store_id);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteOpenAIVectorStore.rejected, handleRejected)
    }
});

export const {
    clearOpenAIVectorStores,
    clearOpenAIVectorStoresNewFlag
} = openAIVectorStoresSlice.actions;

export default openAIVectorStoresSlice.reducer;