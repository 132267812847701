import SiteConfig from 'configs/siteConfig';

const OPENAI_ASSISTANT_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/openai-assistants`;

const openAIAssistantService = {

    getPublicAssistants: async ({token}) => {
        const endpoint = `${OPENAI_ASSISTANT_SERVICE_BASE_URL}/public`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get public openAIAssistants data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getProtectedAssistantsOfFollowedUsersForCurrentUser: async ({token}) => {
        const endpoint = `${OPENAI_ASSISTANT_SERVICE_BASE_URL}/me/followed/protected`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get subscription openAIAssistants data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getAssistantsForCurrentUser: async ({token}) => {
        const endpoint = `${OPENAI_ASSISTANT_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get private openAIAssistants data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    createOpenAIAssistantForCurrentUser: async ({token, name, description, instructions, samplePrompts, tags, accessLevel, openAIChatModelId, openAIVectorStoreId}) => {
        const endpoint = `${OPENAI_ASSISTANT_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: name,
                    description: description,
                    instructions: instructions,
                    sample_prompts: samplePrompts,
                    tags: tags,
                    access_level: accessLevel,
                    openai_chat_model_id: openAIChatModelId,
                    openai_vector_store_id: openAIVectorStoreId
                }),
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create assistant.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    updateOpenAIAssistantForCurrentUser: async ({token, openAIAssistantId, name, description, instructions, samplePrompts, tags, accessLevel, openAIChatModelId, openAIVectorStoreId}) => {
        const endpoint = `${OPENAI_ASSISTANT_SERVICE_BASE_URL}/me/${openAIAssistantId}`;
        try {
            const response = await fetch(endpoint, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_assistant_id: openAIAssistantId,
                    name: name,
                    description: description,
                    instructions: instructions,
                    sample_prompts: samplePrompts,
                    tags: tags,
                    access_level: accessLevel,
                    openai_chat_model_id: openAIChatModelId,
                    openai_vector_store_id: openAIVectorStoreId
                }),
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to update assistant.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteAssistantForCurrentUser: async ({token, openAIAssistantId}) => {
        const endpoint = `${OPENAI_ASSISTANT_SERVICE_BASE_URL}/me/${openAIAssistantId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete assistant.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }
}

export default openAIAssistantService;