import React, {useRef, useEffect, forwardRef, useState} from 'react';
import Skeleton from "react-loading-skeleton";

const AvatarWithSkeleton = forwardRef((props, ref) => {

    const { src, alt, skeletonSize, ...imgProps } = props;

    const [loaded, setLoaded] = useState(false);

    const imageRef = useRef(null);

    useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(imageRef.current);
            } else {
                ref.current = imageRef.current;
            }
        }
    }, [ref]);

    // TODO 进行覆盖显示，然后fadein

    return (
        <div
            className={'avatar-with-skeleton'}
            style={{
                width: skeletonSize,
                height: skeletonSize,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '50%',
            }}
        >
            <Skeleton  circle={true} height={skeletonSize} width={skeletonSize}/>
            <img
                src={src}
                alt={alt}
                {...imgProps}
                style={{
                    display: 'block',
                    width: skeletonSize,
                    height: skeletonSize,
                    borderRadius: '50%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                    background: 'var(--primary-fg-color)',
                }}
                onLoad={() => setLoaded(true)}
                ref={imageRef}
            />
        </div>
    );
});

export default AvatarWithSkeleton;