import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    selectOpenAIVectorStoresData,
    selectOpenAIVectorStoresStatus
} from "features/entities/openAIVectorStores/openAIVectorStoresSelectors";
import {useEffect} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import {
    clearOpenAIVectorStoresNewFlag
} from "features/entities/openAIVectorStores/openAIVectorStoresSlice";
import RoutesConfig from "configs/routesConfig";


const useNavigateToNewOpenAIVectorStore = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const openAIVectorStoresData = useSelector(state => selectOpenAIVectorStoresData(state))
    const openAIVectorStoresStatus = useSelector(state => selectOpenAIVectorStoresStatus(state))

    useEffect(() => {
        if (openAIVectorStoresStatus === AsyncStatus.SUCCESS) {
            openAIVectorStoresData.forEach(item => {
                if (item.hasOwnProperty('is_new') && item.is_new) {
                    navigate(RoutesConfig.app.knowledgeBaseStudio.openAIVectorStoreId(item.id))
                    dispatch(clearOpenAIVectorStoresNewFlag())
                }
            })
        }
    }, [openAIVectorStoresStatus, openAIVectorStoresData, dispatch, navigate])
}

export default useNavigateToNewOpenAIVectorStore;