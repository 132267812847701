import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getUsersByIds,
    getOrCreateUsersByEmails,
    updateUsersRole
} from "./usersThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const usersSlice = createSlice({
    name: 'entities/users',
    initialState,
    reducers: {
        updateUsers : (state, action) => {
            const users = action.payload.users;
            if (!state.data) {
                state.data = {};
            }
            console.log(users)
            for (let user of users) {
                state.data[user.id] = user;
            }
        },
        clearUsers : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsersByIds.pending, handlePending)
            .addCase(getUsersByIds.fulfilled, (state, action) => {
                const users = action.payload.users;
                if (!state.data) {
                    state.data = {};
                }
                for (let user of users) {
                    state.data[user.id] = user;
                }
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getUsersByIds.rejected, handleRejected)
            .addCase(getOrCreateUsersByEmails.pending, handlePending)
            .addCase(getOrCreateUsersByEmails.fulfilled, (state, action) => {
                const users = action.payload.users;
                if (!state.data) {
                    state.data = {};
                }
                for (let user of users) {
                    state.data[user.id] = user;
                }
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getOrCreateUsersByEmails.rejected, handleRejected)
            .addCase(updateUsersRole.pending, handlePending)
            .addCase(updateUsersRole.fulfilled, (state, action) => {
                const users = action.payload.users;
                if (!state.data) {
                    state.data = {};
                }
                for (let user of users) {
                    state.data[user.id] = user;
                }
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(updateUsersRole.rejected, handleRejected)
    },
});

export const {
    updateUsers,
    clearUsers
} = usersSlice.actions;

export default usersSlice.reducer;