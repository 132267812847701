import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={28}
        height={28}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14 4.667A4.666 4.666 0 1 0 14 14a4.666 4.666 0 0 0 0-9.333M7 9.333a7 7 0 1 1 14 0 7 7 0 0 1-14 0M9.333 21a3.5 3.5 0 0 0-3.5 3.5 1.167 1.167 0 0 1-2.333 0 5.833 5.833 0 0 1 5.833-5.833h9.334A5.834 5.834 0 0 1 24.5 24.5a1.167 1.167 0 1 1-2.333 0 3.5 3.5 0 0 0-3.5-3.5z"
            fill="#000"
        />
    </svg>
);
export default SVGComponent;
