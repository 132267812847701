import React, {forwardRef, useEffect, useRef} from 'react';
import './ChatMessageList.css'
import ChatMessageGrid from "components/features/chatCenter/ChatCenterMainPanel/components/ChatMessageGrid/ChatMessageGrid";


const ChatMessageList = forwardRef(({ messages, scrollPaneRef }, ref) => {

    const chatMessageListRef = useRef(null);

    // 使用 useEffect 将外部传入的 ref 和内部的 chatMessageListRef 同步
    useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(chatMessageListRef.current);
            } else {
                ref.current = chatMessageListRef.current;
            }
        }
    }, [ref]);

    useEffect(() => {
        if (!scrollPaneRef.current) return;
        const element = scrollPaneRef.current;
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (element.scrollHeight > element.clientHeight) {
                    element.scrollTop = element.scrollHeight;
                }
            });
        });
        const config = { attributes: true, childList: true, subtree: true };
        observer.observe(element, config);
        return () => observer.disconnect();
    }, [scrollPaneRef]);

    useEffect(() => {
        if (!scrollPaneRef.current || !chatMessageListRef.current) {
            return;
        }
        let scrollPane = scrollPaneRef.current;
        const resizeObserver = new ResizeObserver(() => {
            if (scrollPane.scrollHeight > scrollPane.clientHeight) {
                scrollPane.scrollTop = scrollPane.scrollHeight;
            }
        });
        resizeObserver.observe(chatMessageListRef.current);
        return () => resizeObserver.disconnect();
    }, [scrollPaneRef, chatMessageListRef]);

    return (
        <div className={"chat-message-list"} ref={chatMessageListRef}>
            {
                messages.map((message, index) => {
                    return (
                        <ChatMessageGrid
                            key={index}
                            id={message.id}
                            role={message.role}
                            content={message.content}
                            annotations={message.annotations}
                        />
                    )
                })
            }
        </div>
    );
});

export default ChatMessageList;