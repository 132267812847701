import {useEffect, useState} from "react";
import './LoadingText.css';

const LoadingText = ({text="Loading", initialNumberOfDots=3, maxNumberOfDots=6, timeGap=60}) => {

    const [numberOfDots, setNumberOfDots] = useState(initialNumberOfDots);

    useEffect(() => {
        const interval = setInterval(() => {
            setNumberOfDots((numberOfDots) => (numberOfDots+1) % (maxNumberOfDots+1));
        }, timeGap);
        return () => clearInterval(interval);
    }, [maxNumberOfDots, timeGap]);

    return (
        <>
            <span className={'loading-text'}>
                {text}
            </span>
            {
                new Array(maxNumberOfDots).fill(0).map((_, index) => (
                    <span
                        key={index}
                        className={'loading-text'}
                        style={{
                            opacity: (index < numberOfDots) ? '100%' : '0%'
                        }}
                    >
                        .
                    </span>
                ))
            }
        </>
    )
}

export default LoadingText;