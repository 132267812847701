import {createAsyncThunk} from "@reduxjs/toolkit";
import authService from "services/authService";
import {initSession} from "features/session/sessionThunks";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";

const performLogin = createAsyncThunk(
    'auth/login/performLogin',
    async ({ email, password }, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Logging in...");
        try {
            const responseJson = await authService.login({
                email: email,
                password: password
            });
            if (responseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Login failed!", type: "error"});
                return rejectWithValue(responseJson);
            }
            const token = responseJson.token;
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Login successful!", type: "success"});
            dispatch(initSession({ token }));
            return responseJson;
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Login failed!", type: "error"});
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error
            });
        }
    }
);

export {
    performLogin
};