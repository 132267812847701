import './LogoView.css'
import logo from "assets/images/logo-corpus-chat.webp";
import React from "react";
// import IconCorpusChat from "assets/vectors/IconCorpusChat";

const LogoView = ({fontSize= '1.5rem'}) => {

    const lineHeight = 1.25;

    const logoStyles = {
        width: `calc(${fontSize} * ${lineHeight})`,
        height: `calc(${fontSize} * ${lineHeight})`,
        objectFit: 'contain'
    }

    const headerStyles = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(${fontSize} * ${lineHeight})`
    }

    const textStyles = {
        fontSize: fontSize,
        fontWeight: 700,
        textAlign: 'center'
    }

    return (
        <div
            className={'logo-view no-select'}
            style={{
                gap: `calc(${fontSize} / 3)`
            }}
        >
            <img
                src={logo}
                alt="logo"
                className={"logo"}
                style={logoStyles}
            />
            {/*<IconCorpusChat className={'logo'} style={logoStyles}/>*/}

            <h1
                style={headerStyles}
            >
                <span
                    style={{color: '#0049B0', ...textStyles}}
                >
                    Corpus
                </span>
                <span
                    style={{color: '#FF914C', ...textStyles}}
                >
                    Chat
                </span>
            </h1>
        </div>
    )
}

export default LogoView;