import {createAsyncThunk} from "@reduxjs/toolkit";
import userService from "services/userService";

const getUsersByIds = createAsyncThunk(
    'entities/users/getUsersByIds',
    async ( {token, userIds }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userService.getUsersByIds({
                token: token,
                userIds: userIds
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            const users = responseJson.users;
            return {
                users: users
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get users data.',
                details: error.message
            });
        }
    }
);

const getOrCreateUsersByEmails = createAsyncThunk(
    'entities/users/getOrCreateUsersByEmails',
    async ( {token, emails }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userService.getOrCreateUsersByEmails({
                token: token,
                emails: emails
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                users: responseJson.users
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to create users by emails.',
                details: error.message
            });
        }
    }
);

const updateUsersRole = createAsyncThunk(
    'entities/users/updateUsersRole',
    async ( {token, userIds, role }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userService.updateUsersRole({
                token: token,
                userIds: userIds,
                role: role
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                users: responseJson.users
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to update users role.',
                details: error.message
            });
        }
    }
);

export {
    getUsersByIds,
    getOrCreateUsersByEmails,
    updateUsersRole
};