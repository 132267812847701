import './ExpandableTextarea.css';
import AutoHeightTextArea from "../../../../../core/AutoHeightTextArea/AutoHeightTextArea";

const ExpandableTextarea = (props) => {

    return (
        <div className={'expandable-textarea-wrapper'}>
            <AutoHeightTextArea
                className={'expandable-textarea'}
                {...props}
            />
        </div>
    );
}

export default ExpandableTextarea;