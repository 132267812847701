import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getOpenAIThreads,
    createOpenAIThread,
    deleteOpenAIThread,
    updateOpenAIThreadName,
    renewOpenAIThread
} from "./openAIThreadsThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const openAIThreadsSlice = createSlice({
    name: 'entities/openAIThreads',
    initialState,
    reducers: {
        clearOpenAIThreadsNewFlag : (state) => {
            state.data = state.data.map(thread => {
                if (thread.hasOwnProperty('is_new')) {
                    delete thread.is_new;
                }
                return thread;
            });
        },
        clearOpenAIThreads : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOpenAIThreads.pending, handlePending)
            .addCase(getOpenAIThreads.fulfilled, (state, action) => {
                state.data = action.payload.openai_threads;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getOpenAIThreads.rejected, handleRejected)

            .addCase(createOpenAIThread.pending, handlePending)
            .addCase(createOpenAIThread.fulfilled, (state, action) => {
                const openai_thread = action.payload.openai_thread;
                openai_thread.is_new = true;
                state.data = [ ...state.data, openai_thread ];
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(createOpenAIThread.rejected, handleRejected)

            .addCase(updateOpenAIThreadName.pending, handlePending)
            .addCase(updateOpenAIThreadName.fulfilled, (state, action) => {
                const updatedThread = action.payload.openai_thread;
                state.data = state.data.map(thread => {
                    if (thread.id === updatedThread.id) {
                        return updatedThread;
                    }
                    return thread;
                });
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(updateOpenAIThreadName.rejected, handleRejected)

            .addCase(renewOpenAIThread.pending, handlePending)
            .addCase(renewOpenAIThread.fulfilled, (state, action) => {
                const updatedThread = action.payload.openai_thread;
                state.data = state.data.map(thread => {
                    if (thread.id === updatedThread.id) {
                        return updatedThread;
                    }
                    return thread;
                });
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(renewOpenAIThread.rejected, handleRejected)

            .addCase(deleteOpenAIThread.pending, handlePending)
            .addCase(deleteOpenAIThread.fulfilled, (state, action) => {
                state.data = state.data.filter(thread => thread.id !== action.payload.openai_thread_id);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteOpenAIThread.rejected, handleRejected)
    }
});

export const {
    clearOpenAIThreads,
    clearOpenAIThreadsNewFlag
} = openAIThreadsSlice.actions;

export default openAIThreadsSlice.reducer;