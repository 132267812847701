import './LoginPage.css'
import React from "react";
import LoginForm from "components/shared/forms/authForms/LoginForm";

const LoginPage = () => {

    return (
        <div className={'login-page'}>
            <LoginForm/>
        </div>
    );
}

export default LoginPage;