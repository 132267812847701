import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";
import App from './App';
import store from "./redux/store";
import ToastifyConfig from "configs/toastifyConfig";
import "react-toastify/dist/ReactToastify.css";
import 'i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastContainer
                autoClose={ToastifyConfig.autoClose}
                position="top-right"
                draggable={true}
                style={{top: '3.75rem'}}
            />
            <App />
        </Provider>
    </React.StrictMode>
);
