import {useEffect} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import {clearOpenAIThreadsNewFlag} from "features/entities/openAIThreads/openAIThreadsSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    selectOpenAIThreadsData,
    selectOpenAIThreadsStatus
} from "features/entities/openAIThreads/openAIThreadsSelectors";
import RoutesConfig from "configs/routesConfig";

const useNavigateToNewThread = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const openAIThreadsData = useSelector(state => selectOpenAIThreadsData(state))
    const openAIThreadsStatus = useSelector(state => selectOpenAIThreadsStatus(state))

    useEffect(() => {
        if (openAIThreadsStatus === AsyncStatus.SUCCESS) {
            openAIThreadsData.forEach(item => {
                if (item.hasOwnProperty('is_new') && item.is_new) {
                    navigate(RoutesConfig.app.chatCenter.openAIThreadId(item.id))
                    dispatch(clearOpenAIThreadsNewFlag())
                }
            })
        }
    }, [openAIThreadsStatus, openAIThreadsData, dispatch, navigate])

}

export default useNavigateToNewThread;