import './AssistantFavoriteButton.css'
import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    selectUserFavoriteOpenAIAssistantsData,
    selectUserFavoriteOpenAIAssistantsStatus
} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsSelectors";
import {AsyncStatus} from "constants/asyncStatus";
import {
    createUserFavoriteOpenAIAssistant,
    deleteUserFavoriteOpenAIAssistant
} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsThunks";
import {useEffect, useState} from "react";
import IconStarSolid from "assets/vectors/IconStarSolid";
import IconStarHollow from "assets/vectors/IconStarHollow";

const AssistantFavoriteButton = ({openAIAssistantId}) => {

    const dispatch = useDispatch();
    const sessionToken = useSelector(selectSessionToken);

    const [userFavoriteOpenAIAssistantIds, setUserFavoriteOpenAIAssistantIds] = useState([])
    const [isFavorite, setIsFavorite] = useState(false)

    const userFavoriteOpenAIAssistantsData = useSelector(selectUserFavoriteOpenAIAssistantsData);
    const userFavoriteOpenAIAssistantsStatus = useSelector(selectUserFavoriteOpenAIAssistantsStatus);

    useEffect(() => {
        if (! openAIAssistantId) return
        if (userFavoriteOpenAIAssistantsStatus === AsyncStatus.SUCCESS) {
            const userFavoriteOpenAIAssistantIds = userFavoriteOpenAIAssistantsData.map(item => item.openai_assistant_id)
            setUserFavoriteOpenAIAssistantIds(userFavoriteOpenAIAssistantIds)
        }
    }, [userFavoriteOpenAIAssistantsStatus, userFavoriteOpenAIAssistantsData, openAIAssistantId])

    useEffect(() => {
        if (! openAIAssistantId) return
        if (userFavoriteOpenAIAssistantIds.includes(openAIAssistantId)) {
            setIsFavorite(true)
        } else {
            setIsFavorite(false)
        }
    }, [userFavoriteOpenAIAssistantIds, openAIAssistantId])

    const handleOnFavoriteButtonClicked = () => {
        if (! openAIAssistantId) return
        if (userFavoriteOpenAIAssistantsStatus === AsyncStatus.PENDING) return
        if (! sessionToken) return
        if (isFavorite) {
            const userFavoriteOpenAIAssistantId = userFavoriteOpenAIAssistantsData.find(item => item.openai_assistant_id === openAIAssistantId)?.id
            if (! userFavoriteOpenAIAssistantId) {
                return
            }
            dispatch(deleteUserFavoriteOpenAIAssistant({
                token: sessionToken,
                userFavoriteOpenAIAssistantId: userFavoriteOpenAIAssistantId,
            }))
        }
        else {
            dispatch(createUserFavoriteOpenAIAssistant({
                token: sessionToken,
                openAIAssistantId: openAIAssistantId,
            }))
        }
    }

    return (
        <div
            className={'assistant-favorite-button'}
            onClick={handleOnFavoriteButtonClicked}
        >
            {isFavorite ? <IconStarSolid/> : <IconStarHollow/>}
        </div>
    )
}

export default AssistantFavoriteButton;