import './CodeBlock.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import IconCopy from "assets/vectors/IconCopy";
import {toast} from "react-toastify";

const CodeBlock = ({ node, inline, className, children, ...props }) => {

    const match = /language-(\w+)/.exec(className || '');

    if (inline) {
        return (
            <code className={`inline-code-block ${className}`} {...props}>
                {children}
            </code>
        )
    }

    return (
        <div className={"code-block"} aria-hidden="false">
            <div className={"header no-select"}>
                <span className={"language"}>
                    {match ? match[1] : "code"}
                </span>
                <div className={'copy-button'} onClick={() => {
                    navigator.clipboard.writeText(String(children).replace(/\n$/, ''))
                    toast.success('Copied to clipboard')
                }}>
                    <IconCopy/>&nbsp;Copy Code
                </div>
            </div>
            <SyntaxHighlighter
                style={coldarkDark}
                PreTag="div"
                language={match ? match[1] : null}
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        </div>
    )
};

export default CodeBlock;