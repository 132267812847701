import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.5 12.0405L3.17354 14.3151L4 9.4973L0.5 6.08562L5.33677 5.38324L7.5 1L9.66323 5.38324L14.5 6.08562L11 9.4973L11.8265 14.3151L7.5 12.0405Z"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SVGComponent;
