import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.65 10.63 7.6 22.68l-.788 2.688 2.633-.761 12.091-12.091zm2.057-2.057 1.885 1.886L25.349 8.7a.667.667 0 0 0 0-.942l-.944-.943a.667.667 0 0 0-.942 0zm4.585-3.642.943.942a3.333 3.333 0 0 1 0 4.714l-16.39 16.39-5.626 1.627a1.333 1.333 0 0 1-1.65-1.655l1.664-5.673L21.58 4.929a3.333 3.333 0 0 1 4.713 0z"
            fill="#212121"
        />
    </svg>
);
export default SVGComponent;
