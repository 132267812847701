import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {getAllOpenAIChatModels} from "./openAIChatModelsThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const openAIChatModelsSlice = createSlice({
    name: 'entities/openAIChatModels',
    initialState,
    reducers: {
        clearOpenAIChatModels : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOpenAIChatModels.pending, handlePending)
            .addCase(getAllOpenAIChatModels.fulfilled, (state, action) => {
                state.data = action.payload.models;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getAllOpenAIChatModels.rejected, handleRejected);
    },
});

export const { clearOpenAIChatModels  } = openAIChatModelsSlice.actions;

export default openAIChatModelsSlice.reducer;