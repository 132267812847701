import {
    renewOpenAIThread
} from "features/entities/openAIThreads/openAIThreadsThunks";
import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";


const useClearOpenAIThreadMessages = ({openAIThreadId}) => {

    const dispatch = useDispatch()
    const sessionToken = useSelector(selectSessionToken);

    const clearMessages =  async () => {
        if (!sessionToken) {
            return;
        }
        dispatch(renewOpenAIThread({
            token: sessionToken,
            openAIThreadId: openAIThreadId,
        }))
    }

    return {
        clearMessages
    }
}

export default useClearOpenAIThreadMessages;