import {createAsyncThunk} from "@reduxjs/toolkit";
import userFollowerService from "services/userFollowerService";
import {getUsersByIds} from "../users/usersThunks";
import {toast} from "react-toastify";
import ToastifyConfig from "../../../configs/toastifyConfig";

const getUserFollowers = createAsyncThunk(
    'entities/userFollowers/getUserFollowers',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userFollowerService.getUserFollowersForCurrentUser({
                token: token,
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            const user_followers = responseJson.user_followers;
            dispatch(getUsersByIds({
                token: token,
                userIds: user_followers.map(user_follower => user_follower.follower_id)
            }))
            return {
                user_followers: user_followers
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get user followers for current user.',
                details: error.message
            });
        }
    }
);

const getOrCreateUserFollowers = createAsyncThunk(
    'entities/userFollowers/getOrCreateUserFollowers',
    async ( {token, followerIds }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userFollowerService.getOrCreateUserFollowersForCurrentUser({
                token: token,
                followerIds: followerIds
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                user_followers: responseJson.user_followers
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to create user followers for current user.',
                details: error.message
            });
        }
    }
);

const deleteUserFollower = createAsyncThunk(
    'entities/userFollowers/deleteUserFollower',
    async ( {token, userFollowerId }, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Deleting follower...");
        try {
            const responseJson = await userFollowerService.deleteUserFollowerForCurrentUser({
                token: token,
                userFollowerId: userFollowerId
            })
            if (responseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Failed to delete follower!", type: "error"});
                return rejectWithValue(responseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Follower deleted!", type: "success"});
            return {
                user_follower_id: responseJson.user_follower_id
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete user follower!", type: "error"});
            return rejectWithValue({
                error: 'Fail to delete user follower for current user.',
                details: error.message
            });
        }
    }
);

export {
    getUserFollowers,
    getOrCreateUserFollowers,
    deleteUserFollower
};