import {createAsyncThunk} from "@reduxjs/toolkit";
import transactionService from "services/transactionService";
import {setUser} from "features/entities/user/userSlice";
import {updateUsers} from "features/entities/users/usersSlice";
import {toast} from "react-toastify";
import ToastifyConfig from "../../../configs/toastifyConfig";


const transferQuotaToUsers = createAsyncThunk(
    'entities/transactions/transferQuotaToUsers',
    async ( {token, recipientUserIds, amount}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading('Transferring quota...');
        try {
            const responseJson = await transactionService.transferQuotaToUsersForCurrentUser({
                token: token,
                recipientUserIds: recipientUserIds,
                amount: amount
            })
            if (responseJson.hasOwnProperty("error")) {
                toast.update(toastId, {
                    ...ToastifyConfig.loadingToastUpdateOptions,
                    render: "Failed to transfer quota to users!",
                    type: "error"
                });
                return rejectWithValue(responseJson);
            }
            // get payload from response
            const sender = responseJson.sender;
            const recipients = responseJson.recipients;
            // Update user and followers
            dispatch(setUser({
                user: sender
            }))
            dispatch(updateUsers({
                users: recipients
            }))
            // Update toast
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Quota transferred!",
                type: "success"
            });
            return {
                sender: sender,
                recipients: recipients
            }
        } catch (error) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Failed to transfer quota to users!",
                type: "error"
            });
            return rejectWithValue({
                error: 'Fail to transfer quota to users.',
                details: error.message
            });
        }
    }
);

export {
    transferQuotaToUsers
}
