import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";

import {AsyncStatus} from "constants/asyncStatus";
import {
    selectSessionStatus,
    selectSessionToken
} from "features/session/sessionSelectors";
import {
    selectOpenAIThreadsData,
    selectOpenAIThreadsDataByThreadId, selectOpenAIThreadsStatus
} from "features/entities/openAIThreads/openAIThreadsSelectors";
import {
    getOpenAIThreadMessagesByThreadId
} from "features/entities/openAIThreadMessages/openAIThreadMessagesThunks";
import RoutesConfig from "configs/routesConfig";


const useFetchOpenAIThreadMessages = ({openAIThreadId}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadedOpenAIThreadIds = useRef([])

    const sessionToken = useSelector(selectSessionToken);
    const sessionStatus = useSelector(state => selectSessionStatus(state));

    const openAIThread = useSelector(state => selectOpenAIThreadsDataByThreadId(state, openAIThreadId));
    const openAIThreadsData = useSelector(state => selectOpenAIThreadsData(state));
    const openAIThreadsStatus = useSelector(state => selectOpenAIThreadsStatus(state));

    // 从后端获取消息
    useEffect(() => {
        if (sessionStatus !== AsyncStatus.SUCCESS) return
        if (openAIThreadsStatus !== AsyncStatus.SUCCESS) return
        if (loadedOpenAIThreadIds.current.includes(openAIThreadId)) return
        if (! Array.isArray(openAIThreadsData)) return
        if (! openAIThreadsData.find(thread => thread.id === openAIThreadId)) {
            navigate(RoutesConfig.app.CHAT_CENTER)
        }
        if (! openAIThread) return
        dispatch(getOpenAIThreadMessagesByThreadId({
            token: sessionToken,
            openAIThreadId: openAIThreadId
        }))
        loadedOpenAIThreadIds.current.push(openAIThreadId)
    }, [dispatch, sessionToken, sessionStatus, openAIThreadId, openAIThreadsStatus, openAIThreadsData, openAIThread, navigate])

}

export default useFetchOpenAIThreadMessages;