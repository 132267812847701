import {createSlice} from "@reduxjs/toolkit";
import {performSignUp} from "./signUpThunks";
import {AsyncStatus} from "constants/asyncStatus";

const initialState = {
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const signUpSlice = createSlice({
    name: 'auth/signUp',
    initialState,
    reducers: {
        clearSignUp : (state) => {
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(performSignUp.pending, (state) => {
                state.status =  AsyncStatus.PENDING;
            })
            .addCase(performSignUp.fulfilled, (state, action) => {
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(performSignUp.rejected, (state, action) => {
                state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
                state.status = AsyncStatus.ERROR;
            });
    },
});

export const { clearSignUp  } = signUpSlice.actions;

export default signUpSlice.reducer;