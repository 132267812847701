import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getUserFollowers,
    getOrCreateUserFollowers,
    deleteUserFollower
} from "./userFollowersThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const userFollowersSlice = createSlice({
    name: 'entities/userFollowers',
    initialState,
    reducers: {
        clearUserFollowers : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserFollowers.pending, handlePending)
            .addCase(getUserFollowers.fulfilled, (state, action) => {
                const userFollowers = action.payload.user_followers;
                state.data = (state.data || []).concat(userFollowers);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getUserFollowers.rejected, handleRejected)

            .addCase(getOrCreateUserFollowers.pending, handlePending)
            .addCase(getOrCreateUserFollowers.fulfilled, (state, action) => {
                const userFollowers = action.payload.user_followers;
                const updatedData = state.data?.slice() || [];
                userFollowers.forEach((follower) => {
                    const index = updatedData.findIndex(item => item.id === follower.id);
                    if (index !== -1) {
                        // 更新已有的项目
                        updatedData[index] = follower;
                    } else {
                        // 添加新的项目
                        updatedData.push(follower);
                    }
                });
                state.data = updatedData;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getOrCreateUserFollowers.rejected, handleRejected)

            .addCase(deleteUserFollower.pending, handlePending)
            .addCase(deleteUserFollower.fulfilled, (state, action) => {
                const userFollowerId = action.payload.user_follower_id;
                state.data = state.data.filter((userFollower) => userFollower.id !== userFollowerId);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteUserFollower.rejected, handleRejected);
    },
});

export const {
    clearUserFollowers
} = userFollowersSlice.actions;

export default userFollowersSlice.reducer;