import {createAsyncThunk} from "@reduxjs/toolkit";
import openAIThreadService from "services/openAIThreadService";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";
import {renewOpenAIThreadMessagesByThreadId} from "../openAIThreadMessages/openAIThreadMessagesSlice";

const getOpenAIThreads = createAsyncThunk(
    'entities/openAIThreads/getOpenAIThreads',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIThreadService.getThreadsForCurrentUser({
                token: token
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            return {
                openai_threads: ResponseJson.openai_threads
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get threads.',
                details: error.message
            });
        }
    }
);

const createOpenAIThread = createAsyncThunk(
    'entities/openAIThreads/createOpenAIThread',
    async ( {token, openAIAssistantId, name, description}, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIThreadService.createThreadForCurrentUser({
                token: token,
                openAIAssistantId: openAIAssistantId,
                name: name,
                description: description,
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            const openai_thread = ResponseJson.openai_thread;
            await dispatch(renewOpenAIThreadMessagesByThreadId({
                openAIThreadId: openai_thread.id,
            }))
            return {
                openai_thread:openai_thread
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to create thread.',
                details: error.message
            });
        }
    }
);

const updateOpenAIThreadName = createAsyncThunk(
    'entities/openAIThreads/updateOpenAIThreadName',
    async ( {token, openAIThreadId, name}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Updating chat name...");
        try {
            const ResponseJson = await openAIThreadService.updateThreadNameForCurrentUser({
                token: token,
                openAIThreadId: openAIThreadId,
                name: name
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to update thread name!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            const openai_thread = ResponseJson.openai_thread;
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chat name updated!", type: "success"});
            return {
                openai_thread: openai_thread
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to update thread name!", type: "error"});
            return rejectWithValue({
                error: 'Fail to update thread name.',
                details: error.message
            });
        }
    }
);

const renewOpenAIThread = createAsyncThunk(
    'entities/openAIThreads/renewOpenAIThread',
    async ( {token, openAIThreadId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Clearing messages...");
        try {
            const response = await openAIThreadService.renewThreadForCurrentUser({
                token: token,
                openAIThreadId: openAIThreadId
            })
            if (response.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to clear messages!", type: "error"});
                return rejectWithValue(response);
            }
            const openai_thread = response.openai_thread;
            await dispatch(renewOpenAIThreadMessagesByThreadId({
                openAIThreadId: openAIThreadId,
            }))
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Messages cleared!", type: "success"});
            return {
                openai_thread: openai_thread
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to clear messages!", type: "error"});
            return rejectWithValue({
                error: 'Fail to clear messages.',
                details: error.message
            });
        }
    }
);

const deleteOpenAIThread = createAsyncThunk(
    'entities/openAIThreads/deleteOpenAIThread',
    async ( {token, openAIThreadId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Deleting chat...");
        try {
            const ResponseJson = await openAIThreadService.deleteThreadForCurrentUser({
                token: token,
                openAIThreadId: openAIThreadId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete thread!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Chat deleted!", type: "success"});
            return {
                openai_thread_id: openAIThreadId
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete thread!", type: "error"});
            return rejectWithValue({
                error: 'Fail to delete thread.',
                details: error.message
            });
        }
    }
);

export {
    getOpenAIThreads,
    createOpenAIThread,
    updateOpenAIThreadName,
    renewOpenAIThread,
    deleteOpenAIThread
};