import SecondaryButton from "components/core/SecondaryButton/SecondaryButton";
import PrimaryButton from "components/core/PrimaryButton/PrimaryButton";
import React, {useCallback} from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";
import {transferQuotaToUsers} from "features/entities/Transactions/transactionsThunks";

const QuotaTransferForm = ({userEmail, userId, onClose}) => {

    const dispatch = useDispatch()

    const sessionToken = useSelector(state => selectSessionToken(state))

    const [quota, setQuota] = React.useState("");

    const handleSubmit = useCallback(
        async  (event) => {
            event.preventDefault();
            // Validate quota
            let quotaNumber = parseFloat(quota)
            if (isNaN(quotaNumber) || quotaNumber <= 0) {
                toast.error('Invalid quota amount');
                return
            }
            quotaNumber = quotaNumber / 100;
            // close the form
            onClose();
            // transfer quota
            dispatch(transferQuotaToUsers({
                token: sessionToken,
                recipientUserIds: [userId,],
                amount: quotaNumber
            }))
        }
    , [quota, sessionToken, userId, dispatch, onClose])

    return (
        <form className={"edit-form"} onSubmit={handleSubmit}>

            <h2 className={'title'}>Transfer Quota</h2>

            <label className={"required"}>Recipient</label>
            <input
                type="text"
                value={userEmail}
                disabled={true}
            />

            <label className={"required"}>Quota</label>
            <input
                type="text"
                value={quota || ''}
                onChange={(event) => setQuota(event.target.value)}
                required
            />

            <div className={'button-group'}>
                <SecondaryButton type={'button'}
                    onClick={(event) => {onClose()}}
                >
                    Cancel
                </SecondaryButton>
                <PrimaryButton type="submit">
                    Transfer
                </PrimaryButton>
            </div>

        </form>
    )
}

export default QuotaTransferForm;