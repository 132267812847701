import { createSlice } from '@reduxjs/toolkit';
import {initSession, resumeSession} from './sessionThunks';
import {AsyncStatus} from "constants/asyncStatus";

const initialState = {
    isAuthenticated: false,
    token: null,
    status:  AsyncStatus.IDLE,
    error: null,
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleFulfilled = (state, action) => {
    state.status = AsyncStatus.SUCCESS;
    state.isAuthenticated = true;
    state.token = action.payload.token;
}

const handleRejected = (state, action) =>  {
    state.status = AsyncStatus.ERROR;
    state.isAuthenticated = false;
    state.token = null;
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        clearSession: (state) => {
            state.isAuthenticated = false;
            state.token = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initSession.pending, handlePending)
            .addCase(initSession.fulfilled, handleFulfilled)
            .addCase(initSession.rejected, handleRejected)

            .addCase(resumeSession.pending, handlePending)
            .addCase(resumeSession.fulfilled, handleFulfilled)
            .addCase(resumeSession.rejected, handleRejected)
    },
});

export const { clearSession } = sessionSlice.actions;

export default sessionSlice.reducer;