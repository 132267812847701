import './styles/AuthForm.css';
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import IconEye from "assets/vectors/IconEye";
import IconEyeSlash from "assets/vectors/IconEyeSlash";
import {useDispatch, useSelector} from "react-redux";
import {performLogin} from "features/auth/login/loginThunks";
import {selectLoginStatus} from "features/auth/login/loginSelectors";
import {AsyncStatus} from "constants/asyncStatus";
import {clearLogin} from "features/auth/login/loginSlice";
import RoutesConfig from "configs/routesConfig";

const LoginForm = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loginStatus = useSelector(selectLoginStatus)

    const [isEditable, setIsEditable] = useState(true);

    const [tokenChecked, setTokenChecked] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditable(false);
        e.target.email.blur();
        e.target.password.blur();
        const email = e.target.email.value;
        const password = e.target.password.value;
        console.log(email, password)
        dispatch(performLogin({
            email: email,
            password: password
        }));
    }

    useEffect(() => {
        if (! tokenChecked) {
            if (localStorage.getItem('token') !== null) {
                navigate(RoutesConfig.app.CHAT_CENTER);
            } else {
                setTokenChecked(true);
            }
        }
    }, [navigate, tokenChecked]);

    useEffect(() => {
        if (loginStatus === AsyncStatus.SUCCESS) {
            dispatch(clearLogin());
            navigate(RoutesConfig.app.CHAT_CENTER);
        }
        else if (loginStatus === AsyncStatus.ERROR){
            dispatch(clearLogin());
            setIsEditable(true);
        }
    }, [dispatch, loginStatus, navigate]);

    if (! tokenChecked) {
        return null;
    }

    return (
        <form className={"auth-form"} onSubmit={handleSubmit}>

            <h1 className={"title"}>
                Login
            </h1>

            <div className={"input-group"}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" disabled={!isEditable} required/>
            </div>

            <div className={"input-group"}>
                <label htmlFor="password">Password</label>
                <input type={showPassword ? "text" : "password"} id="password" name="password" disabled={!isEditable} required/>
                <div
                    className={`show-password`}
                    onClick={(event) => {
                        setShowPassword(prev => !prev);
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onMouseDown={(event) => {
                        event.currentTarget.previousElementSibling.previousElementSibling.focus();
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onMouseUp={(event) => {
                        event.currentTarget.previousElementSibling.previousElementSibling.focus();
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    {showPassword ? <IconEyeSlash/> : <IconEye/>}
                </div>
            </div>

            <div className={"links no-select"}>
                <p onClick={() => {
                    navigate(RoutesConfig.auth.SIGN_UP);
                }}>Do not have a account? Sign Up Here!</p>
            </div>

            <button type="submit" disabled={!isEditable} >
                Login
            </button>

        </form>
    );
}

export default LoginForm;