import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getUserFavoriteOpenAIAssistants,
    createUserFavoriteOpenAIAssistant,
    deleteUserFavoriteOpenAIAssistant
} from "./userFavoriteOpenAIAssistantsThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const userFavoriteOpenAIAssistantsSlice = createSlice({
    name: 'entities/userFavoriteOpenAIAssistants',
    initialState,
    reducers: {
        clearUserFavoriteOpenAIAssistants : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserFavoriteOpenAIAssistants.pending, handlePending)
            .addCase(getUserFavoriteOpenAIAssistants.fulfilled, (state, action) => {
                state.data = action.payload.user_favorite_openai_assistants;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getUserFavoriteOpenAIAssistants.rejected, handleRejected)

            .addCase(createUserFavoriteOpenAIAssistant.pending, handlePending)
            .addCase(createUserFavoriteOpenAIAssistant.fulfilled, (state, action) => {
                state.data = [...state.data, action.payload.user_favorite_openai_assistant];
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(createUserFavoriteOpenAIAssistant.rejected, handleRejected)

            .addCase(deleteUserFavoriteOpenAIAssistant.pending, handlePending)
            .addCase(deleteUserFavoriteOpenAIAssistant.fulfilled, (state, action) => {
                state.data = state.data.filter((userFavoriteOpenAIAssistant) => userFavoriteOpenAIAssistant.id !== action.payload.user_favorite_openai_assistant_id);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteUserFavoriteOpenAIAssistant.rejected, handleRejected);
    },
});

export const { clearUserFavoriteOpenAIAssistants  } = userFavoriteOpenAIAssistantsSlice.actions;

export default userFavoriteOpenAIAssistantsSlice.reducer;