import {createSlice} from "@reduxjs/toolkit";
import {PseudoOpenAIThreadMessageId} from "constants/pseudoOpenAIThreadMessageId";
import {OpenAIMessageRole} from "constants/openAIMessageRole";
import {UIDataStatus} from "constants/uiDataStatus";

const initialState = {
    "pseudo_openai_thread_id": {
        "name": "string",
        "messages": [
            {
                "id": PseudoOpenAIThreadMessageId.USER,
                "role": OpenAIMessageRole.USER,
                "content": "",
            },
        ],
        "sample_prompts": [
            "string",
            "string",
        ],
        "status": "string",
    }
}

const mergeMessages = (originalMessages, newMessages) => {
    // If both originalMessages and newMessages are not arrays, return null
    if (!Array.isArray(originalMessages) && !Array.isArray(newMessages)) {
        return null;
    }
    // If only one of originalMessages and newMessages is an array, return the other
    if (!Array.isArray(originalMessages) || !Array.isArray(newMessages)) {
        return (Array.isArray(originalMessages) ? originalMessages : newMessages);
    }
    // TODO 这个函数的逻辑有点乱。需要改正。
    // 懒得改。直接用special case了。
    if (newMessages.length === 0) {
        return newMessages;
    }
    // remove duplicate messages
    const originalMessageIds = {};
    originalMessages.forEach(message => {
        originalMessageIds[message.id] = true;
    });
    newMessages = newMessages.filter(newMessage => !originalMessageIds[newMessage.id]);
    // prepare a buffer for result
    let mergedMessages = [...originalMessages];
    // find the position of pseudo user message and pseudo assistant message
    let pseudoUserMessageIndex = mergedMessages.findIndex(message => message.id === PseudoOpenAIThreadMessageId.USER);
    let pseudoAssistantMessageIndex = mergedMessages.findIndex(message => message.id === PseudoOpenAIThreadMessageId.ASSISTANT);
    // add the non-duplicate messages to the buffer
    newMessages.forEach(message => {
        switch (message.role) {
            case OpenAIMessageRole.USER: {
                if (pseudoUserMessageIndex !== -1) {
                    mergedMessages[pseudoUserMessageIndex] = message;
                    pseudoUserMessageIndex = -1;
                }
                else {
                    mergedMessages.push(message);
                }
                break;
            }
            case OpenAIMessageRole.ASSISTANT: {
                if (pseudoAssistantMessageIndex !== -1) {
                    mergedMessages[pseudoAssistantMessageIndex] = message;
                    pseudoAssistantMessageIndex = -1;
                }
                else {
                    mergedMessages.push(message);
                }
                break;
            }
            default: {
                break;
            }
        }
    })
    return mergedMessages;
}

const ChatMainPanelSlice = createSlice({
    name: 'ui/mainPanels/chatMainPanel',
    initialState,
    reducers: {
        setChatMainPanel : (state, action) => {
            state = action.payload;
        },
        setChatMainPanelThread : (state, action) => {
            const openAIThreadId = action.payload.openAIThreadId;
            if (!openAIThreadId) {
                return;
            }
            const openAIThreadName = action.payload.openAIThreadName;
            const openAIThreadMessages = mergeMessages(state[openAIThreadId]?.messages, action.payload.openAIThreadMessages)
            const openAIAssistantSamplePrompts = action.payload.openAIAssistantSamplePrompts;
            let buffer = {
                name: openAIThreadName || null,
                messages: openAIThreadMessages || null,
                sample_prompts: openAIAssistantSamplePrompts || null,
            }
            let status = UIDataStatus.DATA_READY;
            for (let key in buffer) {
                if (buffer[key] === null) {
                    status = UIDataStatus.DATA_LOADING;
                    break;
                }
            }
            buffer.status = status;
            state[openAIThreadId] = buffer;
        },
        createChatMainPanelThreadMessageWithId : (state, action) => {
            const openAIThreadId = action.payload.openAIThreadId;
            const openAIThreadMessage = action.payload.openAIThreadMessage;
            if (!state[openAIThreadId] || !openAIThreadMessage) {
                return;
            }
            state[openAIThreadId].messages.push(openAIThreadMessage);
        },
        updateChatMainPanelThreadMessageById : (state, action) => {
            const openAIThreadId = action.payload.openAIThreadId;
            const openAIThreadMessage = action.payload.openAIThreadMessage;
            if (!state[openAIThreadId] || !openAIThreadMessage) {
                return;
            }
            state[openAIThreadId].messages = state[openAIThreadId].messages.map(message => {
                if (message.id === openAIThreadMessage.id) {
                    return openAIThreadMessage;
                }
                return message;
            })
        },
        clearChatMainPanel : (state) => {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
    },
});

export const {
    setChatMainPanel,
    setChatMainPanelThread,
    createChatMainPanelThreadMessageWithId,
    updateChatMainPanelThreadMessageById,
    clearChatMainPanel
} = ChatMainPanelSlice.actions;

export default ChatMainPanelSlice.reducer;