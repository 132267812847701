import {useCallback} from "react";
import {useSelector} from "react-redux";

import {AsyncStatus} from "constants/asyncStatus";
import {
    selectOpenAIThreadsDataByThreadId,
    selectOpenAIThreadsStatus
} from "features/entities/openAIThreads/openAIThreadsSelectors";
import {
    selectOpenAIThreadMessagesDataByThreadId,
    selectOpenAIThreadMessagesStatus
} from "features/entities/openAIThreadMessages/openAIThreadMessagesSelectors";
import {
    selectOpenAIAssistantsData,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";

const useDownloadOpenAIThreadMessages = ({openAIThreadId}) => {
    
    const openAIThread = useSelector(state => selectOpenAIThreadsDataByThreadId(state, openAIThreadId));
    const openAIThreadsStatus = useSelector(state => selectOpenAIThreadsStatus(state));
    const openAIThreadMessages = useSelector(state => selectOpenAIThreadMessagesDataByThreadId(state, openAIThreadId));
    const openAIThreadMessagesStatus = useSelector(state => selectOpenAIThreadMessagesStatus(state));

    const openAIAssistantsData = useSelector(state => selectOpenAIAssistantsData(state));
    const openAIAssistantsStatus = useSelector(state => selectOpenAIAssistantsStatus(state));

    const saveOpenAIThreadMessagesAsTXT = useCallback(() => {
        if (openAIThreadsStatus !== AsyncStatus.SUCCESS || ! openAIThread) {return;}
        if (openAIAssistantsStatus !== AsyncStatus.SUCCESS || ! Array.isArray(openAIAssistantsData)) {return;}
        if (openAIThreadMessagesStatus !== AsyncStatus.SUCCESS || ! Array.isArray(openAIThreadMessages)) {return;}
        const openAIAssistant = openAIAssistantsData.find(assistant => assistant.id === openAIThread.openai_assistant_id);
        if (! openAIAssistant) {return;}

        const lines = [
            `Chat History with Chatbot: ${openAIAssistant.name}`,
            ``,
            `Chat Title: ${openAIThread.name}`,
            `Downloaded at: ${new Date().toLocaleString()}`,
            `Chatbot ID: ${openAIThread.openai_assistant_id}`,
            `Thread ID: ${openAIThread.id}`,
            '',
            '',
        ]
        const lineSeparator = '*'.repeat(50) ;

        openAIThreadMessages.forEach(message => {
            if (message.instance) {
                let role = message.instance.role;
                let content = message.instance.content.at(0).text.value;
                content = content.trim();
                lines.push(`${role}: \n\n${content}\n\n${lineSeparator}\n`)
            }
        })

        const txt = lines.join('\n');
        const blob = new Blob([txt], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${openAIThread.name}.txt`
        a.click();
        URL.revokeObjectURL(url);
    }, [openAIThreadMessages, openAIThreadMessagesStatus, openAIAssistantsData, openAIAssistantsStatus, openAIThread, openAIThreadsStatus]);

    const saveOpenAIThreadMessagesAsPDF = useCallback((element) => {

        const newWindowMethod = (element) => {
            // 提取目标元素的 innerHTML
            const content = element.innerHTML;

            // 获取原文档中的所有样式表链接
            const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style')).reduce((acc, styleSheet) => acc + styleSheet.outerHTML, '');

            // 创建一个新的窗口
            const newWindow = window.open('', '_blank');

            // 写入新窗口的内容
            newWindow.document.open();
            newWindow.document.write(`
                <html lang="en">
                    <head>
                        <title>Print Service</title>
                        ${styles}
                        <style>
                            .print-button {
                                font-size: 32px;
                                margin-top: 32px;
                                margin-bottom: 32px;
                                padding: 8px 16px;
                                background-color: rgb(255, 145, 76);
                                color: white;
                                border: none;
                                border-radius: 16px;
                            }
                            .print-content {
                                margin: 0 auto;
                                max-width: 800px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            }
                            @media print {
                                .print-button {
                                    display: none;
                                }
                                .print-content {
                                    max-width: none;
                                }
                            }
                        </style>
                    </head>
                    <body>
                        <div class="print-content" style="padding: 16px">
                            <button class="print-button" onclick="window.print()">
                                Click me to print
                            </button>
                            ${content}
                        </div>
                    </body>
                </html>
            `);
            newWindow.document.close();

            // 等待资源加载完成，然后打印
            // newWindow.onload = function() {
            //     newWindow.focus();
            //     setTimeout(() => {
            //         newWindow.print();
            //     }, 500);
            // };
        }

        newWindowMethod(element);

    }, []);

    return {
        saveOpenAIThreadMessagesAsTXT,
        saveOpenAIThreadMessagesAsPDF,
    }
}

export default useDownloadOpenAIThreadMessages;