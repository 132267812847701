import {createAsyncThunk} from "@reduxjs/toolkit";
import openAIThreadMessageServiceService from "services/openAIThreadMessageService";
import {
    selectOpenAIThreadMessagesData,
} from "./openAIThreadMessagesSelectors";

const getOpenAIThreadMessagesByThreadId = createAsyncThunk(
    'entities/openAIThreadMessages/getOpenAIThreadMessagesByThreadId',
    async ( {token, openAIThreadId }, { dispatch, getState, rejectWithValue }) => {
        try {
            // if there is already data in the store, return it
            const openAIThreadMessagesData = selectOpenAIThreadMessagesData(getState());
            if (openAIThreadMessagesData.hasOwnProperty(openAIThreadId)) {
                return {
                    openai_thread_id: openAIThreadId,
                    openai_thread_messages: openAIThreadMessagesData[openAIThreadId]
                }
            }
            const ResponseJson = await openAIThreadMessageServiceService.getMessagesByThreadIdForCurrentUser({
                token: token,
                openAIThreadId: openAIThreadId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            return {
                openai_thread_id: openAIThreadId,
                openai_thread_messages: ResponseJson.openai_thread_messages
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get thread messages.',
                details: error.message
            });
        }
    }
);

const createOpenAIThreadMessage = createAsyncThunk(
    'entities/openAIThreadMessages/createOpenAIThreadMessageForCurrentUser',
    async ( {token, openAIThreadId, role, content}, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIThreadMessageServiceService.createMessageForCurrentUser({
                token: token,
                openAIThreadId: openAIThreadId,
                role: role,
                content: content
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            const openai_thread_message = ResponseJson.openai_thread_message;
            return {
                openai_thread_id: openAIThreadId,
                openai_thread_message: openai_thread_message
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to create thread message.',
                details: error.message
            });
        }
    }
);

const deleteOpenAIThreadMessage = createAsyncThunk(
    'entities/openAIThreadMessages/deleteOpenAIThreadMessage',
    async ( {token, openAIThreadId, openaiThreadMessageId}, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIThreadMessageServiceService.deleteMessageForCurrentUser({
                token: token,
                openAIThreadMessageId: openaiThreadMessageId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            return {
                openai_thread_id: openAIThreadId,
                openai_thread_message_id: openaiThreadMessageId
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to delete thread message.',
                details: error.message
            });
        }
    }
);

export {
    getOpenAIThreadMessagesByThreadId,
    createOpenAIThreadMessage,
    deleteOpenAIThreadMessage
};