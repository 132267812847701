import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getOpenAIThreadMessagesByThreadId,
    createOpenAIThreadMessage,
    deleteOpenAIThreadMessage,
} from "./openAIThreadMessagesThunks";

const initialState = {
    data: {},
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const openAIThreadMessagesSlice = createSlice({
    name: 'entities/openAIThreadMessages',
    initialState,
    reducers: {
        addOpenAIThreadMessage : (state, action) => {
            const openAIThreadId = action.payload.openAIThreadId;
            const openAIThreadMessage = action.payload.openAIThreadMessage;
            state.data[openAIThreadId].push(openAIThreadMessage);
        },
        renewOpenAIThreadMessagesByThreadId : (state, action) => {
            const openAIThreadId = action.payload.openAIThreadId;
            state.data[openAIThreadId] = [];
        },
        clearOpenAIThreadMessages : (state) => {
            state.data = {};
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOpenAIThreadMessagesByThreadId.pending, handlePending)
            .addCase(getOpenAIThreadMessagesByThreadId.fulfilled, (state, action) => {
                state.data[action.payload.openai_thread_id] = action.payload.openai_thread_messages;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getOpenAIThreadMessagesByThreadId.rejected, handleRejected)

            .addCase(createOpenAIThreadMessage.pending, handlePending)
            .addCase(createOpenAIThreadMessage.fulfilled, (state, action) => {
                state.data[action.payload.openai_thread_id].push(action.payload.openai_thread_message);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(createOpenAIThreadMessage.rejected, handleRejected)

            .addCase(deleteOpenAIThreadMessage.pending, handlePending)
            .addCase(deleteOpenAIThreadMessage.fulfilled, (state, action) => {
                state.data[action.payload.openai_thread_id] = state.data[action.payload.openai_thread_id].filter(message => message.id !== action.payload.openai_thread_message_id);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteOpenAIThreadMessage.rejected, handleRejected)
    }
});

export const {
    addOpenAIThreadMessage,
    renewOpenAIThreadMessagesByThreadId,
    clearOpenAIThreadMessages
} = openAIThreadMessagesSlice.actions;

export default openAIThreadMessagesSlice.reducer;