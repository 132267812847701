const pathUtil = {

    startsWith: ({currentPath, targetPath}) => {
        try {
            if (!currentPath || !targetPath) {
                return false;
            }
            const currentParts = currentPath.split('/');
            const targetParts = targetPath.split('/');
            for (let i = 0; i < targetParts.length; i++) {
                if (currentParts[i] !== targetParts[i]) {
                    return false;
                }
            }
            return true;
        }
        catch (e) {
            return false;
        }
    },

    lastPart: ({path}) => {
        if (!path) {
            return '';
        }
        const parts = path.split('/');
        return parts[parts.length - 1];
    }
}

export default pathUtil;