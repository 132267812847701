import React, {useRef} from "react";
import './VerticalLayout.css';
import TopNavigationBar from "components/shared/navigations/TopNavigationBar/TopNavigationBar";
import {Outlet} from "react-router-dom";
import usePreloadImages from "./hooks/usePreloadImages";

const VerticalLayout = ({homePath}) => {

    const verticalLayoutRef = useRef(null);

    usePreloadImages()

    return (
        <div className="vertical-layout" ref={verticalLayoutRef}>
            <header>
                <TopNavigationBar homePath={homePath} showMenu={true} scrollPaneRef={verticalLayoutRef}/>
            </header>
            <main>
                <Outlet/>
            </main>
        </div>
    );
}

export default VerticalLayout;