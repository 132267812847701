const selectSession = (state) => state.session;
const selectSessionStatus = (state) => selectSession(state).status;
const selectSessionIsAuthenticated = (state) => selectSession(state).isAuthenticated;
const selectSessionToken = (state) => selectSession(state).token;

export {
    selectSession,
    selectSessionStatus,
    selectSessionIsAuthenticated,
    selectSessionToken,
}