import {createAsyncThunk} from "@reduxjs/toolkit";
import openAIChatModelService from "services/openAIChatModelService";

const getAllOpenAIChatModels = createAsyncThunk(
    'entities/openAIChatModels/getAllOpenAIChatModels',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIChatModelService.getModelsForCurrentUser({
                token: token
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            return {
                models: ResponseJson.models
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error.message
            });
        }
    }
);

export {
    getAllOpenAIChatModels
};