import './SamplePromptList.css'


const SamplePromptList = ({samplePrompts, messageSubmitEnabled, onSubmit}) => {

    const defaultSamplePrompts = [
        "Write a short story set in a dystopian future where humans and robots coexist.",
        "Compose a poem about the changing seasons from the perspective of a tree.",
        "Generate a description of a futuristic cityscape with towering skyscrapers and flying vehicles.",
        "Describe a fantasy landscape featuring mythical creatures, enchanted forests, and magical rivers.",
    ]

    const onSamplePromptClick = async (prompt) => {
        if (prompt.trim() === '') {
            return;
        }
        if (! messageSubmitEnabled) {
            return;
        }
        await onSubmit(prompt);
    }

    return (
        <div className="sample-prompt-list">
            <h2>Sample Queries</h2>
            <div className={'sample-prompts'}>
                {
                    samplePrompts.map((prompt, index) => (
                        <div
                            key={index}
                            className="sample-prompt"
                            onClick={() => onSamplePromptClick(prompt)}
                        >
                            <p>
                                {prompt.trim() === '' ? prompt = defaultSamplePrompts[index] : prompt}
                            </p>
                        </div>
                    ))
                }
            </div>
            <h5 className={'hint'}>-  Click on sample query to start your conversation  -</h5>
        </div>
    );
}

export default SamplePromptList;