import {Navigate, Route} from "react-router-dom";
import FixedHeightLayout from "components/shared/routerLayouts/FixedHeightLayout/FixedHeightLayout";
import UserMenu from "components/shared/fields/UserMenu/UserMenu";
import AppLayout from "components/shared/routerLayouts/AppLayout/AppLayout";
import ChatCenterPage from "pages/appPages/ChatCenterPage/ChatCenterPage";
import DefaultMainPanel from "components/shared/panels/DefaultMainPanel/DefaultMainPanel";
import LoadingMainPanel from "components/shared/panels/LoadingMainPanel/LoadingMainPanel";
import ChatCenterMainPanel from "components/features/chatCenter/ChatCenterMainPanel/ChatCenterMainPanel";
import ChatbotGalleryPage from "pages/appPages/ChatbotGalleryPage/ChatbotGalleryPage";
import ChatbotGalleryMainPanel from "components/features/chatbotGallery/ChatbotGalleryMainPanel/ChatbotGalleryMainPanel";
import ChatbotDetailsMainPanel from "components/features/chatbotGallery/ChatbotDetailsMainPanel/ChatbotDetailsMainPanel";
import ChatbotStudioPage from "pages/appPages/ChatbotStudioPage/ChatbotStudioPage";
import ChatbotStudioMainPanel from "components/features/chatbotStudio/ChatbotStudioMainPanel/ChatbotStudioMainPanel";
import KnowledgeBaseStudioPage from "pages/appPages/KnowledgeBaseStudioPage/KnowledgeBaseStudioPage";
import KnowledgeBaseStudioMainPanel from "components/features/knowledgeBaseStudio/knowledgeBaseStudioMainPanel/knowledgeBaseStudioMainPanel";
import AdminCenterPage from "pages/appPages/AdminCenterPage/AdminCenterPage";
import RoutesConfig from "configs/routesConfig";
import UserManagementMainPanel from "components/features/adminCenter/UserManagementMainPanel/UserManagementMainPanel";
import UserInvitationMainPanel from "components/features/adminCenter/UserInvitationMainPanel/UserInvitationMainPanel";
import {UserRole} from "constants/userRole";


const AppRoutes = () => {
    return (
        <Route element={<FixedHeightLayout homePath={RoutesConfig.HOME} userMenu={<UserMenu/>}/>}>

            <Route path={RoutesConfig.APP} element={<AppLayout/>}>

                <Route index element={<Navigate to={RoutesConfig.app.CHAT_CENTER} />} />

                <Route path={RoutesConfig.app.CHAT_CENTER} element={<ChatCenterPage/>}>
                    <Route index element={<DefaultMainPanel/>} />
                    <Route path={RoutesConfig.app.chatCenter.NEW} element={<LoadingMainPanel/>} />
                    <Route path={RoutesConfig.app.chatCenter.openAIThreadId(":openai-thread-id")} element={<ChatCenterMainPanel/>} />
                </Route>

                <Route path={RoutesConfig.app.CHATBOT_GALLERY} element={<ChatbotGalleryPage/>} >
                    <Route index element={<ChatbotGalleryMainPanel/>} />
                    <Route path={RoutesConfig.app.chatbotGallery.openAIAssistantId(":openai-assistant-id")} element={<ChatbotDetailsMainPanel/>} />
                </Route>

                <Route path={RoutesConfig.app.CHATBOT_STUDIO} element={<ChatbotStudioPage/>} >
                    <Route index element={<DefaultMainPanel/>} />
                    <Route path={RoutesConfig.app.chatbotStudio.NEW} element={<ChatbotStudioMainPanel/>} />
                    <Route path={RoutesConfig.app.chatbotStudio.openAIAssistantId(":openai-assistant-id")} element={<ChatbotStudioMainPanel/>} />
                </Route>

                <Route path={RoutesConfig.app.KNOWLEDGE_BASE_STUDIO} element={<KnowledgeBaseStudioPage/>} >
                    <Route index element={<DefaultMainPanel/>} />
                    <Route path={RoutesConfig.app.knowledgeBaseStudio.NEW} element={<KnowledgeBaseStudioMainPanel/>} />
                    <Route path={RoutesConfig.app.knowledgeBaseStudio.openAIVectorStoreId(":openai-vector-store-id")} element={<KnowledgeBaseStudioMainPanel/>} />
                </Route>

                <Route path={RoutesConfig.app.ADMIN_CENTER} element={<AdminCenterPage/>}>
                    <Route index element={<DefaultMainPanel/>} />
                    <Route path={RoutesConfig.app.adminCenter.STUDENTS} element={<UserManagementMainPanel userRole={UserRole.USER}/>} />
                    <Route path={RoutesConfig.app.adminCenter.TEACHERS} element={<UserManagementMainPanel userRole={UserRole.ADMIN}/>} />
                    <Route path={RoutesConfig.app.adminCenter.USER_INVITATION} element={<UserInvitationMainPanel/>} />
                    <Route path={RoutesConfig.app.adminCenter.OPENAI_CHAT_MODELS} element={<DefaultMainPanel/>} />
                    <Route path={RoutesConfig.app.adminCenter.OPENAI_RESOURCES} element={<DefaultMainPanel/>} />
                    <Route path={RoutesConfig.app.adminCenter.SKETCH_ENGINE_RESOURCES} element={<DefaultMainPanel/>} />
                </Route>

                <Route path="*" element={<Navigate to="chat-center" />} />

            </Route>
        </Route>
    );
}

export default AppRoutes;



