import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    selectOpenAIAssistantsDataForUser,
    selectOpenAIAssistantsDataInitialized,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";
import {useEffect, useRef, useState} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import IconGarbageBin from "assets/vectors/IconGarbageBin";
import IconPencil from "assets/vectors/IconPencil";
import {createOpenAIThread} from "features/entities/openAIThreads/openAIThreadsThunks";
import {selectUserId} from "features/entities/user/userSelectors";
import {
    selectOpenAIChatModelsData,
    selectOpenAIChatModelsStatus
} from "features/entities/openAIChatModels/openAIChatModelsSelectors";
import {deleteOpenAIAssistant} from "features/entities/openAIAssistants/openAIAssistantsThunks";
import timeUtil from "utils/timeUtil";
import RoutesConfig from "configs/routesConfig";

const useChatbotStudioSidePanelItems = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sessionToken = useSelector(selectSessionToken)

    const userDataId = useSelector(selectUserId)

    const openAIAssistantsDataForUser = useSelector(state => selectOpenAIAssistantsDataForUser(state, userDataId))
    const openAIAssistantsStatus = useSelector(state => selectOpenAIAssistantsStatus(state))
    const openAIAssistantsDataInitialized = useSelector(state => selectOpenAIAssistantsDataInitialized(state))

    const openAIChatModelsData = useSelector(selectOpenAIChatModelsData)
    const openAIChatModelsStatus = useSelector(selectOpenAIChatModelsStatus)
    const openAIChatModelsDataInitialized = useSelector(state => selectOpenAIAssistantsDataInitialized(state))

    const [items, setItems] = useState(null)

    const [isReady, setIsReady] = useState(false)

    const initialized = useRef(false)

    useEffect(() => {

        if (initialized.current) {
            if (openAIChatModelsStatus !== AsyncStatus.SUCCESS) return
            if (openAIAssistantsStatus !== AsyncStatus.SUCCESS) return
        } else {
            if (!openAIChatModelsDataInitialized) return
            if (!openAIAssistantsDataInitialized) return
        }

        if (! Array.isArray(openAIChatModelsData)) return
        if (! Array.isArray(openAIAssistantsDataForUser)) return

        const itemsBuffer = []

        const sortedOpenAIAssistantsDataForUser = [...openAIAssistantsDataForUser]
        sortedOpenAIAssistantsDataForUser.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
        })

        sortedOpenAIAssistantsDataForUser.forEach(item => {
            const path = RoutesConfig.app.chatbotStudio.openAIAssistantId(item.id);
            itemsBuffer.push({
                id: item.id,
                title: item.name,
                description: openAIChatModelsData.find(model => model.id === item.openai_chat_model_id).base_model,
                tag: timeUtil.convertToShortenDateOnly(item.created_at),
                path: path,
                menuConfigs: [
                    {
                        icon: <IconPencil/>,
                        text: 'New Chat',
                        onClick: function () {
                            if (!sessionToken) return
                            dispatch(createOpenAIThread({
                                token: sessionToken,
                                openAIAssistantId: item.id,
                                name: 'New Chat',
                                description: ''
                            }))
                            navigate(RoutesConfig.app.chatCenter.NEW);
                        }
                    },
                    {
                        icon: <IconGarbageBin/>,
                        text: 'Delete',
                        onClick: function () {
                            if (!sessionToken) return
                            dispatch(deleteOpenAIAssistant({
                                token: sessionToken,
                                openAIAssistantId: item.id
                            }))
                        }
                    }
                ]
            })
        })

        setItems(itemsBuffer)

        initialized.current = true

    }, [
        navigate,
        dispatch,
        sessionToken,
        openAIChatModelsData,
        openAIAssistantsDataForUser,
        openAIChatModelsStatus,
        openAIAssistantsStatus,
        openAIChatModelsDataInitialized,
        openAIAssistantsDataInitialized
    ])

    useEffect(() => {
        setIsReady(initialized.current)
    }, [items])

    return {items, isReady}
}

export default useChatbotStudioSidePanelItems;