import {useEffect, useState} from 'react';
import FontFaceObserver from 'fontfaceobserver';
import {useTranslation} from "react-i18next";
import AppRouter from "routes/AppRouter";

function App() {

    const { t } = useTranslation('common');

    // 设置网站标题
    useEffect(() => {
        document.title = t("app_name");
        document.documentElement.lang = localStorage.getItem('i18nextLng');
    }, [t]);

    // 加载字体
    const [fontsLoaded, setFontsLoaded] = useState(false);
    useEffect(() => {
        const loadFonts = () => {
            const satoshiVariable = new FontFaceObserver('Satoshi-Variable', {
                style: 'normal',
            });
            const consolas = new FontFaceObserver('Consolas', {
                style: 'normal',
            });
            return Promise.all([
                satoshiVariable.load(),
                consolas.load(),
            ]);
        };
        loadFonts().then(() => setFontsLoaded(true));
    }, []);

    if (!fontsLoaded) {
      return null;
    }

    return (
        <AppRouter/>
    );
}

export default App;