import {createSelector} from '@reduxjs/toolkit'

const selectChatMainPanel = state => state.ui.mainPanels.chatMainPanel;

const selectChatMainPanelThreadDataByThreadId = createSelector(
    [selectChatMainPanel, (_, threadId) => threadId],
    (chatMainPanel, threadId) => {
        return chatMainPanel[threadId] || {};
    }
);


export {
    selectChatMainPanel,
    selectChatMainPanelThreadDataByThreadId,
}