import SiteConfig from 'configs/siteConfig';

const OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/openai-vector-store-files`;

const openAIVectorStoreFileService = {

    getVectorStoreFilesByVectorStoreIdForCurrentUser: async ({token, openAIVectorStoreId}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/${openAIVectorStoreId}`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get openAI vector store files by store ID.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getFilesByVectorStoreFileIdsForCurrentUser: async ({token, openAIVectorStoreId, openAIVectorStoreFileIds}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/files`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_vector_store_id: openAIVectorStoreId,
                    openai_vector_store_file_ids: openAIVectorStoreFileIds
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get files by store file IDs for current user.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }

    },

    generateReadAccessSASTokenForVectorStoreFilesForCurrentUser: async ({token, openAIVectorStoreId, openAIVectorStoreFileId}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/generate-read-access-sas-token`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_vector_store_id: openAIVectorStoreId,
                    openai_vector_store_file_id: openAIVectorStoreFileId
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get SAS token of file by store ID.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    generateReadAccessSASTokenForCitedVectorStoreFilesForCurrentUser: async ({token, openAIVectorStoreId, openAIVectorStoreFileInstanceId}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/generate-read-access-sas-token-for-cited-file`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_vector_store_id: openAIVectorStoreId,
                    openai_vector_store_file_instance_id: openAIVectorStoreFileInstanceId
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get SAS token of file by store ID.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    generateWriteAccessSASTokensForVectorStoreFilesForCurrentUser: async ({token, openAIVectorStoreId, fileNames}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/generate-write-access-sas-tokens`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_vector_store_id: openAIVectorStoreId,
                    file_names: fileNames
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get SAS tokens of files by store ID.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    commitUploadedVectorStoreFilesForCurrentUser: async ({token, openAIVectorStoreId, fileNames}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/commit-uploaded-files`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_vector_store_id: openAIVectorStoreId,
                    file_names: fileNames
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to commit uploaded files by store ID.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteVectorStoreFileForCurrentUser: async ({token, openAIVectorStoreId, openAIVectorStoreFile}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_FILE_SERVICE_BASE_URL}/me/${openAIVectorStoreId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_vector_store_file: openAIVectorStoreFile
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete file by store ID.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }

}

export default openAIVectorStoreFileService;