import './UserMenu.css'
import {useSelector} from "react-redux";

import {selectUserData} from "features/entities/user/userSelectors";
import {toast} from "react-toastify";

const UserMenu = () => {

    const user = useSelector(selectUserData);

    const onMenuClick = () => {
        if (!user) return;
        toast(`🔔 Hi ${user.name}!`);
    }

    return (
        <div className="user-menu" onClick={onMenuClick}>
            {user?.name && (
                <>
                    <div className={'name'}>{user.name}</div>
                    <div className={'favicon'}>{user.name[0].toUpperCase()}</div>
                </>
            )}
        </div>
    )
}

export default UserMenu;