import SiteConfig from 'configs/siteConfig';

const OPENAI_THREAD_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/openai-threads`;

const openAIThreadService = {

    getThreadsForCurrentUser: async ({token}) => {
        const endpoint = `${OPENAI_THREAD_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get openAI threads data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    createThreadForCurrentUser: async ({token, openAIAssistantId, name, description}) => {
        const endpoint = `${OPENAI_THREAD_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_assistant_id: openAIAssistantId,
                    name: name,
                    description: description
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create openAI thread.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    updateThreadNameForCurrentUser: async ({token, openAIThreadId, name}) => {
        const endpoint = `${OPENAI_THREAD_SERVICE_BASE_URL}/me/${openAIThreadId}`;
        try {
            const response = await fetch(endpoint, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: name
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to update openAI thread name.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    renewThreadForCurrentUser: async ({token, openAIThreadId}) => {
        const endpoint = `${OPENAI_THREAD_SERVICE_BASE_URL}/me/${openAIThreadId}/renew`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to renew openAI thread.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteThreadForCurrentUser: async ({token, openAIThreadId}) => {
        const endpoint = `${OPENAI_THREAD_SERVICE_BASE_URL}/me/${openAIThreadId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete openAI thread.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

}

export default openAIThreadService;