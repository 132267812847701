const selectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: '0.5rem',
        border: '1px solid var(--primary-color-500)',
        boxShadow: 'none',
        outline: state.isFocused ? '1px solid var(--primary-color-900)' : 'none',
        '&:hover': {
            border: '1px solid var(--primary-color-500)',
            boxShadow: 'none',
        },
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: '0.5rem',
        border: '1px solid var(--primary-color-500)',
        overflow: 'hidden',
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        padding: 0,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        color: state.isSelected ?  'var(--primary-color-50)' : 'var(--primary-color-900)',
        '&:hover': {
            backgroundColor: state.isSelected ? 'var(--primary-color-600)' : 'var(--primary-color-300)',
        },
        backgroundColor: state.isSelected ? 'var(--primary-color-600)' : 'white',
        transition: 'background-color 0.05s ease-in-out',
    }),
};

export default selectStyles;
