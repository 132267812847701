import './MenuButton.css'
import IconMoreOptions from "assets/vectors/IconMoreOptions";
import {Menu, MenuItem} from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const MenuButton = ({ menuItemConfigs }) => {
    return (
        <Menu
            menuButton={
                <button
                    type="button"
                    className={'more-options'}
                    onClick={(e) => {e.stopPropagation()}}
                >
                    <IconMoreOptions/>
                </button>
            }
            menuStyle={
                {
                    padding: 0,
                    borderRadius: '0.5rem',
                    border: '1px solid var(--primary-color-500)',
                    backgroundColor: 'var(--primary-color-50)',
                    boxShadow: 'var(--tab-box-shadow)',
                    overflow: 'hidden',
                }
            }
            onClick={(e) => {
                e.stopPropagation()
            }}
            direction={'bottom'}
            arrow={false}
            align={'end'}
            shift={8}
            gap={5}
            transition
        >
            {
                Array.isArray(menuItemConfigs) && menuItemConfigs.map(
                    menuItemConfig => (
                        <MenuItem
                            key={'menu-item-' + menuItemConfig.text}
                            style={{
                                margin: 0,
                                padding: '0.4rem 1rem',
                            }}
                            onClick={(e) => {
                                menuItemConfig.onClick()
                            }}
                        >
                            <div className={'custom-react-menu-item'}>
                                <div className={'icon-wrapper'}>
                                    {menuItemConfig.icon}
                                </div>
                                <div className={'text-wrapper'}>
                                    {menuItemConfig.text}
                                </div>
                            </div>
                        </MenuItem>
                    )
                )
            }
        </Menu>
    )
}

export default MenuButton;