import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.7157 19.2589C18.7157 19.2589 17.3595 21.0202 15.6268 19.2589C13.9462 17.6011 12.7237 19.2589 12.7237 19.2589L8.67544 27.3734C8.55808 27.8151 8.81644 28.2693 9.25085 28.3879H22.6963C23.1307 28.2693 23.3891 27.816 23.2725 27.3734L18.7157 19.2589ZM24.9156 26.9581L18.4125 15.8282V15.8075L18.4011 9.28524H18.8502C19.5234 9.28524 20.0711 8.72908 20.0711 8.04279C20.0711 7.35732 19.5234 6.80116 18.8502 6.80116H12.3341C11.6601 6.80116 11.1132 7.35732 11.1132 8.04279C11.1132 8.72908 11.6601 9.28524 12.3341 9.28524H12.7269L12.7139 15.8092L7.08369 26.9581C6.73486 28.2834 7.50832 29.6452 8.81073 30H23.1886C24.4926 29.6452 25.2661 28.2834 24.9156 26.9581ZM22.971 29.1695L9.02753 29.1537C8.15953 28.9167 7.64443 28.0083 7.87671 27.1264L13.5387 15.8407L13.5558 8.45721H12.3349C12.1108 8.45721 11.9282 8.27155 11.9282 8.04279C11.9282 7.81402 12.1108 7.62919 12.3349 7.62919H18.851C19.0752 7.62919 19.2569 7.81485 19.2569 8.04279C19.2569 8.27155 19.0752 8.45721 18.851 8.45721H17.6301L17.6505 15.8133L24.121 27.1404C24.3541 28.0248 23.8382 28.9333 22.971 29.1695ZM16.3913 5.55871C16.3913 6.01623 16.7564 6.38756 17.2063 6.38756C17.6562 6.38756 18.0213 6.01623 18.0213 5.55871C18.0213 5.10118 17.657 4.72985 17.2063 4.72985C16.7556 4.72985 16.3913 5.10035 16.3913 5.55871ZM17.2063 3.48657C17.8812 3.48657 18.4289 2.9304 18.4289 2.24328C18.4289 1.55616 17.8812 1 17.2063 1C16.5315 1 15.9838 1.55616 15.9838 2.24328C15.9838 2.9304 16.5315 3.48657 17.2063 3.48657ZM13.9462 5.55871C14.3969 5.55871 14.7612 5.18738 14.7612 4.72985C14.7612 4.27232 14.3969 3.90099 13.9462 3.90099C13.4955 3.90099 13.1312 4.27232 13.1312 4.72985C13.1312 5.18738 13.4955 5.55871 13.9462 5.55871Z"
            fill="black"
        />
    </svg>
);
export default SVGComponent;
