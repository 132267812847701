import {createSelector} from "@reduxjs/toolkit";

const selectOpenAIThreadMessages = state => state.entities.openAIThreadMessages;

const selectOpenAIThreadMessagesData = createSelector(
    [selectOpenAIThreadMessages],
    openAIThreadMessages => openAIThreadMessages.data || {}
);

// Selector to get messages by thread ID
const selectOpenAIThreadMessagesDataByThreadId = createSelector(
    [selectOpenAIThreadMessagesData, (_, threadId) => threadId],
    (data, threadId) => data[threadId] || null
);

const selectOpenAIThreadMessagesDataInitializedByThreadId = createSelector(
    [selectOpenAIThreadMessagesDataByThreadId],
    messages => !!messages
);

const selectOpenAIThreadMessagesStatus = createSelector(
    [selectOpenAIThreadMessages],
    openAIThreadMessages => openAIThreadMessages.status
);

export {
    selectOpenAIThreadMessages,
    selectOpenAIThreadMessagesData,
    selectOpenAIThreadMessagesDataByThreadId,
    selectOpenAIThreadMessagesDataInitializedByThreadId,
    selectOpenAIThreadMessagesStatus
};