import {createSelector} from "@reduxjs/toolkit";

const selectOpenAIVectorStoreFiles = state => state.entities.openAIVectorStoreFiles;

const selectOpenAIVectorStoreFilesData = createSelector(
    [selectOpenAIVectorStoreFiles],
    openAIVectorStoreFiles => openAIVectorStoreFiles.data || {}
);

// Selector to get files by store ID
const selectOpenAIVectorStoreFilesDataByVectorStoreId = createSelector(
    [selectOpenAIVectorStoreFilesData, (_, storeId) => storeId],
    (data, storeId) => data[storeId] || null
);

const selectOpenAIVectorStoreFilesDataInitializedByVectorStoreId = createSelector(
    [selectOpenAIVectorStoreFilesDataByVectorStoreId],
    files => !!files
);

const selectOpenAIVectorStoreFilesStatus = createSelector(
    [selectOpenAIVectorStoreFiles],
    openAIVectorStoreFiles => openAIVectorStoreFiles.status
);

export {
    selectOpenAIVectorStoreFiles,
    selectOpenAIVectorStoreFilesData,
    selectOpenAIVectorStoreFilesDataByVectorStoreId,
    selectOpenAIVectorStoreFilesDataInitializedByVectorStoreId,
    selectOpenAIVectorStoreFilesStatus
};