import './EditableChatTitle.css'
import {useEffect, useState} from "react";
import {updateOpenAIThreadName} from "features/entities/openAIThreads/openAIThreadsThunks";
import {selectSessionToken} from "features/session/sessionSelectors";
import {useDispatch, useSelector} from "react-redux";

const EditableChatTitle = ({openAIThreadId, openAIThreadTitle}) => {

    const dispatch = useDispatch()

    const sessionToken = useSelector(state => selectSessionToken(state))

    const [title, setTitle] = useState("")

    useEffect(() => {
        setTitle(openAIThreadTitle)
    }, [openAIThreadTitle])

    return (
        <div className={'editable-chat-title'} key={openAIThreadId}>
            <input
                type="text"
                value={title}
                maxLength={64}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.target.blur()
                    }
                }}
                onBlur={() => {
                    if (!sessionToken) return
                    if (title === openAIThreadTitle) return
                    if (title === "") {
                        setTitle(openAIThreadTitle)
                        return
                    }
                    dispatch(updateOpenAIThreadName({
                        token: sessionToken,
                        openAIThreadId: openAIThreadId,
                        name: title
                    }))
                }}
            />
        </div>
    )
}

export default EditableChatTitle;