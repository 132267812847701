import {useSelector} from "react-redux";
import {selectUsersData, selectUsersDataInitialized} from "features/entities/users/usersSelectors";
import {
    selectUserFollowersData,
    selectUserFollowersDataInitialized
} from "features/entities/userFollowers/userFollowersSelectors";
import {useEffect, useState} from "react";

const useFollowers = ({role}) => {

    const usersData = useSelector(state => selectUsersData(state))
    const usersDataInitialized = useSelector(state => selectUsersDataInitialized(state))

    const userFollowersData = useSelector(state => selectUserFollowersData(state))
    const userFollowersDataInitialized = useSelector(state => selectUserFollowersDataInitialized(state))

    const [followers, setFollowers] = useState(null)
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (! usersDataInitialized) return
        if (! userFollowersDataInitialized) return

        const followersBuffer = []
        userFollowersData.forEach(userFollower => {
            const followerId = userFollower.follower_id
            const user = usersData[followerId]
            if (user && user.role === role) {
                let data = {...user}
                data['user_follower_id'] = userFollower.id
                followersBuffer.push(data)
            }
        })
        setFollowers(followersBuffer)
    }, [usersDataInitialized, userFollowersDataInitialized, usersData, userFollowersData, role])

    useEffect(() => {
        if (Array.isArray(followers)) {
            setIsReady(true)
        }
    }, [followers])

    return {
        followers,
        isReady
    }
}

export default useFollowers;