import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {getUser} from "./userThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const userSlice = createSlice({
    name: 'entities/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.data = action.payload.user;
        },
        setUserQuota : (state, action) => {
            state.data.quota = action.payload.userQuota;
        },
        clearUser : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, handlePending)
            .addCase(getUser.fulfilled, (state, action) => {
                state.data = action.payload.user;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getUser.rejected, handleRejected);
    },
});

export const {
    setUser,
    setUserQuota,
    clearUser
} = userSlice.actions;

export default userSlice.reducer;