import './LoadingMainPanel.css'
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import LoadingView from "components/shared/views/LoadingView/LoadingView";

const LoadingMainPanel = () => {

    return (
        <MainPanelLayout
            contentArea={
                <div className="content-area loading">
                    <LoadingView />
                </div>
            }
        />
    )
}

export default LoadingMainPanel;