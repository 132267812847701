import './styles/AuthForm.css';
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import IconEye from "assets/vectors/IconEye";
import IconEyeSlash from "assets/vectors/IconEyeSlash";
import {performSignUp} from "features/auth/signUp/signUpThunks";
import {selectSignUpStatus} from "features/auth/signUp/signUpSelectors";
import {useDispatch, useSelector} from "react-redux";
import {AsyncStatus} from "constants/asyncStatus";
import {clearSignUp} from "features/auth/signUp/signUpSlice";
import RoutesConfig from "configs/routesConfig";

const SignUpForm = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);

    const [isEditable, setIsEditable] = useState(true);

    const emailRef = useRef(null);

    const signUpStatus = useSelector(selectSignUpStatus);

    useEffect(() => {
        if (signUpStatus === AsyncStatus.SUCCESS) {
            dispatch(clearSignUp());
            navigate(RoutesConfig.auth.EMAIL_VERIFICATION, { state: { email: emailRef.current.value } });
        } else if (signUpStatus === AsyncStatus.ERROR) {
            setIsEditable(true);
        }
    }, [signUpStatus, navigate, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditable(false);
        const name = e.target.name.value;
        const email = e.target.email.value;
        const password = e.target.password.value;
        dispatch(clearSignUp());
        dispatch(performSignUp({
            name: name,
            email: email,
            password: password
        }));
    }

    return (
        <form className={"auth-form"} onSubmit={handleSubmit}>

            <h1 className={"title"}>
                Sign Up
            </h1>

            <div className={"input-group"}>
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" disabled={!isEditable} required/>
            </div>

            <div className={"input-group"}>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" disabled={!isEditable} ref={emailRef} required/>
            </div>

            <div className={"input-group"}>
                <label htmlFor="password">Password</label>
                <input type={showPassword ? "text" : "password"} id="password" name="password" disabled={!isEditable} required/>
                <div
                    className={`show-password`}
                    onClick={(event) => {
                        setShowPassword(prev => !prev);
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onMouseDown={(event) => {
                        event.currentTarget.previousElementSibling.previousElementSibling.focus();
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onMouseUp={(event) => {
                        event.currentTarget.previousElementSibling.previousElementSibling.focus();
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    {showPassword ? <IconEyeSlash/> : <IconEye/>}
                </div>
            </div>

            <div className={"links no-select"} >
                <p onClick={() => {
                    navigate(RoutesConfig.auth.LOGIN);
                }}>Already Have an Account? Login Here!</p>
            </div>

            <button type="submit" disabled={!isEditable}>
                Sign Up
            </button>

        </form>
    );
}

export default SignUpForm;