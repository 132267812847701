import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.80001 12C5.80001 12.9941 4.99412 13.8 4.00001 13.8C3.0059 13.8 2.20001 12.9941 2.20001 12C2.20001 11.0059 3.0059 10.2 4.00001 10.2C4.99412 10.2 5.80001 11.0059 5.80001 12ZM13.8 12C13.8 12.9941 12.9941 13.8 12 13.8C11.0059 13.8 10.2 12.9941 10.2 12C10.2 11.0059 11.0059 10.2 12 10.2C12.9941 10.2 13.8 11.0059 13.8 12ZM20 13.8C20.9941 13.8 21.8 12.9941 21.8 12C21.8 11.0059 20.9941 10.2 20 10.2C19.0059 10.2 18.2 11.0059 18.2 12C18.2 12.9941 19.0059 13.8 20 13.8Z"
        />
    </svg>
);
export default SVGComponent;
