import './FileManager.css'
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import IconGarbageBin from "assets/vectors/IconGarbageBin";
import IconDownload from "assets/vectors/IconDownload";
import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    createOpenAIVectorStoreFiles,
    deleteOpenAIVectorStoreFile,
    downloadOpenAIVectorStoreFile,
    getOpenAIVectorStoreFiles,
    refreshOpenAIVectorFilesStatus
} from "features/entities/openAIVectorStoreFiles/openAIVectorStoreFilesThunks";
import {
    selectOpenAIVectorStoreFilesDataByVectorStoreId,
    selectOpenAIVectorStoreFilesDataInitializedByVectorStoreId,
    selectOpenAIVectorStoreFilesStatus
} from "features/entities/openAIVectorStoreFiles/openAIVectorStoreFilesSelectors";
import sizeUtil from "utils/sizeUtil";
import timeUtil from "utils/timeUtil";
import {AsyncStatus} from "constants/asyncStatus";
import LoadingView from "components/shared/views/LoadingView/LoadingView";
import IconRefresh from "assets/vectors/IconRefresh";
import ScrollableTable from "components/core/ScrollableTable/ScrollableTable";
import PaginationController from "components/core/PaginationController/PaginationController";
import {OpenAIVectorStoreFileStatus} from "constants/openAIVectorStoreFileStatus";
import PrimaryButton from "components//core/PrimaryButton/PrimaryButton";

const FileManager = ({openAIVectorStoreId}) => {

    const dispatch = useDispatch();

    const sessionToken = useSelector(state => selectSessionToken(state))
    const openAIVectorStoreFilesData = useSelector(state => selectOpenAIVectorStoreFilesDataByVectorStoreId(state, openAIVectorStoreId))
    const openAIVectorStoreFilesDataInitialized = useSelector(state => selectOpenAIVectorStoreFilesDataInitializedByVectorStoreId(state, openAIVectorStoreId))
    const openAIIVectorStoreFilesStatus = useSelector(state => selectOpenAIVectorStoreFilesStatus(state))
    const loadedOpenAIIVectorStoreIds = useRef([])

    useEffect(() => {
        if (openAIVectorStoreFilesDataInitialized) return
        if (!sessionToken) return
        if (!openAIVectorStoreId) return
        if (openAIIVectorStoreFilesStatus === AsyncStatus.PENDING) return
        if (loadedOpenAIIVectorStoreIds.current.includes(openAIVectorStoreId)) return
        loadedOpenAIIVectorStoreIds.current.push(openAIVectorStoreId)
        dispatch(getOpenAIVectorStoreFiles({
            token: sessionToken,
            openAIVectorStoreId: openAIVectorStoreId
        }));
    }, [openAIVectorStoreFilesDataInitialized, openAIVectorStoreFilesData, openAIVectorStoreId, sessionToken, dispatch, openAIIVectorStoreFilesStatus])

    const [files, setFiles] = React.useState(null);

    useEffect(() => {
        if (!openAIVectorStoreFilesDataInitialized) return
        if (!openAIVectorStoreFilesData) return
        const sortedOpenAIVectorStoreFilesData = [...openAIVectorStoreFilesData];
        sortedOpenAIVectorStoreFilesData.sort((a, b) => {
            return new Date(b.last_modified) - new Date(a.last_modified);
        })
        setFiles(sortedOpenAIVectorStoreFilesData);
    }, [openAIVectorStoreFilesDataInitialized, openAIVectorStoreFilesData])

    const refreshFiles = useCallback(
    async () => {
        if (openAIIVectorStoreFilesStatus === AsyncStatus.PENDING) return
        dispatch(refreshOpenAIVectorFilesStatus({
            token: sessionToken,
            openAIVectorStoreId: openAIVectorStoreId
        }));
    }, [openAIVectorStoreId, sessionToken, dispatch, openAIIVectorStoreFilesStatus])

    const uploadFiles = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.accept = ".c, .cs, .cpp, .docs, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts";
        fileInput.onchange = async () => {
            const files = fileInput.files;
            dispatch(createOpenAIVectorStoreFiles({
                token: sessionToken,
                openAIVectorStoreId: openAIVectorStoreId,
                files: files
            }));
        }
        fileInput.click();
    }

    const tableHeaders = useMemo(() => ['File Name', 'Size', 'Uploaded At', 'Status', 'SK Status', 'Actions'], [])
    const [tableRows, setTableRows] = useState([])

    // eslint-disable-next-line no-unused-vars
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const maxPage = useMemo(() => Math.floor((files?.length - 1 || 0) / itemsPerPage), [files, itemsPerPage])

    useEffect(() => {
        if (!files) return
        const tableRowsBuffer = []
        files.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).forEach((file, index) => {
            tableRowsBuffer.push([
                {
                    type: 'text',
                    value: file.name
                },
                {
                    type: 'text',
                    value: sizeUtil.convertBytes(file.size)
                },
                {
                    type: 'text',
                    value: timeUtil.convertToDisplayDate(file.last_modified)
                },
                {
                    type: 'text',
                    value: file.instance_status || OpenAIVectorStoreFileStatus.QUEUED
                },
                {
                    type: 'text',
                    value: file.sketch_engine_corpus_file_instance_status || "N.A."
                },
                {
                    type: 'buttons',
                    buttons: [
                        {
                            icon: <IconDownload/>,
                            onClick: async () => {
                                dispatch(downloadOpenAIVectorStoreFile({
                                    token: sessionToken,
                                    openAIVectorStoreId: openAIVectorStoreId,
                                    openAIVectorStoreFileId: file.id
                                }));
                            }
                        },
                        {
                            icon: <IconGarbageBin/>,
                            onClick: async () => {
                                dispatch(deleteOpenAIVectorStoreFile({
                                    token: sessionToken,
                                    openAIVectorStoreId: openAIVectorStoreId,
                                    openAIVectorStoreFile: file
                                }));
                            }
                        }
                    ]
                }
            ])
        })
        setTableRows(tableRowsBuffer)
    }, [files, itemsPerPage, currentPage, dispatch, sessionToken, openAIVectorStoreId])

    return (
        <div className={'file-manager'}>
            {
                (files === null) ? (
                    <LoadingView/>
                ) : (
                    <>
                        <ScrollableTable headers={tableHeaders} rows={tableRows}/>

                        <div className={'controls'}>

                            <div className={'refresh-button'} onClick={refreshFiles}><IconRefresh/></div>

                            <PaginationController currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={maxPage}/>

                            <PrimaryButton
                                className={'file-upload-button'}
                                onClick={uploadFiles}
                                >
                                + Upload Files
                            </PrimaryButton>

                        </div>
                    </>
                )
            }
        </div>
    )
}

export default FileManager;