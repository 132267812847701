import {createSelector} from "@reduxjs/toolkit";

const selectOpenAIVectorStores = state => state.entities.openAIVectorStores;

const selectOpenAIVectorStoresData = createSelector(
    [selectOpenAIVectorStores],
    openAIVectorStores => openAIVectorStores?.data || []
);

const selectOpenAIVectorStoresDataInitialized = createSelector(
    [selectOpenAIVectorStores],
    openAIVectorStores => !!openAIVectorStores?.data
);

const selectOpenAIVectorStoresDataByVectorStoreId = createSelector(
    [selectOpenAIVectorStoresData, (_, storeId) => storeId],
    (data, storeId) => data?.find(store => store.id === storeId) || null
);

const selectOpenAIVectorStoresDataInitializedByVectorStoreId = createSelector(
    [selectOpenAIVectorStoresDataByVectorStoreId],
    store => !!store
);

const selectOpenAIVectorStoresStatus = createSelector(
    [selectOpenAIVectorStores],
    openAIVectorStores => openAIVectorStores?.status
);

export {
    selectOpenAIVectorStores,
    selectOpenAIVectorStoresData,
    selectOpenAIVectorStoresDataInitialized,
    selectOpenAIVectorStoresDataByVectorStoreId,
    selectOpenAIVectorStoresDataInitializedByVectorStoreId,
    selectOpenAIVectorStoresStatus
};