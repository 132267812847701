import './UserManagementMainPanel.css';
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import MainPanelTitle from "components/shared/fields/MainPanelTitle/MainPanelTitle";
import ScrollableTable from "components/core/ScrollableTable/ScrollableTable";
import React, {useEffect, useMemo, useState} from "react";
import PaginationController from "../../../core/PaginationController/PaginationController";
import {useNavigate} from "react-router-dom";
import {UserRole} from "constants/userRole";
import useFollowers from "./hooks/useFollowers";
import timeUtil from "../../../../utils/timeUtil";
import {useDispatch, useSelector} from "react-redux";
import {deleteUserFollower} from "../../../../features/entities/userFollowers/userFollowersThunks";
import {selectSessionToken} from "../../../../features/session/sessionSelectors";
import QuotaTransferFloatPanel from "./components/QuotaTransferForm/QuotaTransferFloatPanel";

const UserManagementMainPanel = ({userRole=UserRole.USER}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const sessionToken = useSelector(state => selectSessionToken(state))

    const {followers, isReady} = useFollowers({role: userRole})

    const [isQuotaTransferFloatPanelExisting, setQuotaTransferFloatPanelExistence] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserEmail, setSelectedUserEmail] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const maxPage = useMemo(() => Math.floor((followers?.length - 1 || 0) / itemsPerPage), [followers, itemsPerPage])

    const tableHeaders = useMemo(() => ["Email", "Name", "Quota", "Last Login", "Add Quota", "Remove User"], []);
    const [tableRows, setTableRows] = useState([])

    useEffect(() => {
        if (!isReady) return
        if (! Array.isArray(followers)) return
        const tableRowsBuffer = []
        followers.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).forEach((follower, index) => {
            tableRowsBuffer.push([
                {
                    type: 'text',
                    value: follower.email
                },
                {
                    type: 'text',
                    value: follower.name
                },
                {
                    type: 'text',
                    value: follower.quota? (follower.quota * 100).toFixed(4) : 0
                },
                {
                    type: 'text',
                    value: follower?.last_login_at ? timeUtil.convertToDisplayDate(follower.last_login_at) : "Never"
                },
                {
                    type: 'text-button',
                    value: 'Add',
                    onClick: () => {
                        setSelectedUserId(follower.id)
                        setSelectedUserEmail(follower.email)
                        setQuotaTransferFloatPanelExistence(true)
                    }
                },
                {
                    type: 'text-button',
                    value: 'Remove',
                    onClick: () => {
                        dispatch(deleteUserFollower({
                            token: sessionToken,
                            userFollowerId: follower.user_follower_id
                        }))
                    }
                }
            ])
        })
        setTableRows(tableRowsBuffer)
    }, [followers, itemsPerPage, currentPage, navigate, isReady, dispatch, sessionToken])

    const getTitle = () => {
        switch (userRole) {
            case UserRole.USER:
                return "My Students"
            case UserRole.ADMIN:
                return "Teachers"
            default:
                return "My Students"
        }
    }

    return (
        <React.Fragment>
            <MainPanelLayout
                titleArea={
                    <MainPanelTitle title={getTitle()}/>
                }
                contentArea={
                    <div className={"content-area user-management"} key={userRole}>
                        <ScrollableTable headers={tableHeaders} rows={tableRows}/>
                        {
                            isQuotaTransferFloatPanelExisting &&
                            <QuotaTransferFloatPanel
                                userEmail={selectedUserEmail}
                                userId={selectedUserId}
                                isFormExisting={isQuotaTransferFloatPanelExisting}
                                setFormExistence={setQuotaTransferFloatPanelExistence}
                            />
                        }
                    </div>
                }
                operationArea={
                    <div className={"operation-area user-management"}>
                        <div></div>
                        <PaginationController currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={maxPage}/>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default UserManagementMainPanel;