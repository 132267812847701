import './SidePanelItem.css'
import MenuButton from "../MenuButton/MenuButton";
import React, {useCallback, useMemo} from "react";
import pathUtil from "utils/pathUtil";
import {useLocation, useNavigate} from "react-router-dom";

const SidePanelItem = ({title, description, tag, path, menuConfigs}) => {

    const location = useLocation()
    const navigate = useNavigate()

    const selected = useMemo(() => {
        return pathUtil.startsWith({
            currentPath: location.pathname,
            targetPath: path
        })
    }, [location.pathname, path])

    const onClick = useCallback(() => {
        if (selected) return
        navigate(path)
    }, [selected, path, navigate])

    return (
        <div
            className={`side-panel-item ${selected ? 'selected' : '' }`}
            onClick={onClick}
        >

            <div className={'row'}>
                <div className={'title'}>{title}</div>
                {menuConfigs && <MenuButton menuItemConfigs={menuConfigs}/>}
            </div>

            {(description && tag) && (
                <div className={'row'}>
                    <div className={'description'}>{description}</div>
                    <div className={'tag'}>{tag}</div>
                </div>
            )}

        </div>
    )
}

export default SidePanelItem;