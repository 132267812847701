import React from "react";
import './MarkdownView.css';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownView = (props) => {

    return (
        <div className={"markdown-view"}>
            <ReactMarkdown
                children={props.children}
                remarkPlugins={[remarkGfm]}
                skipHtml={true}
                {...props}
            />
        </div>
    );
}

export default MarkdownView;