import './AboutPage.css'
import React from "react";
import lisa from "assets/images/avatar-lisa.jpg";
import vichy from "assets/images/avatar-vichy.jpg";
import henry from "assets/images/avatar-henry.jpg";
import mike from "assets/images/avatar-mike.jpg";
import caes from "assets/images/logo-caes.webp";
import schoolOfChinese from "assets/images/logo-school-of-chinese.webp";
import AvatarWithSkeleton from "components/core/AvatarWithSkeleton/AvatarWithSkeleton";
import IconLink from "assets/vectors/IconLink";

const AboutPage = () => {

    const sections = [
        {
            title: 'Founder',
            subsections: [
                {
                    title: 'Dr. Lisa Cheung',
                    link: 'https://caes.hku.hk/staff/teaching-staff/lisa-cheung/',
                    content: 'Senior Lecturer, CAES\n' +
                        'BA, The University of Hong Kong\n' +
                        'MA in Applied Linguistics, The University of Hong Kong\n' +
                        'PhD in Applied Linguistics, The University of Birmingham\n' +
                        'Fellow, The Higher Education of Academy\n' +
                        'Recipient of the HKU Teaching Excellence Award (Individual) (2022)\n' +
                        'Recipient of the Faculty Teaching Excellence Award, Faculty of Arts (2018-19)\n',
                    image: lisa
                },
            ]
        },
        {
            title: 'Co-founder',
            subsections: [
                {
                    title: 'Dr. HO Wai Chi Vichy',
                    link: 'https://web.chinese.hku.hk/en/people/staff/66/',
                    content: 'Senior Lecturer & Assistant Programme Director, CLEP\n' +
                        'Qualifications\n' +
                        'BA (HK), M.Phil. (HK), PhD (HK)\n' +
                        'Dr. Ho joined the University of Hong Kong in 2000. He is the course co-ordinator of two language courses. He published nine books since 2000. Whilst three of them were related to historical studies, and the rest were concerning his teaching such as linguistic tactics, writing skills, debating skills and communication on the internet.',
                    image: vichy
                }
            ]
        },
        {
            title: 'Developers',
            subsections: [
                {
                    title: 'Mr. HUNG Ka Hing Henry',
                    link: 'https://hungkahing.com/',
                    content:
                        'Research Assistant, CAES\n' +
                        'BEng in Computer Science, The University of Hong Kong\n'+
                        'Roles: Software Architect & Engineer, Project Manager, UI/UX Designer\n' +
                        'Experienced in full-stack development, AI development, and distributed systems, with professional experience at Huawei.\n' +
                        'Henry is the key developer of the project, responsible for the design, implementation, maintenance, and operation of the system.',
                    image: henry
                },
                {
                    title: 'Mr. YUEN Ho Shing Mike',
                    link: 'https://www.linkedin.com/in/ho-shing-yuen-3a82462b1/',
                    content:
                        'Student Research Assistant, CAES\n' +
                        'BEng in Computer Engineering, The University of Hong Kong\n'+
                        'Roles: Operation & Maintenance\n' +
                        'Mike is responsible for the operation and maintenance of the system after version 1.0.0-release.',
                    image: mike
                }
            ]
        },
        {
            title: 'Organisations',
            subsections: [
                {
                    title: 'CAES',
                    link: "https://caes.hku.hk/",
                    content: "The Centre for Applied English Studies' mission is to improve the academic literacy skills of undergraduate and postgraduate students at The University of Hong Kong. We run compulsory and elective undergraduate and postgraduate academic literacy courses for UGC-funded students. We provide UGC-funded students one-to-one language support services to develop a range of communication skills. We also run a Masters in Applied Linguistics and co-run a Masters in TESOL with the Faculty of Education.",
                    image: caes
                },
                {
                    title: 'CLEP',
                    link: "https://www.clep.hku.hk/",
                    content: 'Chinese Language Enhancement Programme (CLEP) is housed in the School of Chinese at the University of Hong Kong. Unless exemption is granted by the home Faculties under special circumstances, all full-time undergraduates have to take the compulsory Chinese Language Enhancement Courses offered by the CLEP to fulfil the UG5 graduation requirements.',
                    image: schoolOfChinese
                }
            ]
        },
    ]

    return (
        <div className="about-page">

            <h1 className={'title'}>
                About Us
            </h1>

            <div>
                <p style={{ textAlign: 'center', fontSize: '1.15rem', margin: 0}}>
                    The development of this platform, <i >CorpusChat</i>, is inspired by a <b>student-staff partnership</b> approach
                    that recognises the value and expertise of staff and students in co-creation of educational changes.
                </p>
            </div>

            {
                sections.map((section, index) => (
                    <div className={'section'} key={index}>
                        <h2 className={'section-title'}>
                            {section.title}
                        </h2>
                        <div className={'section-content'}>
                        {
                            section.subsections.map((subsection, subIndex) => (
                                <div className={'sub-section'} key={subIndex}>

                                    <div
                                        className={'sub-section-title'}
                                        onClick={() => {
                                            if (subsection.link) {
                                                window.open(subsection.link, '_blank', 'noopener,noreferrer');
                                            }
                                        }}
                                    >
                                        <h3>
                                            {subsection.title}
                                        </h3>
                                        {subsection.link && <IconLink/>}
                                    </div>

                                    <div className={'sub-section-content'}>
                                        <div className={'left-box'}>
                                            <p>
                                                {subsection.content}
                                            </p>
                                        </div>
                                        <div className={'right-box'}>
                                            <AvatarWithSkeleton
                                                src={subsection.image}
                                                alt={subsection.title}
                                                skeletonSize={200}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                ))
            }

        </div>
    );
}

export default AboutPage;