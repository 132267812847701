import {createSelector} from "@reduxjs/toolkit";

const selectUser = state => state.entities.user;

const selectUserData = state => selectUser(state).data;

const selectUserDataInitialized = createSelector(
    [selectUserData],
    userData => !!userData
);

const selectUserId = state => {
    const userData = selectUserData(state);
    return userData?.id ? userData.id : null;
};

const selectUserRole = state => {
    const userData = selectUserData(state);
    return userData?.role ? userData.role : null;
};

const selectUserQuota = state => {
    const userData = selectUserData(state);
    return userData?.quota ? userData.quota : null;
};

const selectUserStatus = state => selectUser(state).status;

export {
    selectUser,
    selectUserData,
    selectUserDataInitialized,
    selectUserId,
    selectUserRole,
    selectUserQuota,
    selectUserStatus
}