import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languageMapping = {
    'zh': 'zh-Hans',
    'zh-CN': 'zh-Hans',
    'zh-SG': 'zh-Hans',
    'zh-MY': 'zh-Hans',
    'zh-TW': 'zh-Hant',
    'zh-HK': 'zh-Hant',
    'zh-MO': 'zh-Hant',
};

i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        supportedLngs: ['en', 'zh', 'zh-Hans', 'zh-Hant', 'zh-CN', 'zh-SG', 'zh-MY', 'zh-TW', 'zh-HK', 'zh-MO'],
        backend: {
            // loadPath: '/locales/{{lng}}/{{ns}}.json',
            loadPath: (languages, namespaces) => {
                const lang = languages[0];
                const language = languageMapping[lang] || lang;
                return `/locales/${language}/{{ns}}.json`;
            }
        },
        ns: ['common', 'appUI'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
    })
    .then(r => {
        console.log('i18n initialized')
    })
    .catch(error => {
        console.error('i18n initialization failed', error);
    });

export default i18n;