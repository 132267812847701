import './ChatCenterSidePanel.css'
import SidePanelLayout from "components/shared/layouts/SidePanelLayout/SidePanelLayout";
import IconDiamond from "assets/vectors/IconDiamond";
import {useNavigate} from "react-router-dom";
import useChatCenterSidePanelItems from "./hooks/useChatCenterSidePanelItems";
import useNavigateToNewThread from "./hooks/useNavigateToNewThread";
import useUserQuota from "./hooks/useUserQuota";
import RoutesConfig from "configs/routesConfig";
import {toast} from "react-toastify";

const ChatCenterSidePanel = () => {

    const navigate = useNavigate()

    // eslint-disable-next-line no-unused-vars
    const {items, isReady} = useChatCenterSidePanelItems();
    const {quota} = useUserQuota();
    useNavigateToNewThread()

    return (
        <>
            <SidePanelLayout
                title={
                    `Chat History`
                }
                items={
                    items || []
                }
                buttonConfigs = {{
                    text: 'New Chat',
                    onClick: function () {
                        toast('🔔 Pick a chatbot here!')
                        navigate(RoutesConfig.app.CHATBOT_GALLERY)
                    }
                }}
            />
            <div className={'tab remaining-balance'}>
                <IconDiamond/>
                <h5>{`Quota: ${quota}`}</h5>
            </div>
        </>
    )
}

export default ChatCenterSidePanel;