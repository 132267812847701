import React, {useState} from "react";
import './ScrollableTable.css'


const ScrollableTable = ({ headers, rows}) => {

    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const handleMouseEnter = (rowIndex) => {
        setHoveredRowIndex(rowIndex);
    };

    const handleMouseLeave = () => {
        setHoveredRowIndex(null);
    };

    return (
        <div className={'scrollable-table-wrapper'}>
            <div
                className={'scrollable-table'}
                style={{
                    gridTemplateColumns: `repeat(${headers.length}, 1fr)`
                }}
            >
                {
                    headers.map((header, index) => {
                        return (
                            <div key={index} className={'table-cell table-header'}>
                                {header}
                            </div>
                        );
                    })
                }
                {
                    rows.map((row, rowIndex) => {
                        return (
                            row.map((cell, cellIndex) => {
                                return (
                                    <div
                                        key={`${rowIndex}-${cellIndex}`}
                                        className={`table-cell allows-select ${cell.type} ${rowIndex === hoveredRowIndex ? 'hover' : ''}`}
                                        onMouseEnter={() => handleMouseEnter(rowIndex)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {
                                            (cell.type === 'text') ?
                                                cell.value
                                            : (cell.type === 'buttons') ?
                                                cell.buttons.map((button, buttonIndex) => {
                                                    return (
                                                        <div key={buttonIndex} className={'button no-select'} onClick={button.onClick}>
                                                            {button.icon}
                                                        </div>
                                                    )
                                                })
                                            : (cell.type === 'text-button') ?
                                                <div className={'text-button no-select'} onClick={cell.onClick}>
                                                    {cell.value}
                                                </div>
                                            : null
                                        }
                                    </div>
                                )
                            })
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ScrollableTable;

// let rowsStructure = [
//     [
//         {
//             type: 'text',
//             value: 'Name'
//         },
//         // a button cntain text only
//         {
//             type: 'text-button',
//             value: 'Remove',
//             onClick: () => {}
//         },
//         {
//             type: 'buttons',
//             buttons: [
//                 {
//                     icon: <IconDownload/>,
//                     onClick: async () => {}
//                 }
//             ]
//         }
//     ]
// ]