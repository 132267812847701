import './styles/EditForm.css'
import {useNavigate} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";
import PrimaryButton from "components/core/PrimaryButton/PrimaryButton";
import ExpandableTextarea from "./components/ExpandableTextarea/ExpandableTextarea";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";
import {getOrCreateUsersByEmails, updateUsersRole} from "features/entities/users/usersThunks";
import {getOrCreateUserFollowers} from "features/entities/userFollowers/userFollowersThunks";
import SecondaryButton from "components/core/SecondaryButton/SecondaryButton";
import RoutesConfig from "configs/routesConfig";
import Select from "react-select";
import selectStyles from "./styles/EditFormSelectStyles";
import {UserRole} from "../../../../constants/userRole";
import {selectUserRole} from "../../../../features/entities/user/userSelectors";
import {transferQuotaToUsers} from "../../../../features/entities/Transactions/transactionsThunks";


const UserInvitationForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sessionToken = useSelector(state => selectSessionToken(state))
    const userRole = useSelector(state => selectUserRole(state))

    const roleOptions = useMemo(
        () => {
            switch (userRole) {
                case UserRole.SUPER_ADMIN:
                    return [
                        {value: UserRole.USER, label: 'Student'},
                        {value: UserRole.ADMIN, label: 'Teacher'},
                        ]
                case UserRole.ADMIN:
                    return [
                        {value: UserRole.USER, label: 'Student'},
                    ]
                default:
                    return [
                        {value: UserRole.USER, label: 'Student'},
                    ]
            }
        }, [userRole]
    )

    const [emails, setEmails] = useState('')
    const [quota, setQuota] = useState(10)
    const [role, setRole] = useState(roleOptions[0])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const toastId = toast.loading('Inviting users...')

        // Validate quota
        let quotaNumber = parseFloat(quota)
        if (isNaN(quotaNumber)) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Invalid quota number!",
                type: "error"
            });
            return
        }
        quotaNumber = quotaNumber / 100;
        if (quotaNumber < 0) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Quota must be greater than 0!",
                type: "error"
            });
            return
        }

        // Validate emails
        let emailList = emails.split(/,|;|\n/).map(email => email.trim()).filter(email => email.length > 0)
        emailList = [...new Set(emailList)]
        if (emailList.length === 0) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "No emails entered!",
                type: "error"
            });
            return
        }
        if (emailList.some(email => !email.endsWith('hku.hk'))) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Email must end with hku.hk!",
                type: "error"
            });
            return
        }

        let invitedUsersRole = role.value

        navigate(RoutesConfig.app.ADMIN_CENTER);

        // Get or create users
        let action = await dispatch(getOrCreateUsersByEmails({
            token: sessionToken,
            emails: emailList,
        }))
        if (action.payload.hasOwnProperty("error")) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Failed to invite users!",
                type: "error"
            });
            return
        }

        // get payload from action
        const users = action.payload.users;
        const userIds = users.map(user => user.id);

        // update user role (only super admin can do this)
        if (userRole === UserRole.SUPER_ADMIN) {
            dispatch(updateUsersRole({
                token: sessionToken,
                userIds: userIds,
                role: invitedUsersRole
            }))
        }

        // Create user followers (relationships)
        action = await dispatch(getOrCreateUserFollowers({
            token: sessionToken,
            followerIds: userIds,
        }));
        if (action.payload.hasOwnProperty("error")) {
            toast.update(toastId, {
                ...ToastifyConfig.loadingToastUpdateOptions,
                render: "Failed to invite users!",
                type: "error"
            });
            return
        }

        // get payload from action
        const userFollowers = action.payload.user_followers;

        dispatch(transferQuotaToUsers({
            token: sessionToken,
            recipientUserIds: userFollowers.map(userFollower => userFollower.follower_id),
            amount: quotaNumber
        }))

        // Update toast
        toast.update(toastId, {
            ...ToastifyConfig.loadingToastUpdateOptions,
            render: "Users invited!",
            type: "success"
        });
    }

    return (
        <form className={"edit-form"} onSubmit={handleSubmit}>

            <label className={"required"}>Emails</label>
            <ExpandableTextarea
                placeholder={"Enter emails separated by comma (,) or newline (\\n) or semicolon (;)\ne.g. henry@connect.hku.hk, mike@connect.hku.hk, lisa@hku.hk"}
                value={emails || ''}
                onChange={(event) => setEmails(event.target.value)}
                required={true}
            />

            <label className={"required"}>
                Role
            </label>
            <Select
                options={roleOptions}
                value={role}
                onChange={(selectedOption) => {
                    setRole(selectedOption)
                }}
                styles={selectStyles}
                required={true}
            />

            <label className={"required"}>
                Quota Issued
            </label>
            <input
                type="text"
                value={quota || ''}
                onChange={(event) => setQuota(event.target.value)}
                required
            />

            <div className={'button-group'}>
                <SecondaryButton type={'button'}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(RoutesConfig.app.ADMIN_CENTER);
                    }}
                >
                    Cancel
                </SecondaryButton>
                <PrimaryButton type="submit">
                    Invite
                </PrimaryButton>
            </div>

        </form>
    )
}

export default UserInvitationForm;