import './knowledgeBaseStudioMainPanel.css'
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import KnowledgeBaseEditForm from "components/shared/forms/editForms/KnowledgeBaseEditForm";
import IconSwitchArrows from "assets/vectors/IconSwitchArrows";
import {useEffect, useState} from "react";
import FileManager from "./components/FileManager/FileManager";
import {useParams} from "react-router-dom";

const KnowledgeBaseStudioMainPanel = () => {

    const { 'openai-vector-store-id': openAIVectorStoreId } = useParams();

    const [activeTab, setActiveTab] = useState('Corpus Editor')
    const [inactiveTabs, setInactiveTabs] = useState('Corpus Files')

    const switchTabs = () => {
        setActiveTab(inactiveTabs)
        setInactiveTabs(activeTab)
    }

    useEffect(() => {
        if (openAIVectorStoreId) {
            setActiveTab('Corpus Editor')
            setInactiveTabs('Corpus Files')
        }
    }, [openAIVectorStoreId])

    return (
        <MainPanelLayout
            titleArea={
                <div className={'title-area knowledge-base-studio'}>
                    <h2 className={"title"}>
                        <span>
                            {activeTab}
                        </span>
                        <IconSwitchArrows/>
                        <span onClick={switchTabs}>
                            {inactiveTabs}
                        </span>
                    </h2>
                </div>
            }
            contentArea={
                <div className="content-area knowledge-base-studio">
                    {
                        activeTab === 'Corpus Editor' ? (
                            <KnowledgeBaseEditForm openAIVectorStoreId={openAIVectorStoreId}/>
                        ) : (
                            <FileManager openAIVectorStoreId={openAIVectorStoreId}/>
                        )
                    }
                </div>
            }
        />
    )
}

export default KnowledgeBaseStudioMainPanel;