import './ChatbotStudioSidePanel.css'
import SidePanelLayout from "components/shared/layouts/SidePanelLayout/SidePanelLayout";
import useChatbotStudioSidePanelItems from "./hooks/useChatbotStudioSidePanelItems";
import {useNavigate} from "react-router-dom";
import RoutesConfig from "configs/routesConfig";

const ChatbotStudioSidePanel = () => {

    const navigate = useNavigate()

    // eslint-disable-next-line no-unused-vars
    const {items, isReady} = useChatbotStudioSidePanelItems()

    return (
        <SidePanelLayout
            title={
                `Customised Chatbots`
            }
            items={
                items
            }
            buttonConfigs = {{
                text: 'New Chatbot',
                onClick: function () {
                    navigate(RoutesConfig.app.chatbotStudio.NEW);
                }
            }}
        />
    )
}

export default ChatbotStudioSidePanel;