import './DefaultMainPanel.css'
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";

const DefaultMainPanel = () => {
    return (
        <MainPanelLayout
            contentArea={
                <div className="default-main-panel">
                    <h1 className={"logo"}>Corpus Chat</h1>
                    <h3 className={"tag"}>- Empowered by AI</h3>
                </div>
            }
        />
    )
}

export default DefaultMainPanel;