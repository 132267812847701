import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m17.09 9 .695-4.17L20.565 9zm-.254 1h3.57l-6.785 7.858zm-9.672 0 3.215 7.858L3.592 10zM6.91 9H3.434l2.78-4.17zm.89-.735L7.09 4H11zM13 4h3.91l-.711 4.265zM8.5 9 12 4.333 15.5 9zm7.255 1L12 19.18 8.245 10zm6.16-.777-4-6-.004-.005a.5.5 0 0 0-.163-.148c-.02-.011-.04-.014-.06-.023-.035-.014-.067-.034-.106-.04-.016-.003-.032.003-.05 0-.01 0-.02-.007-.032-.007h-11c-.012 0-.022.008-.034.008-.016.002-.031-.004-.048-.001-.04.006-.071.026-.106.04-.02.009-.041.012-.06.023a.5.5 0 0 0-.162.148l-.006.005-4 6a.5.5 0 0 0 .038.604l9.5 11c.02.025.05.036.075.056.022.017.04.036.066.049a.49.49 0 0 0 .474 0c.025-.013.044-.032.066-.05.025-.02.054-.03.076-.055l9.5-11a.5.5 0 0 0 .037-.604"
            fill="#212121"
        />
    </svg>
);
export default SVGComponent;
