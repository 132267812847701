import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.44 4.254h6.48v.96H4.44zm0 2.495h6.48v.96H4.44zm0 2.54h2.88v.96H4.44z"
            fill="#000"
        />
        <path
            d="M7.084 13.52H2.879V2.48h9.6v5.229a.48.48 0 0 0 .96 0V2.48a.96.96 0 0 0-.96-.96h-9.6a.96.96 0 0 0-.96.96v11.04c0 .53.431.96.96.96h4.205a.48.48 0 0 0 0-.96"
            fill="#000"
        />
        <path
            d="m13.943 13.484-1.283-1.307a2.5 2.5 0 0 0 .394-1.346c0-1.39-1.13-2.52-2.52-2.52s-2.52 1.13-2.52 2.52 1.13 2.52 2.52 2.52a2.5 2.5 0 0 0 1.467-.475l1.257 1.28a.48.48 0 0 0 .342.144.479.479 0 0 0 .343-.816m-3.41-1.093c-.86 0-1.56-.7-1.56-1.56s.7-1.56 1.56-1.56 1.56.7 1.56 1.56-.699 1.56-1.56 1.56"
            fill="#000"
        />
    </svg>
);
export default SVGComponent;
