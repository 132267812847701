import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={800}
        height={800}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14 4a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V5a1 1 0 0 1 1-1m-7.219.376 4 5a1 1 0 0 1 0 1.249l-4 5a1 1 0 1 1-1.562-1.25l3.5-4.374-3.5-4.376a1 1 0 1 1 1.562-1.25z"
            fill="#5C5F62"
        />
    </svg>
);
export default SVGComponent;
