import './ChatbotGallerySidePanel.css'
import SidePanelLayout from "components/shared/layouts/SidePanelLayout/SidePanelLayout";
import useChatbotGallerySidePanelItems from "./hooks/useChatbotGallerySidePanelItems";

const ChatbotGallerySidePanel = () => {

    // eslint-disable-next-line no-unused-vars
    const {items, isReady} = useChatbotGallerySidePanelItems();

    return (
        <SidePanelLayout
            title={
                `Favorite Chatbots`
            }
            items={
                items || []
            }
        />
    )
}

export default ChatbotGallerySidePanel;