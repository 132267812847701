import {createSelector} from "@reduxjs/toolkit";

const selectOpenAIThreads = state => state.entities.openAIThreads;

const selectOpenAIThreadsData = createSelector(
    [selectOpenAIThreads],
    openAIThreads => openAIThreads?.data || []
);

const selectOpenAIThreadsDataInitialized = createSelector(
    [selectOpenAIThreads],
    openAIThreads => !!openAIThreads?.data
);

const selectOpenAIThreadsDataByThreadId = createSelector(
    [selectOpenAIThreadsData, (_, threadId) => threadId],
    (data, threadId) => data?.find(thread => thread.id === threadId) || null
);

const selectOpenAIThreadsDataInitializedByThreadId = createSelector(
    [selectOpenAIThreadsDataByThreadId],
    thread => !!thread
);

const selectOpenAIThreadsStatus = createSelector(
    [selectOpenAIThreads],
    openAIThreads => openAIThreads?.status
);

export {
    selectOpenAIThreads,
    selectOpenAIThreadsData,
    selectOpenAIThreadsDataInitialized,
    selectOpenAIThreadsDataByThreadId,
    selectOpenAIThreadsDataInitializedByThreadId,
    selectOpenAIThreadsStatus
};