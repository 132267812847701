import {createAsyncThunk} from "@reduxjs/toolkit";
import openAIVectorStoreService from "services/openAIVectorStoreService";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";
import {OpenAIVectorStoreAccessLevel} from "constants/openAIVectorStoreAccessLevel";

const getOpenAIVectorStores = createAsyncThunk(
    'entities/openAIVectorStores/getOpenAIVectorStores',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const ResponseJson = await openAIVectorStoreService.getVectorStoresForCurrentUser({
                token: token
            })
            if (ResponseJson.hasOwnProperty("error")) {
                return rejectWithValue(ResponseJson);
            }
            return {
                openai_vector_stores: ResponseJson.openai_vector_stores
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get vector stores.',
                details: error.message
            });
        }
    }
);

const createOpenAIVectorStore = createAsyncThunk(
    'entities/openAIVectorStores/createOpenAIVectorStore',
    async ( {token, name='New Corpus', description='', accessLevel=OpenAIVectorStoreAccessLevel.PRIVATE}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Creating corpus");
        try {
            const ResponseJson = await openAIVectorStoreService.createVectorStoreForCurrentUser({
                token: token,
                name: name,
                description: description,
                accessLevel: accessLevel
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to create vector store!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            const openai_vector_store = ResponseJson.openai_vector_store;
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus created!", type: "success"});
            return {
                openai_vector_store: openai_vector_store
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to create vector store!", type: "error"});
            return rejectWithValue({
                error: 'Fail to create vector store.',
                details: error.message
            });
        }
    }
);

const updateOpenAIVectorStore = createAsyncThunk(
    'entities/openAIVectorStores/updateOpenAIVectorStore',
    async ( {token, openAIVectorStoreId, name, description, expiresAfterDays, accessLevel, enableSketchEngineExtension}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Updating corpus");
        try {
            const ResponseJson = await openAIVectorStoreService.updateVectorStoreForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId,
                name: name,
                description: description,
                expiresAfterDays: expiresAfterDays,
                accessLevel: accessLevel,
                enableSketchEngineExtension: enableSketchEngineExtension
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to update corpus!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            const openai_vector_store = ResponseJson.openai_vector_store;
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus updated!", type: "success"});
            return {
                openai_vector_store: openai_vector_store
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to update corpus!", type: "error"});
            return rejectWithValue({
                error: 'Fail to update corpus.',
                details: error.message
            });
        }
    }
);

const deleteOpenAIVectorStore = createAsyncThunk(
    'entities/openAIVectorStores/deleteOpenAIVectorStore',
    async ( {token, openAIVectorStoreId}, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Deleting corpus");
        try {
            const ResponseJson = await openAIVectorStoreService.deleteVectorStoreForCurrentUser({
                token: token,
                openAIVectorStoreId: openAIVectorStoreId
            })
            if (ResponseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete corpus!", type: "error"});
                return rejectWithValue(ResponseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Corpus deleted!", type: "success"});
            return {
                openai_vector_store_id: openAIVectorStoreId
            }
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Fail to delete corpus!", type: "error"});
            return rejectWithValue({
                error: 'Fail to delete corpus.',
                details: error.message
            });
        }
    }
);

export {
    getOpenAIVectorStores,
    createOpenAIVectorStore,
    updateOpenAIVectorStore,
    deleteOpenAIVectorStore
};