import './LoadingView.css'
import IconTubeSpinner from "assets/vectors/IconTubeSpinner";
import LoadingText from "../LoadingText/LoadingText";

const LoadingView = () => {

    return (
        <div className="loading-view">
            <IconTubeSpinner />
            <h1>
                <LoadingText />
            </h1>
        </div>
    )
}

export default LoadingView;