import { useEffect, useRef } from 'react';

import avatarHenry from 'assets/images/avatar-henry.jpg';
import avatarMike from 'assets/images/avatar-mike.jpg';
import avatarLisa from 'assets/images/avatar-lisa.jpg';
import avatarVichy from 'assets/images/avatar-vichy.jpg';

import iconChatbot from 'assets/images/icon-chatbot.webp';
import iconCorpusSearch from 'assets/images/icon-corpus-search.webp';
import iconInspiration from 'assets/images/icon-inspiration.webp';
import iconOpenAI from 'assets/images/icon-openai.webp';

import iconMailbox from 'assets/images/icon-mailbox.svg';
import iconFacebook from 'assets/images/icon-facebook.svg';
import iconYoutube from 'assets/images/icon-youtube.svg';

import logoCAES from 'assets/images/logo-caes.webp';
import logoSchoolOfChinese from 'assets/images/logo-school-of-chinese.webp';
import logoCorpusChat from 'assets/images/logo-corpus-chat.webp';
import logoHKU from 'assets/images/logo-hku.webp';

import photoRunRunShawTower from 'assets/images/photo-run-run-shaw-tower.jpg';

const usePreloadImages = () => {
    const imagesRef = useRef([]);

    useEffect(() => {
        if (imagesRef.current.length > 0) {
            return;
        }

        // List of image URLs to preload
        const imageUrls = [
            avatarHenry,
            avatarMike,
            avatarLisa,
            avatarVichy,
            iconChatbot,
            iconCorpusSearch,
            iconInspiration,
            iconOpenAI,
            iconMailbox,
            iconFacebook,
            iconYoutube,
            logoCAES,
            logoSchoolOfChinese,
            logoCorpusChat,
            logoHKU,
            photoRunRunShawTower
        ];

        // Preload each image and store the Image object in the imagesRef
        imageUrls.forEach(url => {
            const img = new Image();
            img.src = url;
            imagesRef.current.push(img);
        });

        // Optional: Handle image load and error events
        const images = imagesRef.current;
        images.forEach(img => {
            img.onload = () => {
                // console.log(`${img.src} loaded successfully`)
            };
            img.onerror = () => {
                // console.log(`Failed to load ${img.src}`)
            };
        });

        // Clean up function to remove event listeners
        return () => {
            images.forEach(img => {
                img.onload = null;
                img.onerror = null;
            });
        };
    }, []);

    // Optionally return the imagesRef if needed for further use
    return imagesRef;
};

export default usePreloadImages;