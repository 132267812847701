import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectSessionToken} from "features/session/sessionSelectors";
import {useEffect, useRef, useState} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import IconGarbageBin from "assets/vectors/IconGarbageBin";
import sizeUtil from "utils/sizeUtil";
import {
    selectOpenAIVectorStoresData,
    selectOpenAIVectorStoresDataInitialized, selectOpenAIVectorStoresStatus
} from "features/entities/openAIVectorStores/openAIVectorStoresSelectors";
import {deleteOpenAIVectorStore} from "features/entities/openAIVectorStores/openAIVectorStoresThunks";
import timeUtil from "utils/timeUtil";
import RoutesConfig from "configs/routesConfig";

const useKnowledgeBaseStudioSidePanelItems = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sessionToken = useSelector(state => selectSessionToken(state))

    const openAIVectorStoresData = useSelector(state => selectOpenAIVectorStoresData(state))
    const openAIVectorStoresDataInitialized = useSelector(state => selectOpenAIVectorStoresDataInitialized(state))
    const openAIVectorStoresStatus = useSelector(state => selectOpenAIVectorStoresStatus(state))

    const [items, setItems] = useState(null)

    const [isReady, setIsReady] = useState(false)

    const initialized = useRef(false)

    useEffect(() => {

        if (initialized.current) {
            if (openAIVectorStoresStatus !== AsyncStatus.SUCCESS) return
        } else {
            if (!openAIVectorStoresDataInitialized) return
        }

        if (! Array.isArray(openAIVectorStoresData)) return

        const itemsBuffer = []

        const sortedOpenAIVectorStoresData = [...openAIVectorStoresData]
        sortedOpenAIVectorStoresData.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
        })

        sortedOpenAIVectorStoresData.forEach(
            item => {
                const path = RoutesConfig.app.knowledgeBaseStudio.openAIVectorStoreId(item.id)

                itemsBuffer.push({
                    id: item.id,
                    title: item.name,
                    description: sizeUtil.convertBytes(item?.instance?.usage_bytes || 0),
                    tag: timeUtil.convertToShortenDateOnly(item.created_at),
                    path: path,
                    menuConfigs: [
                        {
                            icon: <IconGarbageBin/>,
                            text: 'Delete',
                            onClick: function () {
                                if (!sessionToken) return
                                dispatch(deleteOpenAIVectorStore({
                                    token: sessionToken,
                                    openAIVectorStoreId: item.id
                                }))
                                navigate(RoutesConfig.app.KNOWLEDGE_BASE_STUDIO)
                            }
                        }
                    ]
                })
            }
        )

        setItems(itemsBuffer)

        initialized.current = true

    }, [navigate, dispatch, sessionToken, openAIVectorStoresData, openAIVectorStoresStatus, openAIVectorStoresDataInitialized])

    useEffect(() => {
        setIsReady(initialized.current)
    }, [items])

    return {items, isReady}
}

export default useKnowledgeBaseStudioSidePanelItems;