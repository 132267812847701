import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M27 11.348C28.1787 11.7267 29 12.852 29 14.144V19.8587C29 21.3733 27.8707 22.6587 26.36 22.7827C25.9067 22.8187 25.4533 22.852 25 22.8787V27L21 23C19.1947 23 17.408 22.9267 15.64 22.7827C15.2551 22.7516 14.8807 22.6417 14.54 22.46M27 11.348C26.794 11.2817 26.581 11.2392 26.3653 11.2213C22.7946 10.9249 19.2054 10.9249 15.6347 11.2213C14.1267 11.3467 13 12.6307 13 14.144V19.8587C13 20.9747 13.6133 21.9653 14.54 22.46M27 11.348V8.84934C27 6.688 25.464 4.81467 23.32 4.536C20.5611 4.17839 17.782 3.99935 15 4C12.18 4 9.40267 4.18267 6.68 4.536C4.536 4.81467 3 6.688 3 8.84934V17.1507C3 19.312 4.536 21.1853 6.68 21.464C7.44933 21.564 8.22267 21.6507 9 21.7227V28L14.54 22.46"
            stroke="#212121"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SVGComponent;
