import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={42}
        height={42}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={42} height={42} rx={8} />
        <path
            d="M12 23.594h18m-18 0a3.94 3.94 0 0 1-2.828-1.208A4.2 4.2 0 0 1 8 19.469m4 4.125a3.94 3.94 0 0 0-2.828 1.208A4.2 4.2 0 0 0 8 27.719a4.2 4.2 0 0 0 1.172 2.917A3.94 3.94 0 0 0 12 31.844h18a3.94 3.94 0 0 0 2.828-1.208A4.2 4.2 0 0 0 34 27.719a4.2 4.2 0 0 0-1.172-2.917A3.94 3.94 0 0 0 30 23.594m0 0a3.94 3.94 0 0 0 2.828-1.208A4.2 4.2 0 0 0 34 19.469m-26 0a4.2 4.2 0 0 1 1.172-2.917A3.94 3.94 0 0 1 12 15.344h18a3.94 3.94 0 0 1 2.828 1.208A4.2 4.2 0 0 1 34 19.469m-26 0c0-1.339.421-2.642 1.2-3.713l3.45-4.744a4.54 4.54 0 0 1 1.587-1.366 4.4 4.4 0 0 1 2.012-.49h9.502c1.416 0 2.749.688 3.6 1.856l3.449 4.744A6.3 6.3 0 0 1 34 19.47m-4 8.25h.01v.01H30zm0-8.25h.01v.01H30zm-4 8.25h.01v.01H26zm0-8.25h.01v.01H26z"
            stroke="#212121"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SVGComponent;
