import React, { useEffect, useState } from 'react';

const GoogleSearchConsoleAuthPage = () => {
    const [authContent, setAuthContent] = useState('');

    useEffect(() => {
      fetch(`${process.env.PUBLIC_URL}/google342ac9f1ea59c3f7.html`)
        .then(response => response.text())
        .then(data => setAuthContent(data))
        .catch(error => console.error('Error fetching sitemap:', error));
    }, []);

    return (
      <>
        {authContent}
      </>
    );
};

export default GoogleSearchConsoleAuthPage;