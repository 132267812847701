import { createSlice } from '@reduxjs/toolkit';
import { performEmailVerification } from './emailVerificationThunks';
import {AsyncStatus} from "constants/asyncStatus";

const initialState = {
    status: AsyncStatus.IDLE,
    error: null,
}

const emailVerificationSlice = createSlice({
    name: 'auth/emailVerification',
    initialState,
    reducers: {
        clearEmailVerification: (state) => {
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(performEmailVerification.pending, (state) => {
                state.status = AsyncStatus.PENDING;
            })
            .addCase(performEmailVerification.fulfilled, (state, action) => {
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(performEmailVerification.rejected, (state, action) => {
                state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
                state.status = AsyncStatus.ERROR;
            });
    },
});

export const { clearEmailVerification } = emailVerificationSlice.actions;

export default emailVerificationSlice.reducer;