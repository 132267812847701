import FloatingPanel from "components/core/FloatingPanel/FloatingPanel";
import {useState} from "react";
import QuotaTransferForm from "components/shared/forms/editForms/QuotaTransferForm";
import './QuotaTransferFloatPanel.css'


const QuotaTransferFloatPanel = ({ userEmail, userId, isFormExisting, setFormExistence }) => {

    const [isFormVisible, setFormVisibility] = useState(true);

    return (
        <FloatingPanel
            isFormExisting={isFormExisting}
            setFormExistence={setFormExistence}
            isFormVisible={isFormVisible}
            setFormVisibility={setFormVisibility}
            closeOnOutsideClick={true}
            children={
                <div className="quota-transfer-float-panel">
                    <QuotaTransferForm userEmail={userEmail} userId={userId} onClose={() => setFormVisibility(false)}/>
                </div>
            }
        />
    );
}

export default QuotaTransferFloatPanel;