import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={42}
        height={42}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={42} height={42} rx={8} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.845 12.343v9.428c0 7.04 6.113 10.424 11.023 13.143l.131.072.133-.072c4.91-2.72 11.023-6.104 11.023-13.143v-9.428L20.999 6.982zM21 37a.9.9 0 0 1-.44-.115l-.571-.316C14.928 33.766 8 29.93 8 21.771V11.746c0-.365.207-.697.53-.853L20.608 5.09a.9.9 0 0 1 .784 0l12.078 5.803c.323.157.53.488.53.853v10.025c0 8.16-6.93 11.995-11.988 14.798l-.57.316a.9.9 0 0 1-.443.115m7.632-9.127h-5.12l-1.496-1.525v-1.396h-1.908v-1.947h-1.368l-.956-.974-.094.001c-2.876 0-5.216-2.386-5.216-5.32s2.34-5.324 5.216-5.324 5.217 2.389 5.217 5.323q0 .048-.002.094l5.727 5.845zm-4.356-1.882h2.511v-2.562l-5.807-5.927.065-.621c.017-2.065-1.497-3.61-3.355-3.61s-3.37 1.545-3.37 3.44c0 1.898 1.512 3.44 3.37 3.44l.772-.09 1.042 1.062h2.449v1.947h1.908v2.5zm-5.12-9.226c0 .78-.62 1.412-1.384 1.412s-1.384-.633-1.384-1.412c0-.78.62-1.412 1.384-1.412s1.384.633 1.384 1.412"
            fill="#212121"
        />
    </svg>
);
export default SVGComponent;
