import './TutorialPage.css'
import React from "react";


const TutorialPage = () => {

    const sections = [
        {
            title: 'Introduction',
            videoLink: 'https://www.youtube.com/embed/5tRk6-NWUtw',
        },
        {
            title: 'Frequency',
            videoLink: 'https://www.youtube.com/embed/i6q3KVSZY7Q',
        },
        {
            title: 'Concordance',
            videoLink: 'https://www.youtube.com/embed/v73LX09V3gQ',
        },
        {
            title: 'Collocation',
            videoLink: 'https://www.youtube.com/embed/etcD60sHNGk',
        },
    ]

    return (
        <div className="tutorial-page">

            <h1 className={'title'}>
                <i>CorpusChat</i> Tutorial
            </h1>

            <div>
                <p style={{ textAlign: 'center', fontSize: '1.15rem', margin: 0}}>
                    A series of tutorial videos to introduce the main functions of <i>CorpusChat</i>
                </p>
            </div>

            {
                sections.map((section, index) => (
                    <div className={'section'} key={index}>
                        <h2 className={'section-title'}>
                            {section.title}
                        </h2>
                        <div className={'section-content'}>
                            {/* <p>{section.content}</p> */}
                            <div className="video-container">
                                <iframe
                                    className="video"
                                    src={section.videoLink}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={section.title}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
    );
}

export default TutorialPage;