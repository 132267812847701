import SiteConfig from 'configs/siteConfig';

const USER_FAVORITE_OPENAI_ASSISTANT_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/user-favorite-openai-assistants`;

const userFavoriteOpenAIAssistantService = {

    getUserFavoriteOpenAIAssistantsForCurrentUser: async ({token}) => {
        const endpoint = `${USER_FAVORITE_OPENAI_ASSISTANT_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get user favorite openAI assistants data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    createUserFavoriteOpenAIAssistantForCurrentUser: async ({token, openAIAssistantId}) => {
        const endpoint = `${USER_FAVORITE_OPENAI_ASSISTANT_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_assistant_id: openAIAssistantId
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create user favorite openAI assistant.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteUserFavoriteOpenAIAssistantForCurrentUser: async ({token, userFavoriteOpenAIAssistantId}) => {
        const endpoint = `${USER_FAVORITE_OPENAI_ASSISTANT_SERVICE_BASE_URL}/me/${userFavoriteOpenAIAssistantId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete user favorite openAI assistant.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }
};

export default userFavoriteOpenAIAssistantService;