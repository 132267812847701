import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    selectOpenAIVectorStoresStatus
} from "features/entities/openAIVectorStores/openAIVectorStoresSelectors";
import {AsyncStatus} from "constants/asyncStatus";
import {
    createOpenAIVectorStore,
} from "features/entities/openAIVectorStores/openAIVectorStoresThunks";

const useCreateOpenAIVectorStore = () => {

    const dispatch = useDispatch()

    const sessionToken = useSelector(state => selectSessionToken(state))

    const openAIVectorStoresStatus = useSelector(state => selectOpenAIVectorStoresStatus(state))

    const createNewOpenAIVectorStore = async () => {
        if (!sessionToken) return
        if (openAIVectorStoresStatus === AsyncStatus.PENDING) return
        await dispatch(createOpenAIVectorStore({
            token: sessionToken,
        }))
        return true
    }

    return {createNewOpenAIVectorStore}
}

export default useCreateOpenAIVectorStore;