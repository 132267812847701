import SiteConfig from 'configs/siteConfig';

const USER_FOLLOWER_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/user-followers`;

const userFollowerService = {

    getUserFollowersForCurrentUser: async ({token}) => {
        const endpoint = `${USER_FOLLOWER_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get user followers for current user.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getOrCreateUserFollowersForCurrentUser: async ({token, followerIds}) => {
        const endpoint = `${USER_FOLLOWER_SERVICE_BASE_URL}/me/get-or-create`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    follower_ids: followerIds
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create user followers for current user.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteUserFollowerForCurrentUser: async ({token, userFollowerId}) => {
        const endpoint = `${USER_FOLLOWER_SERVICE_BASE_URL}/me/${userFollowerId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete user follower for current user.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }

}

export default userFollowerService;