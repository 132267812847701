import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#212121">
            <path d="M6.222 5.333h14.223v8.711h1.777v-8.71a1.78 1.78 0 0 0-1.777-1.778H6.222a1.78 1.78 0 0 0-1.778 1.777v21.334a1.78 1.78 0 0 0 1.778 1.777h14.223a1.78 1.78 0 0 0 1.777-1.777h-16z" />
            <path d="M25.031 15.36a.89.89 0 0 0-1.253 1.253l3.004 2.943H13.893a.889.889 0 1 0 0 1.777h12.89l-3.005 3.076a.889.889 0 1 0 1.253 1.253l5.191-5.155z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h32v32H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SVGComponent;
