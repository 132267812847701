import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getOpenAIVectorStoreFiles,
    refreshOpenAIVectorFilesStatus,
    createOpenAIVectorStoreFiles,
    deleteOpenAIVectorStoreFile,
} from "./openAIVectorStoreFilesThunks";

const initialState = {
    data: {},
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const openAIVectorStoreFileFilesSlice = createSlice({
    name: 'entities/openAIVectorStoreFiles',
    initialState,
    reducers: {
        clearOpenAIVectorStoreFiles : (state) => {
            state.data = {};
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOpenAIVectorStoreFiles.pending, handlePending)
            .addCase(getOpenAIVectorStoreFiles.fulfilled, (state, action) => {
                state.data[action.payload.openai_vector_store_id] = action.payload.openai_vector_store_files;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getOpenAIVectorStoreFiles.rejected, handleRejected)

            .addCase(refreshOpenAIVectorFilesStatus.pending, handlePending)
            .addCase(refreshOpenAIVectorFilesStatus.fulfilled, (state, action) => {
                const openai_vector_store_id = action.payload.openai_vector_store_id;
                const refreshedFiles = action.payload.openai_vector_store_files;
                let fileBuffer = state.data[openai_vector_store_id] ? [...state.data[openai_vector_store_id]] : [];
                for (let i = 0; i < refreshedFiles.length; i++) {
                    fileBuffer = fileBuffer.filter(file => file.id !== refreshedFiles[i].id);
                    fileBuffer.push(refreshedFiles[i]);
                }
                state.data[openai_vector_store_id] = fileBuffer;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(refreshOpenAIVectorFilesStatus.rejected, handleRejected)

            .addCase(createOpenAIVectorStoreFiles.pending, handlePending)
            .addCase(createOpenAIVectorStoreFiles.fulfilled, (state, action) => {
                let fileBuffer = state.data[action.payload.openai_vector_store_id] ? [...state.data[action.payload.openai_vector_store_id]] : [];
                let newFiles = action.payload.openai_vector_store_files;
                for (let i = 0; i < newFiles.length; i++) {
                    fileBuffer = fileBuffer.filter(file => file.id !== newFiles[i].id);
                    fileBuffer.push(newFiles[i]);
                }
                state.data[action.payload.openai_vector_store_id] = fileBuffer;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(createOpenAIVectorStoreFiles.rejected, handleRejected)

            .addCase(deleteOpenAIVectorStoreFile.pending, handlePending)
            .addCase(deleteOpenAIVectorStoreFile.fulfilled, (state, action) => {
                const deleted_file_id = action.payload.openai_vector_store_file_id;
                let fileBuffer = state.data[action.payload.openai_vector_store_id] ? [...state.data[action.payload.openai_vector_store_id]] : [];
                // since id is string, use '===' instead of '=='
                fileBuffer = fileBuffer.filter(file => file.id !== deleted_file_id);
                state.data[action.payload.openai_vector_store_id] = fileBuffer;
                console.log(state.data[action.payload.openai_vector_store_id]);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteOpenAIVectorStoreFile.rejected, handleRejected)
    }
});

export const {
    clearOpenAIVectorStoreFiles,
    clearOpenAIVectorStoreFilesNewFlag
} = openAIVectorStoreFileFilesSlice.actions;

export default openAIVectorStoreFileFilesSlice.reducer;