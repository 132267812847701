import {createAsyncThunk} from "@reduxjs/toolkit";
import authService from "services/authService";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";

const performSignUp = createAsyncThunk(
    'auth/signUp/performSignUp',
    async ({ name, email, password }, { dispatch, getState, rejectWithValue }) => {
        const toastId = toast.loading("Signing up...");
        try {
            const responseJson = await authService.signup({
                name: name,
                email: email,
                password: password
            })
            if (responseJson.hasOwnProperty("error")) {
                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Sign up failed!", type: "error"});
                return rejectWithValue(responseJson);
            }
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Sign up successful!", type: "success"});
            return responseJson;
        } catch (error) {
            toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Sign up failed!", type: "error"});
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error.message
            });
        }
    }
);

export {
    performSignUp
};