import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.375 12.4893L10.682 20.1823C9.83811 21.0262 8.69351 21.5003 7.50003 21.5003C6.30655 21.5003 5.16195 21.0262 4.31803 20.1823C3.47411 19.3383 3 18.1937 3 17.0003C3 15.8068 3.47411 14.6622 4.31803 13.8183L15.258 2.87825C15.5367 2.59972 15.8675 2.3788 16.2315 2.22811C16.5956 2.07742 16.9857 1.99991 17.3797 2C17.7737 2.00009 18.1639 2.07779 18.5278 2.22865C18.8918 2.37951 19.2225 2.60059 19.501 2.87925C19.7796 3.15792 20.0005 3.48872 20.1512 3.85276C20.3019 4.2168 20.3794 4.60696 20.3793 5.00096C20.3792 5.39496 20.3015 5.78508 20.1506 6.14906C19.9998 6.51303 19.7787 6.84372 19.5 7.12225L8.55203 18.0703C8.26801 18.3425 7.88839 18.4913 7.49497 18.4863C7.10156 18.4813 6.72585 18.322 6.44883 18.0426C6.1718 17.7632 6.01564 17.3862 6.01399 16.9927C6.01234 16.5993 6.16535 16.2209 6.44003 15.9393L14.25 8.12925M8.56103 18.0603L8.55103 18.0703"
            strokeOpacity={1}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SVGComponent;
