import React from 'react';
import './NotFoundPage.css';
import { useNavigate } from 'react-router-dom';
import RainView from "../../../components/shared/views/RainView/RainView";

const NotFoundPage = (props) => {

    const navigate = useNavigate();

    return (
        <div className='not-found-page'>
            <div className='background'>
                <RainView/>
            </div>
            <div className={"content"}>
                <h1>404</h1>
                <h2>OOPS! NOTHING WAS FOUND</h2>
                <p>
                    The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
                    <br/>
                    Return to <span onClick={() => {navigate('/')}}>homepage</span>
                </p>
            </div>
        </div>
    );
};

export default NotFoundPage;