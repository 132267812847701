import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={42}
        height={42}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={42} height={42} rx={8} />
        <path
            d="M19.667 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4.666 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
            fill="#212121"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 6.333a1.333 1.333 0 0 1 1.333 1.334v.666h5.334a4 4 0 0 1 4 4V17A6.667 6.667 0 0 1 25 23.667h-8A6.667 6.667 0 0 1 10.333 17v-4.667a4 4 0 0 1 4-4h5.334v-.666A1.333 1.333 0 0 1 21 6.333M14.333 11h13.334A1.334 1.334 0 0 1 29 12.333V17a4 4 0 0 1-4 4h-8a4 4 0 0 1-4-4v-4.667A1.334 1.334 0 0 1 14.333 11"
            fill="#212121"
        />
        <path
            d="M13 33c0-1.299.735-2.6 2.176-3.63 1.437-1.026 3.493-1.703 5.824-1.703s4.387.677 5.825 1.704C28.265 30.4 29 31.7 29 33a1.333 1.333 0 0 0 2.667 0c0-2.384-1.355-4.415-3.292-5.8C26.435 25.816 23.823 25 21 25s-5.436.815-7.376 2.2c-1.937 1.387-3.292 3.419-3.292 5.8A1.333 1.333 0 0 0 13 33"
            fill="#212121"
        />
    </svg>
);
export default SVGComponent;
