const autoClose = 3000;
const draggable = true;

const ToastifyConfig = {
    autoClose: autoClose,
    draggable: draggable,
    loadingToastUpdateOptions: {
        isLoading: false,
        autoClose: autoClose,
        closeButton: true,
        draggable: draggable,
    }
}

export default ToastifyConfig;