import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g data-name="Layer 2">
            <path fill="none" data-name="invisible box" d="M0 0h48v48H0z" />
            <path
                d="M44 9a7 7 0 1 0-9 6.7v.3a6 6 0 0 1-6 6h-8a10.3 10.3 0 0 0-6 2v-8.3a7 7 0 1 0-4 0v16.6a7 7 0 1 0 4 0V32a6 6 0 0 1 6-6h8a10 10 0 0 0 10-10v-.3A7 7 0 0 0 44 9M10 9a3 3 0 0 1 6 0 3 3 0 0 1-6 0m6 30a3 3 0 1 1-3-3 2.9 2.9 0 0 1 3 3m21-27a2.9 2.9 0 0 1-3-3 3 3 0 0 1 6 0 2.9 2.9 0 0 1-3 3"
                data-name="icons Q2"
            />
        </g>
    </svg>
);
export default SVGComponent;
