import SiteConfig from 'configs/siteConfig';

const OPENAI_CHAT_MODEL_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/openai-chat-models`;

const openAIChatModelService = {

    getAllModels: async ({token}) => {
        const endpoint = `${OPENAI_CHAT_MODEL_SERVICE_BASE_URL}/`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get openAI chat models data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getModelsForCurrentUser: async ({token}) => {
        const endpoint = `${OPENAI_CHAT_MODEL_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get openAI chat models data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }

}

export default openAIChatModelService;