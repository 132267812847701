import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {PseudoOpenAIThreadMessageId} from "constants/pseudoOpenAIThreadMessageId";
import {selectChatMainPanelThreadDataByThreadId} from "features/ui/mainPanels/chatMainPanel/chatMainPanelSelectors";
import {selectUserQuota} from "features/entities/user/userSelectors";


const useMessageSubmitEnabled = ({ openAIThreadId}) => {

    const [messageSubmitEnabled, setMessageSubmitEnabled] = useState(false)

    const userQuota = useSelector(state => selectUserQuota(state));
    const chatMainPanelThreadData = useSelector(state => selectChatMainPanelThreadDataByThreadId(state, openAIThreadId));

    useEffect(() => {
        if (Array.isArray(chatMainPanelThreadData?.messages)) {
            const messages = chatMainPanelThreadData?.messages;
            const pseudoUserMessageExists = messages.find(message => message.id === PseudoOpenAIThreadMessageId.USER);
            const pseudoAssistantMessageExists = messages.find(message => message.id === PseudoOpenAIThreadMessageId.ASSISTANT);
            if (pseudoUserMessageExists || pseudoAssistantMessageExists) {
                setMessageSubmitEnabled(false)
                return;
            }
            if (userQuota <= 0) {
                setMessageSubmitEnabled(false)
                return;
            }
        }
        setMessageSubmitEnabled(true)
    }, [chatMainPanelThreadData, userQuota])

    return {
        messageSubmitEnabled
    }
}

export default useMessageSubmitEnabled;