import React, {useEffect, useRef} from 'react';
import './AppLayout.css';
import {Outlet, useNavigate} from 'react-router-dom';
import SideNavigationBar from "components/shared/navigations/SideNavigationBar/SideNavigationBar";
import {useDispatch, useSelector} from "react-redux";
import {AsyncStatus} from "constants/asyncStatus";
import {selectSession} from "features/session/sessionSelectors";
import {resumeSession} from "features/session/sessionThunks";
import {clearSession} from "features/session/sessionSlice";
import RoutesConfig from "configs/routesConfig";

const AppLayout = () => {

    const session = useSelector(selectSession);
    const dispatch = useDispatch();
    const resumeSessionTriggeredRef = useRef(false);
    const navigate = useNavigate();
    const hasLoginRef = useRef(false);

    useEffect(() => {
        if (session.isAuthenticated === true) {
            if (!hasLoginRef.current) {
                hasLoginRef.current = true;
            }
        }
        else {
            if (hasLoginRef.current) {
                navigate(RoutesConfig.auth.LOGIN);
            }
            else if (session.status === AsyncStatus.IDLE) {
                if (!resumeSessionTriggeredRef.current) {
                    resumeSessionTriggeredRef.current = true;
                    dispatch(resumeSession());
                }
            }
            else if (session.status === AsyncStatus.ERROR) {
                dispatch(clearSession());
                navigate(RoutesConfig.HOME);
            }
        }
    }, [session, dispatch, navigate, resumeSessionTriggeredRef]);

    return (
        <div className={`app-layout ${ ! session.isAuthenticated ? "hidden" : ""}`}>
            <aside>
                <SideNavigationBar/>
            </aside>
            <section>
                <Outlet/>
            </section>
        </div>
    );
}

export default AppLayout;