import './SignUpPage.css'
import React from 'react';
import SignUpForm from "components/shared/forms/authForms/SignUpForm";


const SignUpPage = () => {
    return (
        <div className={'sign-up-page'}>
            <SignUpForm/>
        </div>
    );
}

export default SignUpPage;