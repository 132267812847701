import './ChatCenterPage.css'
import ChatCenterSidePanel from "components/features/chatCenter/ChatCenterSidePanel/ChatCenterSidePanel";
import AppPageLayout from "components/shared/layouts/AppPageLayout/AppPageLayout";
import { Outlet } from 'react-router-dom';

const ChatCenterPage = () => {

    return (
        <AppPageLayout
            SidePanel={
                <ChatCenterSidePanel/>
            }
            MainPanel={(
                <Outlet />
            )}
        />
    );
}

export default ChatCenterPage;