import './ChatbotStudioMainPanel.css';
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import ChatbotEditForm from "components/shared/forms/editForms/ChatbotEditForm";
import {useParams} from "react-router-dom";
import MainPanelTitle from "components/shared/fields/MainPanelTitle/MainPanelTitle";

const ChatbotStudioMainPanel = () => {

    const { 'openai-assistant-id': openAIAssistantId } = useParams();

    return (
        <MainPanelLayout
            titleArea={
                <MainPanelTitle title={ openAIAssistantId ? 'Chatbot Editor' : 'Chatbot Creator'}/>
            }
            contentArea={
                <div className="content-area chatbot-studio">
                    <ChatbotEditForm openAIAssistantId={openAIAssistantId}/>
                </div>
            }
        />
    )
}

export default ChatbotStudioMainPanel;