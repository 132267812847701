import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import NotFoundPage from 'pages/publicPages/NotFoundPage/NotFoundPage';
import AppRoutes from "./AppRoutes";
import PublicRoutes from "./PublicRoutes";
import AuthRoutes from "./AuthRoutes";
import SEORoutes from './SEORoutes';


const AppRouter = () => {
    return (
        <>
            <Router>
                <Routes>
                    {PublicRoutes()}
                    {AuthRoutes()}
                    {AppRoutes()}
                    {SEORoutes()}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </>
    );
};

export default AppRouter;