import Sitemap from "pages/seoPages/SitemapPage"
import GoogleSearchConsoleAuthPage from "pages/seoPages/GoogleSearchConsoleAuthPage"
import { Route } from "react-router-dom";


const SEORoutes = () => {
  return (
    <>
      <Route path="/sitemap.xml" element={<Sitemap />} />
      <Route path="/google342ac9f1ea59c3f7.html" element={<GoogleSearchConsoleAuthPage />} />
    </>
  );
};

export default SEORoutes;
