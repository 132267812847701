import {createAsyncThunk} from "@reduxjs/toolkit";
import userFavoriteOpenAIAssistantService from "services/userFavoriteOpenAIAssistantService";

const getUserFavoriteOpenAIAssistants = createAsyncThunk(
    'entities/userFavoriteOpenAIAssistants/getUserFavoriteOpenAIAssistants',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userFavoriteOpenAIAssistantService.getUserFavoriteOpenAIAssistantsForCurrentUser({
                token: token
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                user_favorite_openai_assistants: responseJson.user_favorite_openai_assistants
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error.message
            });
        }
    }
);

const createUserFavoriteOpenAIAssistant = createAsyncThunk(
    'entities/userFavoriteOpenAIAssistants/createUserFavoriteOpenAIAssistant',
    async ( {token, openAIAssistantId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userFavoriteOpenAIAssistantService.createUserFavoriteOpenAIAssistantForCurrentUser({
                token: token,
                openAIAssistantId: openAIAssistantId
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                user_favorite_openai_assistant: responseJson.user_favorite_openai_assistant
            };
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error.message
            });
        }
    }
);

const deleteUserFavoriteOpenAIAssistant = createAsyncThunk(
    'entities/userFavoriteOpenAIAssistants/deleteUserFavoriteOpenAIAssistant',
    async ( {token, userFavoriteOpenAIAssistantId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userFavoriteOpenAIAssistantService.deleteUserFavoriteOpenAIAssistantForCurrentUser({
                token: token,
                userFavoriteOpenAIAssistantId: userFavoriteOpenAIAssistantId
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                user_favorite_openai_assistant_id: userFavoriteOpenAIAssistantId
            };
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error.message
            });
        }
    }
);

export {
    getUserFavoriteOpenAIAssistants,
    createUserFavoriteOpenAIAssistant,
    deleteUserFavoriteOpenAIAssistant
};