import './SidePanelLayout.css'
import SidePanelItem from "./components/SidePanelItem/SidePanelItem";
import React from "react";
import PrimaryButton from "components/core/PrimaryButton/PrimaryButton";

const SidePanelLayout = ({title, items, buttonConfigs}) => {

    return (
        <div className="side-panel-layout">

            <h4 className={'title'}>
                {
                    title ? (title) : null
                }
            </h4>

            <div className={'content medium-scroll-bar'}>
                {items && Array.isArray(items) && (items.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <SidePanelItem
                                title = {item.title}
                                description = {item.description}
                                tag = {item.tag}
                                path = {item.path}
                                menuConfigs = {item.menuConfigs}
                            />
                        </React.Fragment>
                    )
                }))}
            </div>

            {
                buttonConfigs && (
                    <PrimaryButton
                        className="button"
                        onClick={buttonConfigs.onClick}
                    >
                        {buttonConfigs.text}
                    </PrimaryButton>
                )
            }

        </div>
    );
}

export default SidePanelLayout;