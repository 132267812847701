import './MainPanelLayout.css';

const MainPanelLayout = ({titleArea, contentArea, operationArea}) => {

    return (
        <div className="main-panel-layout">
            {
                titleArea && (
                    <div className={'title-area-wrapper'}>
                        {titleArea}
                    </div>
                )
            }
            {
                contentArea && (
                    <div className={'content-area-wrapper'}>
                        {contentArea}
                    </div>
                )
            }
            {
                operationArea && (
                    <div className={'operation-area-wrapper'}>
                        {operationArea}
                    </div>
                )
            }
        </div>
    )
}

export default MainPanelLayout;