import React, { useRef, useEffect, forwardRef } from 'react';

const AutoHeightTextarea = forwardRef((props, ref) => {

    const textareaRef = useRef(null);

    // 函数用于调整 textarea 高度
    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            // 重置高度以允许其收缩
            textarea.style.height = '0px';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            // 创建一个 MutationObserver 实例
            const observer = new MutationObserver(() => {
                adjustHeight();
            });

            // 配置观察选项
            const config = {
                childList: true,
                subtree: true,
                characterData: true,
            };

            // 开始观察 textarea
            observer.observe(textarea, config);

            // 初始调整高度
            adjustHeight();

            return () => {
                // 停止观察
                observer.disconnect();
            };
        }
    }, []);

    // 使用 useEffect 将外部传入的 ref 和内部的 textareaRef 同步
    useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(textareaRef.current);
            } else {
                ref.current = textareaRef.current;
            }
        }
    }, [ref]);

    return (
        <textarea
            {...props}
            ref={textareaRef}
            style={{ resize: 'none' }}
        />
    );
});

export default AutoHeightTextarea;