import './ChatbotStudioPage.css'
import {Outlet} from "react-router-dom";
import AppPageLayout from "components/shared/layouts/AppPageLayout/AppPageLayout";
import ChatbotStudioSidePanel from "components/features/chatbotStudio/ChatbotStudioSidePanel/ChatbotStudioSidePanel";

const ChatbotStudioPage = () => {

    return (
        <AppPageLayout
            SidePanel={
                <ChatbotStudioSidePanel />
            }
            MainPanel={(
                <Outlet />
            )}
        />
    );
}

export default ChatbotStudioPage;