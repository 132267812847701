import SiteConfig from 'configs/siteConfig';

const OPENAI_THREAD_MESSAGE_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/openai-thread-messages`;

const openAIThreadMessageService = {

    getMessagesByThreadIdForCurrentUser: async ({token, openAIThreadId}) => {
        const endpoint = `${OPENAI_THREAD_MESSAGE_SERVICE_BASE_URL}/me/${openAIThreadId}`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get openAI threads message data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    createMessageForCurrentUser: async ({token, openAIThreadId, role, content}) => {
        const endpoint = `${OPENAI_THREAD_MESSAGE_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    openai_thread_id: openAIThreadId,
                    role: role,
                    content: content
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create openAI thread message.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteMessageForCurrentUser: async ({token, openAIThreadMessageId}) => {
        const endpoint = `${OPENAI_THREAD_MESSAGE_SERVICE_BASE_URL}/me/${openAIThreadMessageId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete openAI thread message.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

}

export default openAIThreadMessageService;