import React, { useEffect, useRef } from "react";

const RainView = ({ width = '100%', height = '100%' }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // 设置canvas的宽度和高度
        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientHeight;

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // 创建一个大小为width/10的数组并填充为0
        const arr = Array(Math.ceil(canvasWidth / 10)).fill(0);

        // 创建一个字符串数组，用于储存字符
        const str = "✸❄✺✹✸✷❅✻✶❉✼❋✽✾✿❆❊❇❈❀★☆⍟✪✫✩✰✧✦➳➵➸➺➻➼➽➾➚➪".split("");

        // 设置文字大小为10px
        ctx.font = "10px";

        function rain() {
            // 设置图层颜色，并绘制一个全屏的矩形
            ctx.fillStyle = "rgba(0,0,20,0.05)";
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);

            // 设置文字颜色
            ctx.fillStyle = "#00c8a2";
            arr.forEach((value, index) => {
                // 根据随机数的索引值绘制文字，x坐标为索引值 * 10，y 坐标为 value + 10
                ctx.fillText(str[Math.floor(Math.random() * str.length)], index * 10, value + 10);
                // 从上一个绘制的位置开始，将数组值设置为下一个绘制位置
                arr[index] = value > canvasHeight || value > 8888 * Math.random() ? 0 : value + 10;
            });
        }

        const intervalId = setInterval(rain, 30);

        // 清除interval，当组件卸载时
        return () => clearInterval(intervalId);
    }, []);

    return (
        <canvas
            ref={canvasRef}
            style={{ width: width, height: height, display: 'block' }}
        />
    );
};

export default RainView;