import React, {useRef} from "react";
import './FixedHeightLayout.css';
import { Outlet } from 'react-router-dom';
import TopNavigationBar from "components/shared/navigations/TopNavigationBar/TopNavigationBar";

const FixedHeightLayout = ({homePath, showMenu=false, showUserMenu=true}) => {

    const verticalLayoutRef = useRef(null);

    return (
        <div className="fixed-height-layout no-select">
            <header>
                <TopNavigationBar homePath={homePath} showMenu={showMenu} showUserMenu={showUserMenu} scrollPaneRef={verticalLayoutRef}/>
            </header>
            <main ref={verticalLayoutRef}>
                <Outlet/>
            </main>
        </div>
    );
}

export default FixedHeightLayout;