import {combineReducers} from 'redux';

import sessionReducer from 'features/session/sessionSlice';

import loginReducer from 'features/auth/login/loginSlice';
import signUpReducer from 'features/auth/signUp/signUpSlice';
import emailVerificationReducer from 'features/auth/emailVerification/emailVerificationSlice';

import userReducer from 'features/entities/user/userSlice';
import usersReducer from 'features/entities/users/usersSlice';
import userFollowersReducer from 'features/entities/userFollowers/userFollowersSlice';
import userFavoriteOpenAIAssistantsReducer from 'features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsSlice';
import openAIAssistantsReducer from 'features/entities/openAIAssistants/openAIAssistantsSlice';
import openAIThreadsReducer from 'features/entities/openAIThreads/openAIThreadsSlice';
import openAIThreadMessagesReducer from 'features/entities/openAIThreadMessages/openAIThreadMessagesSlice';
import openAIChatModelsReducer from 'features/entities/openAIChatModels/openAIChatModelsSlice';
import openAIVectorStoresReducer from 'features/entities/openAIVectorStores/openAIVectorStoresSlice';
import openAIVectorStoreFilesReducer from 'features/entities/openAIVectorStoreFiles/openAIVectorStoreFilesSlice';

import chatMainPanelReducer from 'features/ui/mainPanels/chatMainPanel/chatMainPanelSlice';

const rootReducer = combineReducers({
    session: sessionReducer,
    auth: combineReducers({
        login: loginReducer,
        signUp: signUpReducer,
        emailVerification: emailVerificationReducer,
    }),
    entities: combineReducers({
        user: userReducer,
        users: usersReducer,
        userFollowers: userFollowersReducer,
        userFavoriteOpenAIAssistants: userFavoriteOpenAIAssistantsReducer,
        openAIChatModels: openAIChatModelsReducer,
        openAIAssistants: openAIAssistantsReducer,
        openAIThreads: openAIThreadsReducer,
        openAIThreadMessages: openAIThreadMessagesReducer,
        openAIVectorStores: openAIVectorStoresReducer,
        openAIVectorStoreFiles: openAIVectorStoreFilesReducer,
    }),
    ui: combineReducers({
        mainPanels: combineReducers({
            chatMainPanel: chatMainPanelReducer,
        }),
    }),
});

export default rootReducer;