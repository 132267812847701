import './AppPageLayout.css'

const AppPageLayout = ({SidePanel, MainPanel}) => {
    return (
        <div className="app-page-layout">
            <div className={"side-panel-wrapper"}>
                {SidePanel}
            </div>
            <div className={"main-panel-wrapper"}>
                {MainPanel}
            </div>
        </div>
    )
}

export default AppPageLayout;