import React, {useEffect, useState} from "react";
import './GuidePage.css';
import CodeBlock from "components/shared/markdownComponents/CodeBlock/CodeBlock";
import MarkdownView from "components/shared/views/MarkdownView/MarkdownView";

const GuidePage = () => {

    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        if (markdown) {
            return;
        }
        fetch('docs/guide.md')
            .then(response => response.text())
            .then(text => {
                setMarkdown(text);
            });
    }, [markdown]);

    return (
        <div className="guide-page">
            <h1 className={'title'}>
                User Guide
            </h1>
            {markdown &&
                <div className={"content"}>
                    <MarkdownView
                        components={{
                            code: CodeBlock,
                        }}
                    >
                        {markdown}
                    </MarkdownView>
                </div>
            }
        </div>
    );
}

export default GuidePage;