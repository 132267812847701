import React from "react";
import './HomePage.css';
import {useNavigate} from "react-router-dom";
import RoutesConfig from "configs/routesConfig";
import LogoView from "components/shared/views/LogoView/LogoView";

import iconOpenAI from "assets/images/icon-openai.webp";
import iconChatbot from "assets/images/icon-chatbot.webp";
import iconInspiration from "assets/images/icon-inspiration.webp";
import iconCorpusSearch from "assets/images/icon-corpus-search.webp";

import logoHKU from "assets/images/logo-hku.webp";

import logoAzure from "assets/images/logo-azure.webp";
import logoOpenAI from "assets/images/logo-openai.webp";
import logoSketchEngine from "assets/images/logo-sketch-engine.webp";

import iconFacebook from "assets/images/icon-facebook.svg";
import iconYoutube from "assets/images/icon-youtube.svg";
import iconMailbox from "assets/images/icon-mailbox.svg";

import avatarLisa from "assets/images/avatar-lisa.jpg";
import AvatarWithSkeleton from "components/core/AvatarWithSkeleton/AvatarWithSkeleton";

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div className="home-page">

            <section className={'entry'}>

                <LogoView fontSize={`6rem`}/>

                <h2 className={'sub-title'}>Empowered by AI</h2>

                <p className={"description"}>
                    A diversity of specifically-tailored GPTs for university students to develop their
                    discipline-specific academic writing skills
                </p>

                <div></div>

                <button
                    className={'join-now-button'}
                    onClick={() => {
                        navigate(RoutesConfig.auth.SIGN_UP)
                    }}
                >
                    Join Now!
                </button>

            </section>

            <div className={'separator'}></div>

            <section className={'features'}>

                <h2 className={'title'}>
                    Features
                </h2>

                <div className={'feature-list'}>

                    <div className={'feature'}>

                        <div className={'icon'}>
                            <img src={iconOpenAI} alt="icon"/>
                        </div>

                        <div></div>

                        <h3 className={'title'}>
                            Best AI
                        </h3>

                        <p className={'description'}>
                            ChatGPT and Retrieval-Augmented Generation are used to bring language learning and academic
                            writing into one AI-powered platform
                        </p>

                    </div>

                    <div className={'feature'}>

                        <div className={'icon'}>
                            <img src={iconChatbot} alt="icon"/>
                        </div>

                        <div></div>

                        <h3 className={'title'}>
                            Customised Chatbot
                        </h3>

                        <p className={'description'}>
                            Create customized discipline-specific chatbots tailored to university academic needs,
                            enhancing writing skills and learning experiences.
                        </p>

                    </div>

                    <div className={'feature'}>

                        <div className={'icon'}>
                            <img src={iconInspiration} alt="icon"/>
                        </div>

                        <div></div>

                        <h3 className={'title'}>
                            Tailor-made Corpus
                        </h3>

                        <p className={'description'}>
                            Create discipline-specific corpora of authentic texts from diverse disciplines to enrich the
                            chatbot’s knowledge across different domains
                        </p>

                    </div>

                    <div className={'feature'}>

                        <div className={'icon'}>
                            <img src={iconCorpusSearch} alt="icon"/>
                        </div>

                        <div></div>

                        <h3 className={'title'}>
                            Corpus Search
                        </h3>

                        <p className={'description'}>
                            Empower the chatbot with the ability to analyze language use within a corpus, providing
                            deeper insights into language use and improving academic outcomes.
                        </p>

                    </div>

                </div>

            </section>

            <div className={'separator'}></div>

            <section className={'about'}>
                <div className={'left-box'}>
                    <AvatarWithSkeleton src={avatarLisa} alt={"Dr. Lisa Cheung"} skeletonSize={250}/>
                </div>
                <div className={'right-box'}>
                    <h2 className={'title'}>
                        Meet Dr. Lisa Cheung
                    </h2>
                    <p className={'description'}>
                        Dr. Lisa Cheung is the founder of the AI-empowered platform <i>CorpusChat</i>.
                        She is an expert in the field of Corpus Linguistics and has been teaching at the University of
                        Hong Kong for more than two decades.
                        She is passionate about supporting university students to enhance their academic writing skills.
                        With a solid background in Corpus Linguistics, Lisa believes that Large Language Models (LLMs)
                        have the potential to play a pivotal role
                        in corpus linguistics research, offering learners a new perspective of AI-human dialogues in
                        understanding language use in a variety of domains
                        and registers. <i>CorpusChat</i> is an innovative pedagogical tool in her arsenal,
                        integrating LLMs into a corpus analysis tool to enrich students’ AI-powered learning
                        experiences.
                    </p>
                </div>
            </section>

            <div className={'separator'}></div>

            <section className={'demo-video'}>
                <h2 className={'title'}>
                    What is <i>CorpusChat?</i>
                </h2>
                <div className={'video'}>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/gf7ky7Bzz4A?si=LEI_pga77ORifJ9s&autoplay=1&mute=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </section>

            <div className={'separator'}></div>

            <section className={'supported-by'}>
                <h3 className={'title'}>
                    Powered By
                </h3>
                <div className={'logos'}>
                    <a href={'https://azure.microsoft.com/en-us/'} target={'_blank'} rel={'noreferrer'}>
                    <img src={logoAzure} alt="Azure"/>
                    </a>
                    <a href={'https://www.openai.com/'} target={'_blank'} rel={'noreferrer'}>
                        <img src={logoOpenAI} alt="OpenAI"/>
                    </a>
                    <a href={'https://www.sketchengine.eu/'} target={'_blank'} rel={'noreferrer'}>
                        <img src={logoSketchEngine} alt="Sketch Engine"/>
                    </a>
                    <a href={'https://www.sketchengine.eu/british-academic-written-english-corpus/'} target={'_blank'}
                       rel={'noreferrer'}>
                        <h1>BAWE</h1>
                    </a>
                </div>
            </section>

            <section className={'footer'}>

                <div className={"organizations"}>
                    <div className={'logos'}>
                        <a href={'https://www.hku.hk/'} target={'_blank'} rel={'noreferrer'}>
                            <img src={logoHKU} alt="HKU"/>
                        </a>
                    </div>
                </div>

                <div className={'separation-line'}></div>

                <div className={'links-and-disclaimer'}>
                    <div className={'links'}>
                        <a href="/404"><img src={iconFacebook} alt="Facebook"/></a>
                        <a href="/404"><img src={iconYoutube} alt="Youtube"/></a>
                        <a href="/404"><img src={iconMailbox} alt="Mailbox"/></a>
                    </div>
                    <LogoView fontSize={`1.5rem`}/>
                    <div className={'disclaimer'}>Ⓒ Copyright 2024 All rights reserved</div>
                </div>

            </section>

        </div>
    );
}

export default HomePage;