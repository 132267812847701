import './MainPanelBackButton.css'
import IconArrowLeft from "assets/vectors/IconArrowLeft";
import {useNavigate} from "react-router-dom";

const MainPanelBackButton = ({ backPath }) => {

    const navigate = useNavigate()

    return (
        <div className={'main-panel-back-title'}>
            <div
                className={"back-button"}
                onClick={() => navigate(backPath)}
            >
                <div className={"icon"}>
                    <IconArrowLeft/>
                </div>
                <h2 className={"title"}>Back</h2>
            </div>
        </div>
    )
}

export default MainPanelBackButton;