import './ChatbotPreviewCard.css'
import {useNavigate} from "react-router-dom";
import AssistantFavoriteButton from "components/shared/buttons/AssistantFavoriteButton/AssistantFavoriteButton";
import {createOpenAIThread} from "features/entities/openAIThreads/openAIThreadsThunks";
import {selectSessionToken} from "features/session/sessionSelectors";
import {useDispatch, useSelector} from "react-redux";
import RoutesConfig from "configs/routesConfig";
import SecondaryButton from "../../../core/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../core/PrimaryButton/PrimaryButton";

const ChatbotPreviewCard = ({openAIAssistantId, openAIAssistantName, openAIAssistantDescription}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sessionToken = useSelector(selectSessionToken);

    return (
        <div className={'chatbot-preview-card'}>
            <div className={'row'}>
                <h3 className={'title'}>
                    {openAIAssistantName}
                </h3>
                <div
                    className={'favorite-button'}
                >
                    <AssistantFavoriteButton openAIAssistantId={openAIAssistantId}/>
                </div>
            </div>
            <div className={'row'}>
                <p className={'description'}>
                    {openAIAssistantDescription}
                </p>
            </div>
            <div className={'row filler'}>
            </div>
            <div className={'row'}>
                <SecondaryButton
                    onClick={() => {
                        navigate(RoutesConfig.app.chatbotGallery.openAIAssistantId(openAIAssistantId))
                    }}
                >
                    View Details
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => {
                        if (!sessionToken) return
                        dispatch(createOpenAIThread({
                            token: sessionToken,
                            openAIAssistantId: openAIAssistantId,
                            name: 'New Chat',
                            description: ''
                        }))
                        navigate(RoutesConfig.app.chatCenter.NEW)
                    }}
                >
                    Chat Now
                </PrimaryButton>
            </div>
        </div>
    )
}

export default ChatbotPreviewCard;