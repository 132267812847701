import './styles/EditForm.css'
import Select from "react-select";
import selectStyles from "./styles/EditFormSelectStyles";
import React, {useEffect, useMemo, useState} from "react";
import {
    selectOpenAIVectorStoresDataByVectorStoreId,
    selectOpenAIVectorStoresDataInitializedByVectorStoreId,
    selectOpenAIVectorStoresStatus
} from "features/entities/openAIVectorStores/openAIVectorStoresSelectors";
import {useDispatch, useSelector} from "react-redux";
import {AsyncStatus} from "constants/asyncStatus";
import {updateOpenAIVectorStore} from "features/entities/openAIVectorStores/openAIVectorStoresThunks";
import {useNavigate} from "react-router-dom";
import timeUtil from "utils/timeUtil";
import sizeUtil from "utils/sizeUtil";
import {selectSessionToken} from "features/session/sessionSelectors";
import RoutesConfig from "configs/routesConfig";
import SecondaryButton from "components/core/SecondaryButton/SecondaryButton";
import PrimaryButton from "components/core/PrimaryButton/PrimaryButton";
import ExpandableTextarea from "./components/ExpandableTextarea/ExpandableTextarea";

const KnowledgeBaseEditForm = ({openAIVectorStoreId}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState('null');
    const [description, setDescription] = useState('null');
    const [expirationAfterDays, setExpirationAfterDays] = useState(null);
    const [enableSketchEngineExtension, setEnableSketchEngineExtension] = useState(null);
    const [accessLevel, setAccessLevel] = useState(null)

    const sessionToken = useSelector(state => selectSessionToken(state))
    const openAIVectorStoreData = useSelector(state => selectOpenAIVectorStoresDataByVectorStoreId(state, openAIVectorStoreId))
    const openAIVectorStoresStatus = useSelector(state => selectOpenAIVectorStoresStatus(state))
    const openAIVectorStoreDataInitialized = useSelector(state => selectOpenAIVectorStoresDataInitializedByVectorStoreId(state, openAIVectorStoreId))

    const expirationPolicyOptions = useMemo(() => [
        {value: -1, label: 'Never'},
        {value: 7, label: '1 week since last active'},
        {value: 30, label: '1 month since last active'},
        {value: 90, label: '3 month since last active'},
        {value: 180, label: '6 month since last active'},
    ], [])

    const accessLevelOptions = useMemo(() => [
        {value: 'private', label: 'Myself'},
        {value: 'protected', label: 'My Students'},
        {value: 'public', label: 'Anyone'}
    ], [])

    const enableCorpusAnalysisOptions = useMemo(() => [
        {value: false, label: 'Disabled'},
        {value: true, label: 'Enabled'},
    ], [])

    useEffect(() => {
        if (!openAIVectorStoreDataInitialized) return;
        setName(openAIVectorStoreData.name);
        setDescription(openAIVectorStoreData.description);
        if (openAIVectorStoreData.instance.expires_after === null) {
            setExpirationAfterDays(expirationPolicyOptions.find(option => option.value === -1));
        }
        else {
            setExpirationAfterDays(expirationPolicyOptions.find(option => option.value === openAIVectorStoreData.instance.expires_after.days));
        }
        setAccessLevel(accessLevelOptions.find(option => option.value === openAIVectorStoreData.access_level));
        if (openAIVectorStoreData.extensions.find(extension => extension.type === 'sketch_engine_corpus_search')) {
            setEnableSketchEngineExtension(enableCorpusAnalysisOptions.find(option => option.value === true));
        }
        else {
            setEnableSketchEngineExtension(enableCorpusAnalysisOptions.find(option => option.value === false));
        }
    }, [accessLevelOptions, enableCorpusAnalysisOptions, expirationPolicyOptions, openAIVectorStoreData, openAIVectorStoreDataInitialized]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!sessionToken) return;
        if (openAIVectorStoresStatus === AsyncStatus.PENDING) return;
        if (!openAIVectorStoreDataInitialized) return;
        const data = {
            token: sessionToken,
            openAIVectorStoreId: openAIVectorStoreId,
            name: name,
            description: description,
            expiresAfterDays: expirationAfterDays.value,
            accessLevel: accessLevel.value,
            enableSketchEngineExtension: enableSketchEngineExtension.value
        }
        dispatch(updateOpenAIVectorStore(data));
        navigate(RoutesConfig.app.KNOWLEDGE_BASE_STUDIO);
    }

    return (
        <form className={'knowledge-base edit-form'} onSubmit={handleSubmit}>

            <label className={"required"}>Name</label>
            <input
                type="text"
                value={name || ''}
                onChange={(event) => setName(event.target.value)}
                required
            />

            <label>Description</label>
            <ExpandableTextarea
                value={description || ''}
                onChange={(event) => setDescription(event.target.value)}
                required={false}
            />

            <label className={"required"}>Expiration Policy</label>
            <Select
                options={expirationPolicyOptions}
                value={expirationAfterDays || expirationPolicyOptions[0]}
                onChange={(option) => setExpirationAfterDays(option)}
                styles={selectStyles}
                required={true}
            />

            <label className={"required"}>Corpus Analysis</label>
            <Select
                options={enableCorpusAnalysisOptions}
                value={enableSketchEngineExtension || enableCorpusAnalysisOptions[0]}
                onChange={(option) => setEnableSketchEngineExtension(option)}
                styles={selectStyles}
                required={true}
            />

            <label className={"required"}>
                Access Level
            </label>
            <Select
                options={accessLevelOptions}
                value={accessLevel}
                onChange={(selectedOption) => {
                    setAccessLevel(selectedOption)
                }}
                styles={selectStyles}
                required={true}
            />

            <label>Current Size</label>
            <input
                type="text"
                disabled={true}
                value={(
                    openAIVectorStoreDataInitialized ?
                        sizeUtil.convertBytes(openAIVectorStoreData?.instance?.usage_bytes)
                        : '0'
                )}
            />

            <label>Created At</label>
            <input
                type="text"
                disabled={true}
                value={(
                    openAIVectorStoreDataInitialized ?
                        timeUtil.convertToDisplayDate(openAIVectorStoreData.created_at)
                        : 'null'
                )}
            />

            <label>Last Active At</label>
            <input
                type="text"
                disabled={true}
                value={
                    (openAIVectorStoreDataInitialized
                            ?
                            timeUtil.convertToDisplayDate(
                                timeUtil.convertToISO8601(openAIVectorStoreData.instance.last_active_at)
                            )
                            :
                            'null'
                    )
                }
            />

            <label >Expires At</label>
            <input
                type="text"
                disabled={true}
                value={
                    (
                        openAIVectorStoreDataInitialized && openAIVectorStoreData.instance.expires_at !== null
                            ?
                            timeUtil.convertToDisplayDate(
                                timeUtil.convertToISO8601(openAIVectorStoreData.instance.expires_at)
                            )
                            :
                            'null'
                    )
                }
            />

            <div className={'button-group'}>
                <SecondaryButton type={'button'}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(RoutesConfig.app.KNOWLEDGE_BASE_STUDIO);
                    }}
                >
                    Cancel
                </SecondaryButton>
                <PrimaryButton type="submit">
                    Save
                </PrimaryButton>
            </div>

        </form>
    )
}

export default KnowledgeBaseEditForm;