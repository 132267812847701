import './PaginationController.css'
import IconPrevPage from "assets/vectors/IconPrevPage";
import IconNextPage from "assets/vectors/IconNextPage";
import React, {useEffect, useState} from "react";

const PaginationController = ({currentPage, setCurrentPage, totalPages}) => {

    const [displayedPage, setDisplayedPage] = useState(0);

    useEffect(() => {
        setDisplayedPage(currentPage + 1)
    }, [currentPage]);

    const switchPage = (event) => {
        try {
            let pageNumber = parseInt(event.target.value);
            if (pageNumber >= 1 && pageNumber <= totalPages+1) {
                setCurrentPage(pageNumber-1);
                setDisplayedPage(pageNumber);
            }
            else {
                setDisplayedPage(currentPage+1);
            }
        }
        catch (e) {
            setDisplayedPage(currentPage+1);
        }
    }

    return (
        <div className={'pagination-controller'}>

            <div
                className={'button'}
                onClick={() => {
                    if (currentPage > 0 && currentPage <= totalPages) {
                        setCurrentPage(currentPage - 1);
                    }
                }}
            >
                <IconPrevPage/>
            </div>

            <div className={'page-number'}>
                <input
                    type='text'
                    value={displayedPage}
                    onChange={(e) => setDisplayedPage(e.target.value)}
                    onBlur={switchPage}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            switchPage(e);
                        }
                    }}
                />
                &nbsp;/&nbsp;
                <div>{totalPages + 1}</div>
            </div>

            <div
                className={'button'}
                onClick={() => {
                    if (currentPage < totalPages) {
                        setCurrentPage(currentPage + 1);
                    }
                }}
            >
                <IconNextPage/>
            </div>

        </div>

    )

}

export default PaginationController;