import './FAQPage.css'
import React from "react";

const FAQPage = () => {

    const faqs = [
        {
            'question': <>What is <i>CorpusChat?</i></>,
            'answer': <>
                This platform is an AI-powered chatbot center specifically designed for educational purposes.
                It aims to enhance the effectiveness of teaching and learning by offering customized course-specific chatbots with proprietary corpus,
                as well as an advanced chatbot with Corpus Search ability, which is particularly beneficial for language learning.
            </>
        },
        {
            'question': <>What capabilities are available for teachers?</>,
            'answer': <>
                Educators have the ability to develop and personalize their own chatbot for specific courses.
                They can upload course materials and construct a corpus, then tailor the chatbot with a customized
                corpus and various settings to align with their instructional requirements. Additionally,
                teachers can invite students to engage with their bespoke chatbots.
            </>,
        },

        {
            'question': <>What functionalities are accessible to students?</>,
            'answer': <>
                Students can engage with the chatbot crafted by their instructors, utilizing it to build knowledge,
                solve problems, and analyze language patterns within its corpus. Students also have the option to
                explore the chatbot gallery for additional education resources.
            </>,
        },
        {
            'question': <>What underlying techniques are utilized in the platform?</>,
            'answer': <>
                The platform leverages RAG (Retrieval-Augmented Generation) with vector store, in conjunction with
                OpenAI chat models, to provide an additional knowledge source for chatbots. Additionally, Sketch Engine
                is employed to enhance the chat model with advanced corpus search capabilities, enabling the chatbot to
                analyze the language patterns within its own corpus.
            </>,
        },
        {
            'question': <>How can access be obtained?</>,
            'answer': <>Access to the platform is exclusively available to invited students at the University of Hong
                Kong. For further information, please reach out to your course instructor and the founder of CorpusChat.
            </>,
        },
        {
            'question': <>Is there a fee for using the platform?</>,
            'answer': <>The platform is designed for educational purposes and is therefore provided at no cost. However,
                usage limits are determined by the course instructor, and exceeding these limits may necessitate
                applying for additional usage.
            </>,
        },
        {
            'question': <>How is the safety and storage of my data ensured?</>,
            'answer': <>Your data is securely stored on database provided by Microsoft Azure, located in Hong Kong, to
                ensure compliance with legal regulations and maintain strict security measures. The platform is
                committed to safeguarding your data which will not be shared with any third party.
            </>,
        },
        {
            'question': <>How is access to my corpus files managed?</>,
            'answer': <>Unless the corpus is set to be public, access to the corpus is restricted solely to the user
                who uploaded it, ensuring the privacy and security of the files.'
            </>,
        },
    ]

    return (
        <div className="faq-page">

            <h1>FAQs</h1>

            <div>
                {
                    faqs.map((faq, index) => (
                        <React.Fragment key={index}>
                            <section>
                                <h4>{index+1}. {faq.question}</h4>
                                <p>{faq.answer}</p>
                            </section>
                            <br/>
                        </React.Fragment>
                    ))
                }
            </div>

        </div>
    );
}

export default FAQPage;