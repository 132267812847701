import {createSelector} from "@reduxjs/toolkit";

const selectUserFavoriteOpenAIAssistants = (state) => state.entities.userFavoriteOpenAIAssistants;

const selectUserFavoriteOpenAIAssistantsData = (state) => selectUserFavoriteOpenAIAssistants(state).data;

const selectUserFavoriteOpenAIAssistantsInitialized = createSelector(
    [selectUserFavoriteOpenAIAssistants],
    (userFavoriteOpenAIAssistants) => !!userFavoriteOpenAIAssistants.data
);

const selectUserFavoriteOpenAIAssistantsStatus = (state) => selectUserFavoriteOpenAIAssistants(state).status;

export {
    selectUserFavoriteOpenAIAssistantsData,
    selectUserFavoriteOpenAIAssistantsInitialized,
    selectUserFavoriteOpenAIAssistantsStatus,
}