import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    selectUserFavoriteOpenAIAssistantsData,
    selectUserFavoriteOpenAIAssistantsStatus
} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsSelectors";
import {
    selectOpenAIAssistantsData, selectOpenAIAssistantsDataInitialized,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";
import {
    selectOpenAIChatModelsData, selectOpenAIChatModelsDataInitialized,
    selectOpenAIChatModelsStatus
} from "features/entities/openAIChatModels/openAIChatModelsSelectors";
import {useEffect, useRef, useState} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import IconViewDetails from "assets/vectors/IconViewDetails";
import IconGarbageBin from "assets/vectors/IconGarbageBin";
import {
    deleteUserFavoriteOpenAIAssistant
} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsThunks";
import RoutesConfig from "configs/routesConfig";

const useChatbotGallerySidePanelItems = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sessionToken = useSelector(selectSessionToken);

    const userFavoriteOpenAIAssistantsData = useSelector(selectUserFavoriteOpenAIAssistantsData);
    const userFavoriteOpenAIAssistantsDataInitialized = useSelector(state => selectOpenAIAssistantsDataInitialized(state))
    const userFavoriteOpenAIAssistantsStatus = useSelector(selectUserFavoriteOpenAIAssistantsStatus);

    const openAIAssistantsData = useSelector(selectOpenAIAssistantsData)
    const openAIAssistantsDataInitialized = useSelector(state => selectOpenAIAssistantsDataInitialized(state))
    const openAIAssistantsStatus = useSelector(selectOpenAIAssistantsStatus)

    const openAIChatModelsData = useSelector(selectOpenAIChatModelsData)
    const openAIChatModelsDataInitialized = useSelector(state => selectOpenAIChatModelsDataInitialized(state))
    const openAIChatModelsStatus = useSelector(selectOpenAIChatModelsStatus)

    const [items, setItems] = useState(null)

    const [isReady, setIsReady] = useState(false)

    const initialized = useRef(false)

    useEffect(() => {

        if (initialized.current) {
            if (openAIChatModelsStatus !== AsyncStatus.SUCCESS) return
            if (openAIAssistantsStatus !== AsyncStatus.SUCCESS) return
            if (userFavoriteOpenAIAssistantsStatus !== AsyncStatus.SUCCESS) return
        } else {
            if (! userFavoriteOpenAIAssistantsDataInitialized) return
            if (! openAIAssistantsDataInitialized) return
            if (! openAIChatModelsDataInitialized) return
        }
        if (! Array.isArray(userFavoriteOpenAIAssistantsData)) return
        if (! Array.isArray(openAIAssistantsData)) return
        if (! Array.isArray(openAIChatModelsData)) return

        const itemsBuffer = []

        userFavoriteOpenAIAssistantsData.forEach(item => {
            const openAIAssistant = openAIAssistantsData.find(assistant => assistant.id === item.openai_assistant_id)
            if (!openAIAssistant) {return}
            const openAIChatModel = openAIChatModelsData.find(model => model.id === openAIAssistant.openai_chat_model_id)
            if (!openAIChatModel) {return}

            const path = RoutesConfig.app.chatbotGallery.openAIAssistantId(openAIAssistant.id);

            itemsBuffer.push({
                id: openAIAssistant?.id,
                title: openAIAssistant.name,
                description: openAIChatModel.base_model || 'No description',
                tag: "Chat Now ➜",
                path: path,
                menuConfigs: [
                    {
                        icon: <IconViewDetails/>,
                        text: 'View Details',
                        onClick: function () {
                            navigate(RoutesConfig.app.chatbotGallery.openAIAssistantId(openAIAssistant.id))
                        }
                    },
                    {
                        icon: <IconGarbageBin/>,
                        text: 'Delete',
                        onClick: function () {
                            if (userFavoriteOpenAIAssistantsStatus === AsyncStatus.PENDING) return
                            if (! sessionToken) return
                            dispatch(deleteUserFavoriteOpenAIAssistant({
                                token: sessionToken,
                                userFavoriteOpenAIAssistantId: item.id,
                            }))
                        }
                    }
                ]
            })
        })

        setItems(itemsBuffer)

        initialized.current = true

    }, [
        dispatch,
        navigate,
        sessionToken,
        openAIAssistantsData,
        openAIChatModelsData,
        userFavoriteOpenAIAssistantsData,
        openAIChatModelsStatus,
        openAIAssistantsStatus,
        userFavoriteOpenAIAssistantsStatus,
        openAIChatModelsDataInitialized,
        openAIAssistantsDataInitialized,
        userFavoriteOpenAIAssistantsDataInitialized,
    ])

    useEffect(() => {
        setIsReady(initialized.current)
    }, [items])

    return {items, isReady}
}

export default useChatbotGallerySidePanelItems;