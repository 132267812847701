import {createSlice} from "@reduxjs/toolkit";
import {AsyncStatus} from "constants/asyncStatus";
import {
    getAllAccessibleOpenAIAssistants,
    createOpenAIAssistant,
    updateOpenAIAssistant,
    deleteOpenAIAssistant
} from "./openAIAssistantsThunks";

const initialState = {
    data: null,
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const handlePending = (state) => {
    state.status = AsyncStatus.PENDING;
}

const handleRejected = (state, action) =>  {
    state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
    state.status = AsyncStatus.ERROR;
}

const openAIAssistantsSlice = createSlice({
    name: 'entities/openAIAssistants',
    initialState,
    reducers: {
        clearOpenAIAssistants : (state) => {
            state.data = null;
            state.status = AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllAccessibleOpenAIAssistants.pending, handlePending)
            .addCase(getAllAccessibleOpenAIAssistants.fulfilled, (state, action) => {
                state.data = action.payload.assistants;
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(getAllAccessibleOpenAIAssistants.rejected, handleRejected)

            .addCase(createOpenAIAssistant.pending, handlePending)
            .addCase(createOpenAIAssistant.fulfilled, (state, action) => {
                state.data = [ ...state.data, action.payload.assistant];
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(createOpenAIAssistant.rejected, handleRejected)

            .addCase(updateOpenAIAssistant.pending, handlePending)
            .addCase(updateOpenAIAssistant.fulfilled, (state, action) => {
                state.data = state.data.map(item => item.id === action.payload.assistant.id ? action.payload.assistant : item);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(updateOpenAIAssistant.rejected, handleRejected)

            .addCase(deleteOpenAIAssistant.pending, handlePending)
            .addCase(deleteOpenAIAssistant.fulfilled, (state, action) => {
                state.data = state.data.filter(item => item.id !== action.payload.openai_assistant_id);
                state.status = AsyncStatus.SUCCESS;
            })
            .addCase(deleteOpenAIAssistant.rejected, handleRejected);
    },
});

export const { clearOpenAIAssistants  } = openAIAssistantsSlice.actions;

export default openAIAssistantsSlice.reducer;