import './knowledgeBaseStudioSidePanel.css'
import SidePanelLayout from "components/shared/layouts/SidePanelLayout/SidePanelLayout";
import useKnowledgeBaseStudioSidePanelItems from "./hooks/useKnowledgeBaseStudioSidePanelItems";
import useNavigateToNewOpenAIVectorStore from "./hooks/useNavigateToNewOpenAIVectorStore";
import useCreateOpenAIVectorStore from "./hooks/useCreateOpenAIVectorStore";

const KnowledgeBaseStudioSidePanel = () => {

    // eslint-disable-next-line no-unused-vars
    const {items, isReady} = useKnowledgeBaseStudioSidePanelItems()

    const {createNewOpenAIVectorStore} = useCreateOpenAIVectorStore()

    useNavigateToNewOpenAIVectorStore()

    return (
        <SidePanelLayout
            title={
                `Corpora`
            }
            items={
                items
            }
            buttonConfigs = {{
                text: 'Create New',
                onClick: createNewOpenAIVectorStore
            }}
        />
    )
}

export default KnowledgeBaseStudioSidePanel;