import './TopNavigationBar.css'
import {useNavigate} from "react-router-dom";
import UserMenu from "components/shared/fields/UserMenu/UserMenu";
import RoutesConfig from "configs/routesConfig";
import LogoView from "components/shared/views/LogoView/LogoView";
import SecondaryButton from "components/core/SecondaryButton/SecondaryButton";


const TopNavigationBar = ({homePath, showMenu=false, showUserMenu=false, scrollPaneRef=null}) => {

    const navigate = useNavigate();

    const routes = [
        {
            name: 'Home',
            path: RoutesConfig.HOME
        },
        {
            name: 'About',
            path: RoutesConfig.ABOUT
        },
        // {
        //     name: 'Blog',
        //     path: RoutesConfig.BLOG
        // },
        {
            name: 'Guide',
            path: RoutesConfig.GUIDE
        },
        {
            name: 'Tutorial',
            path: RoutesConfig.TUTORIAL
        },
        {
            name: 'Prompt',
            path: RoutesConfig.PROMPT
        },
        {
            name: 'FAQ',
            path: RoutesConfig.FAQ,
        },
        {
            name: 'Contact',
            path: RoutesConfig.CONTACT
        }
    ]

    return (
        <div className="top-navigation-bar no-select">

            <div className={'title-wrapper'} onClick={() => {
                navigate(homePath)
                scrollPaneRef?.current?.scrollTo({top: 0, behavior: 'smooth'});
            }}>
                <LogoView fontSize={`1.5rem`}/>
            </div>

            <div className={'filler'}></div>

            {
                showMenu ?
                    <div className={'menu-wrapper'}>
                        {
                            routes.map((route, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`menu-item ${route.path === window.location.pathname ? 'selected' : ''}`}
                                        onClick={() => {
                                            navigate(route.path);
                                            scrollPaneRef?.current?.scrollTo({top: 0, behavior: 'smooth'});
                                        }}
                                    >
                                        {route.name}
                                    </div>
                                )
                            })
                        }
                        <div className={'menu-item'}>
                            <SecondaryButton onClick={() => {navigate(RoutesConfig.auth.LOGIN)}}>Login</SecondaryButton>
                        </div>

                        <div className={'menu-item'}>
                            <SecondaryButton onClick={() => {navigate(RoutesConfig.auth.SIGN_UP)}}>Sign Up</SecondaryButton>
                        </div>
                    </div>
                : null
            }

            {
                showUserMenu ?
                    <div className={'user-menu-wrapper'}>
                        <UserMenu/>
                    </div>
                : null
            }

        </div>
    )
}

export default TopNavigationBar;