import SiteConfig from 'configs/siteConfig';

const USER_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/users`;

const userService = {

    getCurrentUser: async ({token}) => {
        const endpoint = `${USER_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get current user data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getUsersByIds: async ({token, userIds}) => {
        const endpoint = `${USER_SERVICE_BASE_URL}/batch`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    user_ids: userIds
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get users data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    getOrCreateUsersByEmails: async ({token, emails}) => {
        const endpoint = `${USER_SERVICE_BASE_URL}/emails/get-or-create`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    emails: emails
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create users by emails.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    updateUsersRole: async ({token, userIds, role}) => {
        const endpoint = `${USER_SERVICE_BASE_URL}/role`;
        try {
            const response = await fetch(endpoint, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    user_ids: userIds,
                    role: role
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to update users role.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }

};

export default userService;