import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectUserDataInitialized, selectUserQuota} from "features/entities/user/userSelectors";

const useUserQuota = () => {

    const [quota, setQuota] = useState(0)

    const userDataInitialized = useSelector(state => selectUserDataInitialized(state))
    const userQuota = useSelector(state => selectUserQuota(state))

    useEffect(() => {

        if (! userDataInitialized || userQuota === null) return
        const MULTIPLIER = 100
        const PRECISION = 4

        let duration = 500; // 0.5s
        let interval = 1000 / 60;
        let incrementInterval = setInterval(() => {
            setQuota(
                quota =>
                    Number((quota + ((userQuota * MULTIPLIER - quota) / duration * interval)).toFixed(PRECISION))
            );
        }, interval);
        setTimeout(() => {
            clearInterval(incrementInterval);
            setQuota((Number((userQuota * MULTIPLIER).toFixed(PRECISION))));
        }, duration);
    }, [userDataInitialized, userQuota])

    return {
        quota,
    }
}

export default useUserQuota;