import './SideNavigationBar.css';
import IconChatBubble from "assets/vectors/IconChatBubble";
import IconRobot from "assets/vectors/IconRobot";
import IconDatabase from "assets/vectors/IconDatabase";
import IconLogout from "assets/vectors/IconLogout";
import {useLocation, useNavigate} from "react-router-dom";
import IconSecurity from "assets/vectors/IconSecurity";
import IconFlask from "assets/vectors/IconFlask";
import pathUtil from "utils/pathUtil";
import {useDispatch, useSelector} from "react-redux";
import {terminateSession} from "features/session/sessionThunks";
import {selectUserDataInitialized, selectUserRole, selectUserStatus} from "features/entities/user/userSelectors";
import {useEffect, useState} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import {UserRole} from "constants/userRole";
import RoutesConfig from "configs/routesConfig";

const SideNavigationBar = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const dispatch = useDispatch();

    const userDataInitialized = useSelector(state => selectUserDataInitialized(state));
    const userStatus = useSelector(state => selectUserStatus(state));
    const userRole = useSelector(state => selectUserRole(state));

    const [navigationBarItems, setNavigationBarItems] = useState([
        {
            id: "chat",
            name: "Chat",
            icon: <IconChatBubble/>,
            path: RoutesConfig.app.CHAT_CENTER,
            allowedRoles: [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN],
            onclick: function () {navigate(this.path)},
            position: "top"
        },
        {
            id: "chatbots",
            name: "Chatbots",
            path: RoutesConfig.app.CHATBOT_GALLERY,
            icon: <IconRobot/>,
            allowedRoles: [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN],
            onclick: function () {navigate(this.path)},
            position: "top"
        },
        {
            id: "chatbotStudio",
            name: "Craft",
            path: RoutesConfig.app.CHATBOT_STUDIO,
            icon: <IconFlask/>,
            allowedRoles: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
            onclick: function () {navigate(this.path)},
            position: "top"
        },
        {
            id: "knowledgeBaseStudio",
            name: "Corpus",
            path: RoutesConfig.app.KNOWLEDGE_BASE_STUDIO,
            icon: <IconDatabase/>,
            allowedRoles: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
            onclick: function () {navigate(this.path)},
            position: "top"
        },
        {
            id: "adminCenter",
            name: "Admin",
            path: RoutesConfig.app.ADMIN_CENTER,
            icon: <IconSecurity/>,
            allowedRoles: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
            onclick: function () {navigate(this.path)},
            position: "top"
        },
        {
            id: "logout",
            name: "Logout",
            path: null,
            icon: <IconLogout/>,
            allowedRoles: [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN],
            onclick: function () {
                dispatch(terminateSession());
            },
            position: "bottom"
        }
    ]);

    useEffect(() => {
        if (userStatus !== AsyncStatus.SUCCESS) {
            return
        }
        if(userRole !== "admin" && userRole !== "super_admin") {
            setNavigationBarItems(prevState => {
                return prevState.filter(item => item.id !== "admin")
            })
        }
    }, [userStatus, userRole])
    
    return (
        <div className="side-navigation-bar">
            <div className="items medium-scroll-bar">
                {navigationBarItems.map((item, index) => {
                    const isSelected =  pathUtil.startsWith({currentPath: location.pathname, targetPath: item.path})
                    const isVisible = userDataInitialized && item.allowedRoles.includes(userRole)
                    return (
                        <div
                            key={index}
                            className={`item ${item.position} ${isSelected ? 'selected' : ''}`}
                            style={{
                                visibility: isVisible ? 'visible' : 'hidden',
                                opacity: isVisible ? 1 : 0
                            }}
                            onClick={() => {
                                item.onclick()
                            }}
                        >
                            <div className={"icon-wrapper"}>
                                {item.icon}
                            </div>
                            <p className={"text"}>{item.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default SideNavigationBar;