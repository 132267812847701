import SiteConfig from 'configs/siteConfig';

const TRANSACTION_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/transactions`;

const transactionService = {

    transferQuotaToUsersForCurrentUser: async ({token, recipientUserIds, amount}) => {
        const endpoint = `${TRANSACTION_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    recipient_user_ids: recipientUserIds,
                    amount: amount
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to transfer quota to users for current user.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }
}

export default transactionService;