import './AdminCenterSidePanel.css'
import SidePanelLayout from "components/shared/layouts/SidePanelLayout/SidePanelLayout";
import RoutesConfig from "configs/routesConfig";

const AdminCenterSidePanel = () => {

    const items = [
        {
            id: "teachers",
            title: "Teachers",
            path: RoutesConfig.app.adminCenter.TEACHERS
        },
        {
            id: "students",
            title: "Students",
            path: RoutesConfig.app.adminCenter.STUDENTS
        },
        {
            id: "user-invitation",
            title: "Invitation",
            path: RoutesConfig.app.adminCenter.USER_INVITATION
        }
        // {
        //     id: "openai-chat-models",
        //     title: "OpenAI Models",
        //     path: RoutesConfig.app.adminCenter.OPENAI_CHAT_MODELS,
        // },
        // {
        //     id: "openai-resources",
        //     title: "OpenAI Resources",
        //     path: RoutesConfig.app.adminCenter.OPENAI_RESOURCES,
        // },
        // {
        //     id: "sketch-engine-resources",
        //     title: "Sketch Engine Resources",
        //     path: RoutesConfig.app.adminCenter.SKETCH_ENGINE_RESOURCES,
        // },
    ]

    return (
        <SidePanelLayout
            title={
                `Admin Functions`
            }
            items={
                items
            }
        />
    )
}

export default AdminCenterSidePanel;