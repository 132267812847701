import { createSlice } from '@reduxjs/toolkit';
import { performLogin } from './loginThunks';
import {AsyncStatus} from "constants/asyncStatus";

const initialState = {
    status: AsyncStatus.IDLE,
    error: null,    // { error: 'error message', details: 'error details' }
}

const loginSlice = createSlice({
    name: 'auth/login',
    initialState,
    reducers: {
        clearLogin: (state) => {
            state.status =  AsyncStatus.IDLE;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(performLogin.pending, (state) => {
                state.status =  AsyncStatus.PENDING;
            })
            .addCase(performLogin.fulfilled, (state, action) => {
                state.status =  AsyncStatus.SUCCESS;
            })
            .addCase(performLogin.rejected, (state, action) => {
                state.error = action.payload || { error: 'Unknown error', details: 'No additional details provided' };
                state.status = AsyncStatus.ERROR;
            });
    },
});

export const { clearLogin } = loginSlice.actions;

export default loginSlice.reducer;