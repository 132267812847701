import './ChatMessageGrid.css'
import IconRobot from "assets/vectors/IconRobot";
import IconUser from "assets/vectors/IconUser";
import {PseudoOpenAIThreadMessageId} from "constants/pseudoOpenAIThreadMessageId";
import {useEffect, useMemo, useRef, useState} from "react";
import React from 'react';
import CodeBlock from "components/shared/markdownComponents/CodeBlock/CodeBlock";
import {OpenAIMessageRole} from "constants/openAIMessageRole";
import CitationLink from "components/shared/markdownComponents/CitationLink/CitationLink";
import {useParams} from "react-router-dom";
import MarkdownView from "components/shared/views/MarkdownView/MarkdownView";
import LoadingText from "../../../../../shared/views/LoadingText/LoadingText";

const ChatMessageGrid = ({id, role, content, annotations}) => {

    const { 'openai-thread-id': openAIThreadId } = useParams();

    const [isMarkdown, setIsMarkdown] = useState(false)
    const isMarkdownSet = useRef(false)

    const isPseudoMessage = useMemo(() => {
        return [PseudoOpenAIThreadMessageId.ASSISTANT, PseudoOpenAIThreadMessageId.USER].includes(id)
    }, [id])

    useEffect(() => {
        const delay = 300;
        if (! isMarkdownSet.current && ! isPseudoMessage) {
            setTimeout(() => {
                setIsMarkdown(true)
            }, delay);
            isMarkdownSet.current = true
        }
    }, [isMarkdownSet, isPseudoMessage])

    const preprocessContent = (content) => {
        const regex =  /(【.*?†.*?】)/g;
        return content.replace(regex, '[$1](#)');
    };

    const CustomLink = React.memo(({ ...props }) => {
        return <CitationLink {...props} openAIThreadId={openAIThreadId} annotations={annotations} />
    })

    return (
        <div className={`chat-message-grid ${role}`}>

            <div className={"assistant-avatar"}>
                <IconRobot/>
            </div>

            <div className={'chat-message-bubble-container'}>
                <div className={"chat-message-bubble allows-select"}>
                    <div className={"chat-message-content-wrapper"}>
                        <div className={"chat-message-content"}>
                            {
                                role === OpenAIMessageRole.USER || ! isMarkdown || ! content? (
                                    <p className={"raw-message"}>
                                        {content ? content.replace(/`/g, "'") : <LoadingText initialNumberOfDots={1} maxNumberOfDots={6} timeGap={120}/>}
                                    </p>
                                ) : (
                                    <MarkdownView
                                        components={{
                                            a: CustomLink,
                                            code: CodeBlock,
                                        }}
                                    >
                                        {preprocessContent(content)}
                                    </MarkdownView>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={"user-avatar"}>
                <IconUser/>
            </div>

        </div>
    )
}

const MemoizedChatMessageGrid = React.memo(ChatMessageGrid);

export default MemoizedChatMessageGrid;