import * as React from "react";
const SVGComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        xmlSpace="preserve"
        {...props}
    >
        <path
            d="M14 17V3c0-1.1.9-2 2-2h0c1.1 0 2 .9 2 2v14m-4-3h4m8 17H6l2.7-12.4c.2-.9 1-1.6 2-1.6h10.8c.9 0 1.8.7 2 1.6zM9 21h14m-12 6v3m10-1v1"
            style={{
                fill: "none",
                stroke: "inherit",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
    </svg>
);
export default SVGComponent;
