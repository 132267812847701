import {createAsyncThunk} from "@reduxjs/toolkit";
import userService from "services/userService";

const getUser = createAsyncThunk(
    'entities/user/getUser',
    async ( {token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const responseJson = await userService.getCurrentUser({
                token: token
            })
            if (responseJson.hasOwnProperty("error")) {
                return rejectWithValue(responseJson);
            }
            return {
                user: responseJson.user
            }
        } catch (error) {
            return rejectWithValue({
                error: 'Fail to get user data.',
                details: error.message
            });
        }
    }
);

export {
    getUser
};