import { createSelector } from '@reduxjs/toolkit'

const selectOpenAIAssistants = (state) => state.entities.openAIAssistants;

const selectOpenAIAssistantsData = createSelector(
    [selectOpenAIAssistants],
    (openAIAssistants) => openAIAssistants?.data || null
);

const selectOpenAIAssistantsDataInitialized = createSelector(
    [selectOpenAIAssistants],
    (openAIAssistants) => !!openAIAssistants?.data
);

const selectOpenAIAssistantDataById = createSelector(
    [selectOpenAIAssistantsData, (state, assistantId) => assistantId],
    (allAssistants, assistantId) => allAssistants?.find(assistant => assistant.id === assistantId)
);

const selectOpenAIAssistantsDataForUser = createSelector(
    [selectOpenAIAssistantsData, (state, userId) => userId],
    (allAssistants, userId) => allAssistants?.filter(assistant => assistant.user_id === userId)
);

const selectOpenAIAssistantsDataForUserInitialized = createSelector(
    [selectOpenAIAssistantsDataForUser],
    (userAssistants) => !!userAssistants
);

const selectOpenAIAssistantsStatus = createSelector(
    [selectOpenAIAssistants],
    (openAIAssistants) => openAIAssistants.status
);

export {
    selectOpenAIAssistants,
    selectOpenAIAssistantsData,
    selectOpenAIAssistantsDataInitialized,
    selectOpenAIAssistantsDataForUser,
    selectOpenAIAssistantsDataForUserInitialized,
    selectOpenAIAssistantDataById,
    selectOpenAIAssistantsStatus
}