import './ContactPage.css'
import bgPhoto from 'assets/images/photo-run-run-shaw-tower.jpg'
const ContactPage = () => {
    return (
        <div className="contact-page">

            <div className={"background"}>
                <img src={bgPhoto} alt="background"/>
            </div>

            <div className={"content"}>

                <h1>Contact Us</h1>
                <p style={{fontSize: '1.2rem'}}>
                    For any inquiries, please contact the founder of <i>CorpusChat</i> at: <br/>
                    <br/>
                    <b>E-mail: </b>
                    <a href="mailto:lisa@hku.hk">
                        lisa@hku.hk
                    </a>
                    <br/>
                    <br/>
                    <b>Office: </b>
                    <a href="https://www.google.com/maps?q=Run Run Shaw Tower, Pok Fu Lam Rd, Lung Fu Shan, 香港" target="_blank" rel="noreferrer">
                        Centre for Applied English Studies, The University of Hong Kong
                    </a>
                    <br/>
                </p>

            </div>

        </div>


    );
}

export default ContactPage;