function createNestedRoutes(basePath, routes) {
    const nestedRoutes = {};
    for (const key in routes) {
        switch (typeof routes[key]) {
            case 'string':
                if (routes[key] !== routes.BASE_PATH) {
                    nestedRoutes[key] = `${basePath}${routes[key]}`;
                } else {
                    nestedRoutes[key] = `${basePath}`;
                }
                break;
            case 'function':
                nestedRoutes[key] = (id) => `${basePath}${routes[key](id)}`;
                break;
            case 'object':
                nestedRoutes[key] = createNestedRoutes(`${basePath}${routes[key].BASE_PATH || ''}`, routes[key]);
                break;
            default:
                break;
        }
    }
    return nestedRoutes;
}

let RoutesConfig = {

    HOME: '/',
    ABOUT: '/about',
    GUIDE: '/guide',
    PROMPT: '/prompt',
    FAQ: '/faq',
    CONTACT: '/contact',
    TUTORIAL: '/tutorial',
    BLOG: '/blog',

    AUTH: '/auth',
    auth: {
        BASE_PATH: '/auth',
        LOGIN: '/login',
        SIGN_UP: '/sign-up',
        EMAIL_VERIFICATION: '/email-verification',
    },

    APP: '/app',
    app: {
        BASE_PATH: '/app',
        CHAT_CENTER: '/chat-center',
        chatCenter: {
            BASE_PATH: '/chat-center',
            openAIThreadId: (id) => `/${id}`,
            NEW: '/new',
        },
        CHATBOT_GALLERY: '/chatbot-gallery',
        chatbotGallery: {
            BASE_PATH: '/chatbot-gallery',
            openAIAssistantId: (id) => `/${id}`,
        },
        CHATBOT_STUDIO: '/chatbot-studio',
        chatbotStudio: {
            BASE_PATH: '/chatbot-studio',
            openAIAssistantId: (id) => `/${id}`,
            NEW: '/new',
        },
        KNOWLEDGE_BASE_STUDIO: '/knowledge-base-studio',
        knowledgeBaseStudio: {
            BASE_PATH: '/knowledge-base-studio',
            openAIVectorStoreId: (id) => `/${id}`,
            NEW: '/new',
        },
        ADMIN_CENTER: '/admin-center',
        adminCenter: {
            BASE_PATH: '/admin-center',
            STUDENTS: '/students',
            USER_INVITATION: '/user-invitation',
            TEACHERS: '/teachers',
            OPENAI_CHAT_MODELS: '/openai-chat-models',
            OPENAI_RESOURCES: '/openai-resources',
            SKETCH_ENGINE_RESOURCES: '/sketch-engine-resources',
        },
    },
};

RoutesConfig = createNestedRoutes('', RoutesConfig);

export default RoutesConfig;