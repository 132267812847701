import './styles/AuthForm.css';
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import authService from "services/authService";
import {useDispatch, useSelector} from "react-redux";
import {AsyncStatus} from "constants/asyncStatus";
import {selectEmailVerificationStatus} from "features/auth/emailVerification/emailVerificationSelectors";
import {performEmailVerification} from "features/auth/emailVerification/emailVerificationThunks";
import {clearEmailVerification} from "features/auth/emailVerification/emailVerificationSlice";
import {toast} from "react-toastify";
import ToastifyConfig from "configs/toastifyConfig";
import RoutesConfig from "configs/routesConfig";


const EmailVerificationForm = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const dispatch = useDispatch();

    const emailVerificationStatus = useSelector(selectEmailVerificationStatus);

    const [isEditable, setIsEditable] = useState(true);

    const [remainingTime, setRemainingTime] = useState(60);

    const verificationSentRef = useRef(false);

    const emailRef = useRef(null);

    useEffect(() => {
        const { email } = location.state || {};
        if (!verificationSentRef.current && typeof email === 'string') {
            emailRef.current = email;
            verificationSentRef.current = true;
            const toastId = toast.loading('Sending verification email...');
            authService.sendEmailVerification({
                email: emailRef.current
            })
            .then((responseJson) => {
                if (responseJson.hasOwnProperty("error")) {
                    console.log(responseJson.error);
                    toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Verification email failed!", type: "error"});
                }
                else {
                    toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Verification email sent!", type: "success"});
                }
            })
        }
        else if (typeof email !== 'string') {
            navigate(RoutesConfig.auth.SIGN_UP);
        }
    }, [location, navigate]);

    useEffect(() => {
        if (emailVerificationStatus === AsyncStatus.SUCCESS) {
            dispatch(clearEmailVerification());
            navigate(RoutesConfig.app.CHAT_CENTER);
        }
        else if (emailVerificationStatus === AsyncStatus.ERROR) {
            dispatch(clearEmailVerification());
            setIsEditable(true);
        }
    }, [emailVerificationStatus, navigate, dispatch]);

    useEffect(() => {
        if (remainingTime > 0) {
            setTimeout(() => {
                setRemainingTime(remainingTime - 1);
            }, 1000);
        }
    }, [remainingTime]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditable(false);
        const verificationCode = e.target['verification-code'].value;
        dispatch(performEmailVerification({
            email: emailRef.current,
            verification_code: verificationCode
        }));
    }

    return (
        <form className={"auth-form"} onSubmit={handleSubmit}>

            <h1 className={"title"}>
                Email Verification
            </h1>

            <div className={"input-group"}>
                <label htmlFor="verification-code">Verification Code</label>
                <input type="text" id="verification-code" name="verification-code" disabled={!isEditable} required/>
            </div>

            <div className={"links no-select"}>
                <p onClick={() => {
                    if (remainingTime <= 0) {
                        setRemainingTime(60);
                        const toastId = toast.loading('Sending verification email...');
                        authService.sendEmailVerification({
                            email: emailRef.current
                        })
                        .then((responseJson) => {
                            if (responseJson.hasOwnProperty("error")) {
                                console.log(responseJson.error);
                                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Verification email failed!", type: "error"});
                            }
                            else {
                                toast.update(toastId, {...ToastifyConfig.loadingToastUpdateOptions, render: "Verification email sent!", type: "success"});
                            }
                        })
                    }
                }}>
                    {
                        remainingTime <= 0 ?
                        "Resend Verification Code Now"
                        :
                        `Resend option available after ${remainingTime} seconds`
                    }
                </p>
            </div>

            <button type="submit" disabled={!isEditable}>
                Verify
            </button>

        </form>
    );
}

export default EmailVerificationForm;