import './ChatbotDetailsMainPanel.css'
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    selectOpenAIAssistantDataById, selectOpenAIAssistantsDataInitialized,
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";
import {
    selectOpenAIChatModelsData, selectOpenAIChatModelsDataInitialized,
} from "features/entities/openAIChatModels/openAIChatModelsSelectors";
import {useEffect, useState} from "react";
import AssistantFavoriteButton from "components/shared/buttons/AssistantFavoriteButton/AssistantFavoriteButton";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {createOpenAIThread} from "features/entities/openAIThreads/openAIThreadsThunks";
import {selectSessionToken} from "features/session/sessionSelectors";
import {toast} from "react-toastify";
import MainPanelBackButton from "components/shared/buttons/MainPanelBackButton/MainPanelBackButton";
import RoutesConfig from "configs/routesConfig";
import PrimaryButton from "../../../core/PrimaryButton/PrimaryButton";


const ChatbotDetailsMainPanel = () => {

    const { 'openai-assistant-id': openAIAssistantId } = useParams();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const sessionToken = useSelector(state => selectSessionToken(state))

    const [chatbotName, setChatbotName] = useState(null)
    const [chatbotModel, setChatbotModel] = useState(null)
    const [chatbotDescription, setChatbotDescription] = useState(null)
    const [chatbotSamplePrompts, setChatbotSamplePrompts] = useState(null)

    const openAIChatModelsData = useSelector(selectOpenAIChatModelsData)
    const openAIChatModelsDataInitialized = useSelector(state => selectOpenAIChatModelsDataInitialized(state))

    const openAIAssistantData = useSelector(state => selectOpenAIAssistantDataById(state, openAIAssistantId));
    const openAIAssistantDataInitialized = useSelector(state => selectOpenAIAssistantsDataInitialized(state));

    useEffect(() => {
        if (openAIChatModelsDataInitialized && openAIAssistantDataInitialized) {
            setChatbotName(openAIAssistantData.name)
            setChatbotModel(openAIChatModelsData.find(item => item.id === openAIAssistantData.openai_chat_model_id).name)
            setChatbotDescription(openAIAssistantData.description)
            setChatbotSamplePrompts(openAIAssistantData.sample_prompts.filter(prompt => prompt !== ""))
        }
        else {
            setChatbotName(null)
            setChatbotModel(null)
            setChatbotDescription(null)
            setChatbotSamplePrompts(null)
        }
    }, [openAIAssistantData, openAIChatModelsData, openAIChatModelsDataInitialized, openAIAssistantDataInitialized])

    return (
        <MainPanelLayout
            titleArea={
                <MainPanelBackButton backPath={RoutesConfig.app.CHATBOT_GALLERY}/>
            }
            contentArea={
                <div className="content-area chatbot-details">
                    <div className="info-tab">
                        <div className="row">
                            <h2 className={'title allows-select'}>
                                {chatbotName ||  <Skeleton/>}
                            </h2>
                            <div className={'favorite-button'}>
                                <AssistantFavoriteButton openAIAssistantId={openAIAssistantId}/>
                            </div>
                        </div>
                        <div className="row allows-select">
                            <p className={'tag'}>
                                Based on {chatbotModel || <Skeleton width={'10rem'}/>}
                            </p>
                        </div>
                        <div className="col allows-select">
                            <p>
                                <b>Description:</b>
                            </p>
                            <p>
                                {chatbotDescription || <Skeleton count={3}/> }
                            </p>
                        </div>
                        <div className="col allows-select">
                            <p className={'description'}>
                                <b>Sample Prompts:</b>
                            </p>
                            <ol>
                                {
                                    Array.isArray(chatbotSamplePrompts) ? (
                                        chatbotSamplePrompts.map((prompt, index) => {
                                            return (
                                                <li key={index}>
                                                    {prompt}
                                                </li>
                                            )
                                        })
                                    ) : (
                                        Array(4).fill(0).map((_, index) => (
                                            <li key={index}>
                                                <Skeleton/>
                                            </li>
                                        ))
                                    )
                                }
                            </ol>
                        </div>

                        <div className="row">
                            <PrimaryButton
                                className={'button submit'}
                                onClick={async (event) => {
                                    // copy the current URL to the clipboard
                                    await navigator.clipboard.writeText(window.location.href)
                                    toast.success("Link copied to clipboard")
                                }}
                            >
                                Share
                            </PrimaryButton>
                            <PrimaryButton
                                className={'button chat'}
                                onClick={() => {
                                    if (!sessionToken) return
                                    dispatch(createOpenAIThread({
                                        token: sessionToken,
                                        openAIAssistantId: openAIAssistantId,
                                        name: 'New Chat',
                                        description: ''
                                    }))
                                    navigate(RoutesConfig.app.chatCenter.NEW)
                                }}
                            >
                                Chat
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default ChatbotDetailsMainPanel;