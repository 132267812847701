import SiteConfig from 'configs/siteConfig';

const AUTH_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/auth`;

const authService = {

    login: async ({email, password}) => {
        const endpoint = `${AUTH_SERVICE_BASE_URL}/login`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to login.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    signup: async ({name, email, password}) => {
        const endpoint = `${AUTH_SERVICE_BASE_URL}/register`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    password: password,
                }),
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to sign up.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    sendEmailVerification: async ({email}) => {
        const endpoint = `${AUTH_SERVICE_BASE_URL}/send-email-verification`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to send email verification.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    verifyEmail: async ({email, verification_code}) => {
        const endpoint = `${AUTH_SERVICE_BASE_URL}/verify-email`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    verification_code: verification_code
                }),
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to verify email.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    refreshJWT: async ({token}) => {
        const endpoint = `${AUTH_SERVICE_BASE_URL}/refresh-jwt`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to refresh JWT.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }
};

export default authService;