import './BlogPage.css'

const BlogPage = () => {
    return (
        <div className="blog-page">
            <h1>Blogs</h1>
        </div>
    );
}

export default BlogPage;