import React, {useRef, useEffect} from "react";
import './FloatingPanel.css';


const FloatingPanel = ({isFormExisting, setFormExistence, isFormVisible, setFormVisibility, closeOnOutsideClick=true, children}) => {

    const floatingPanelRef = useRef(null);

    // display the form immediately if it exists
    useEffect(() => {
        if (isFormExisting) {
            setFormVisibility(true);
        }
    }, [isFormExisting, setFormVisibility]);

    // hide the form after the animation ends and banner notification being displayed
    useEffect(() => {
        if (!isFormVisible) {
            setTimeout(() => {
                setFormExistence(false);
                setFormVisibility(true);
            }, 500);
        }
    }, [isFormVisible, setFormExistence, setFormVisibility]);

    // hide the form when clicking outside the form
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (floatingPanelRef.current && !floatingPanelRef.current.contains(event.target)) {
                if (!closeOnOutsideClick) {
                    return;
                }
                setFormVisibility(false);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.keyCode === 27) {
                setFormVisibility(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscapeKey);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscapeKey);
        };
    });

    return (
        <>
            <div className={`floating-panel-background ${isFormVisible ? "enter" : "leave"}`} ></div>
            
            <div  className={`floating-panel ${isFormVisible ? "enter" : "leave"}`} ref={floatingPanelRef}>
                {children}
            </div>
        </>
    );
};

export default FloatingPanel;