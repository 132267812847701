import {Route} from "react-router-dom";
import VerticalLayout from "components/shared/routerLayouts/VerticalLayout/VerticalLayout";
import HomePage from "pages/publicPages/HomePage/HomePage";
import RoutesConfig from "configs/routesConfig";
import AboutPage from "pages/publicPages/AboutPage/AboutPage";
import ContactPage from "pages/publicPages/ContactPage/ContactPage";
import BlogPage from "pages/publicPages/BlogPage/BlogPage";
import FAQPage from "pages/publicPages/FAQPage/FAQPage";
import GuidePage from "pages/publicPages/GuidePage/GuidePage";
import SamplePromptPage from "../pages/publicPages/SamplePromptPage/SamplePromptPage";
import TutorialPage from "pages/publicPages/TutorialPage/TutorialPage";

const PublicRoutes = () => {
    return (
        <Route element={<VerticalLayout homePath={RoutesConfig.HOME}/>}>
            <Route index element={<HomePage/>} />
            <Route path={RoutesConfig.ABOUT} element={<AboutPage />} />
            <Route path={RoutesConfig.BLOG} element={<BlogPage />} />
            <Route path={RoutesConfig.FAQ} element={<FAQPage />} />
            <Route path={RoutesConfig.GUIDE} element={<GuidePage />} />
            <Route path={RoutesConfig.PROMPT} element={<SamplePromptPage />} />
            <Route path={RoutesConfig.CONTACT} element={<ContactPage />} />
            <Route path={RoutesConfig.TUTORIAL} element={<TutorialPage />} />
        </Route>
    );
}

export default PublicRoutes;