import './ChatCenterMainPanel.css'
import {useMemo, useRef} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {UIDataStatus} from "constants/uiDataStatus";
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import LoadingView from "components/shared/views/LoadingView/LoadingView";
import {selectChatMainPanelThreadDataByThreadId} from "features/ui/mainPanels/chatMainPanel/chatMainPanelSelectors";
import ChatMessageList from "./components/ChatMessageList/ChatMessageList";
import SamplePromptList from "./components/SamplePromptList/SamplePromptList";
import EditableChatTitle from "./components/EditableChatTitle/EditableChatTitle";
import ChatInputField from "./components/ChatInputField/ChatInputField";
import useMessageSubmitEnabled from "./hooks/useMessageSubmitEnabled";
import useSubmitMessage from "./hooks/useSubmitMessage";
import useFetchOpenAIThreadMessages from "./hooks/useFetchOpenAIThreadMessages";
import useSyncOpenAIThreadToUI from "./hooks/useSyncOpenAIThreadToUI";
import IconClean from "assets/vectors/IconClean";
import IconDownload from "assets/vectors/IconDownload";
import useDownloadOpenAIThreadMessages from "./hooks/useDownloadOpenAIThreadMessages";
import useClearOpenAIThreadMessages from "./hooks/useClearOpenAIThreadMessages";
import IconPrinter from "assets/vectors/IconPrinter";

const ChatCenterMainPanel = () => {

    const { 'openai-thread-id': openAIThreadId } = useParams();

    useFetchOpenAIThreadMessages({openAIThreadId})
    useSyncOpenAIThreadToUI({openAIThreadId})

    const {messageSubmitEnabled} = useMessageSubmitEnabled({ openAIThreadId })
    const {submitMessage} = useSubmitMessage({openAIThreadId})
    const {saveOpenAIThreadMessagesAsTXT, saveOpenAIThreadMessagesAsPDF} = useDownloadOpenAIThreadMessages({openAIThreadId})
    const {clearMessages} = useClearOpenAIThreadMessages({openAIThreadId})

    const chatMainPanelThreadData = useSelector(state => selectChatMainPanelThreadDataByThreadId(state, openAIThreadId));
    const chatContentAreaRef = useRef(null);
    const chatMessageListRef = useRef(null);

    const isReady = useMemo(() => chatMainPanelThreadData?.status === UIDataStatus.DATA_READY, [chatMainPanelThreadData?.status]);
    const samplePrompts = useMemo(() => chatMainPanelThreadData?.sample_prompts, [chatMainPanelThreadData?.sample_prompts]);
    const messages = useMemo(() => chatMainPanelThreadData?.messages, [chatMainPanelThreadData?.messages]);

    const titleArea = useMemo(() => {
        if (!isReady) return null;
        return (
            <div className={'title-area chat-center'}>
                <h2 className={"chat-title"}>
                    <EditableChatTitle
                        openAIThreadId={openAIThreadId}
                        openAIThreadTitle={chatMainPanelThreadData?.name}
                    />
                    {
                        (Array.isArray(messages) && messages.length > 0) && (
                            <>
                                <button
                                    className={'title-button'}
                                    disabled={!messageSubmitEnabled}
                                    onClick={() => {saveOpenAIThreadMessagesAsPDF(chatContentAreaRef.current)}}
                                >
                                    <IconPrinter/>&nbsp;Print
                                </button>
                                <button
                                    className={'title-button'}
                                    disabled={!messageSubmitEnabled}
                                    onClick={() => {saveOpenAIThreadMessagesAsTXT()}}
                                >
                                    <IconDownload/>&nbsp;Save
                                </button>
                                <button
                                    className={'title-button'}
                                    disabled={!messageSubmitEnabled}
                                    onClick={() => {clearMessages()}}
                                >
                                    <IconClean/>&nbsp;Clear
                                </button>
                            </>
                        )
                    }
                </h2>
            </div>
        )
    }, [isReady, openAIThreadId, chatMainPanelThreadData?.name, messages, messageSubmitEnabled, saveOpenAIThreadMessagesAsPDF, saveOpenAIThreadMessagesAsTXT, clearMessages])

    const contentArea = useMemo(() => {
        if (!isReady) return (<LoadingView/>);
        return (
            <div className="content-area chat-center" ref={chatContentAreaRef} key={openAIThreadId}>
                {(messages.length > 0) ? (
                    <ChatMessageList
                        messages={messages}
                        scrollPaneRef={chatContentAreaRef}
                        ref={chatMessageListRef}
                    />
                ) : (
                    <SamplePromptList
                        samplePrompts={samplePrompts}
                        onSubmit={submitMessage}
                        messageSubmitEnabled={messageSubmitEnabled}
                    />
                )}
            </div>
        )
    }, [isReady, messages, samplePrompts, submitMessage, messageSubmitEnabled, chatContentAreaRef, openAIThreadId])

    const operationArea = useMemo(() => {
        if (!isReady) return null;
        return (
            <div className="operation-area chat-center">
                <div className={'tool-area'}>
                </div>
                <div className={'input-area'}>
                    <ChatInputField
                        onSubmit={submitMessage}
                        messageSubmitEnabled={messageSubmitEnabled}
                    />
                </div>
            </div>
        )
    }, [isReady, submitMessage, messageSubmitEnabled])

    return (
        <MainPanelLayout
            titleArea={titleArea}
            contentArea={contentArea}
            operationArea={operationArea}
        />
    )
}

export default ChatCenterMainPanel;