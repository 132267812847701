import { createSelector } from '@reduxjs/toolkit'

const selectOpenAIChatModels = (state) => state.entities.openAIChatModels;

const selectOpenAIChatModelsData = (state) => selectOpenAIChatModels(state).data;

const selectOpenAIChatModelsDataInitialized = createSelector(
    [selectOpenAIChatModels],
    (openAIChatModels) => !!openAIChatModels?.data
);

const selectOpenAIChatModelsStatus = (state) => selectOpenAIChatModels(state).status;

export {
    selectOpenAIChatModels,
    selectOpenAIChatModelsData,
    selectOpenAIChatModelsDataInitialized,
    selectOpenAIChatModelsStatus
}