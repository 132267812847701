import './MainPanelTitle.css'

const MainPanelTitle = ({ title }) => {
    return (
        <div className={'main-panel-title'}>
            <h2 className={"title"}>{title}</h2>
        </div>
    )
}

export default MainPanelTitle;