import {Route} from "react-router-dom";
import FixedHeightLayout from "components/shared/routerLayouts/FixedHeightLayout/FixedHeightLayout";
import LoginPage from "pages/authPages/LoginPage/LoginPage";
import SignUpPage from "pages/authPages/SignUpPage/SignUpPage";
import EmailVerificationPage from "pages/authPages/EmailVerificationPage/EmailVerificationPage";
import RoutesConfig from "configs/routesConfig";

const AuthRoutes = () => {
    return (
        <Route path={RoutesConfig.AUTH} element={<FixedHeightLayout homePath={RoutesConfig.HOME}/>}>
            <Route path={RoutesConfig.auth.LOGIN} element={<LoginPage/>} />
            <Route path={RoutesConfig.auth.SIGN_UP} element={<SignUpPage/>} />
            <Route path={RoutesConfig.auth.EMAIL_VERIFICATION} element={<EmailVerificationPage/>} />
        </Route>
    );
}

export default AuthRoutes;