import {createSelector} from "@reduxjs/toolkit";

const selectUsers = state => state.entities.users;

const selectUsersData = state => selectUsers(state).data;

const selectUsersDataInitialized = createSelector(
    [selectUsersData],
    usersData => (usersData !== null)
);

const selectUsersStatus = state => selectUsers(state).status;

const selectUsersByIds = (state, userIds) => {
    const usersData = selectUsersData(state);
    if (!usersData) {
        return [];
    }
    return userIds.map(userId => usersData[userId]);
}

export {
    selectUsers,
    selectUsersData,
    selectUsersDataInitialized,
    selectUsersStatus,
    selectUsersByIds
}
