import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectSessionToken} from "features/session/sessionSelectors";
import {
    selectOpenAIAssistantsData, selectOpenAIAssistantsDataInitialized,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";
import {useEffect, useRef, useState} from "react";
import {AsyncStatus} from "constants/asyncStatus";
import IconGarbageBin from "assets/vectors/IconGarbageBin";
import {
    selectOpenAIThreadsData,
    selectOpenAIThreadsDataInitialized, selectOpenAIThreadsStatus
} from "features/entities/openAIThreads/openAIThreadsSelectors";
import IconPencil from "assets/vectors/IconPencil";
import {
    createOpenAIThread,
    deleteOpenAIThread
} from "features/entities/openAIThreads/openAIThreadsThunks";
import IconBranch from "assets/vectors/IconBranch";
import {toast} from "react-toastify";
import timeUtil from "utils/timeUtil";
import RoutesConfig from "configs/routesConfig";

const useChatCenterSidePanelItems = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const sessionToken = useSelector(selectSessionToken);

    const openAIAssistantsData = useSelector(state => selectOpenAIAssistantsData(state))
    const openAIAssistantsDataInitialized = useSelector(state => selectOpenAIAssistantsDataInitialized(state))
    const openAIAssistantsStatus = useSelector(state => selectOpenAIAssistantsStatus(state))

    const openAIThreadsData = useSelector(state => selectOpenAIThreadsData(state))
    const openAIThreadsDataInitialized = useSelector(state => selectOpenAIThreadsDataInitialized(state))
    const openAIThreadsStatus = useSelector(state => selectOpenAIThreadsStatus(state))

    const [items, setItems] = useState(null)

    const [isReady, setIsReady] = useState(false)

    const initialized = useRef(false)


    useEffect(() => {

        if (initialized.current) {
            if (openAIThreadsStatus !== AsyncStatus.SUCCESS) return
            if (openAIAssistantsStatus !== AsyncStatus.SUCCESS) return
        } else {
            if (!openAIThreadsDataInitialized) return
            if (!openAIAssistantsDataInitialized) return
        }

        if (! Array.isArray(openAIThreadsData)) return
        if (! Array.isArray(openAIAssistantsData)) return

        const itemsBuffer = []

        const sortedOpenAIThreadsData = [...openAIThreadsData]
        sortedOpenAIThreadsData.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
        })

        sortedOpenAIThreadsData.forEach(item => {
            const openAIAssistant = openAIAssistantsData.find(assistant => assistant.id === item.openai_assistant_id)
            if (!openAIAssistant) return

            const path = RoutesConfig.app.chatCenter.openAIThreadId(item.id)

            itemsBuffer.push({
                id: item.id,
                title: item.name,
                description: openAIAssistant.name,
                tag: timeUtil.convertToShortenDateOnly(item.created_at),
                path: path,
                menuConfigs: [
                    {
                        icon: <IconPencil/>,
                        text: 'New Chat',
                        onClick: function () {
                            if (!sessionToken) return
                            dispatch(createOpenAIThread({
                                token: sessionToken,
                                openAIAssistantId: openAIAssistant.id,
                                name: 'New Chat',
                                description: ''
                            }))
                            navigate( RoutesConfig.app.chatCenter.NEW)
                        }
                    },
                    {
                        icon: <IconBranch/>,
                        text: 'Branch',
                        onClick: function () {
                            toast.info('Branching is not supported yet')
                        }
                    },
                    {
                        icon: <IconGarbageBin/>,
                        text: 'Delete',
                        onClick: async function () {
                            if (!sessionToken) return
                            await dispatch(deleteOpenAIThread({
                                token: sessionToken,
                                openAIThreadId: item.id
                            }))
                            if (window.location.pathname.includes(path)) {
                                navigate( RoutesConfig.app.CHAT_CENTER)
                            }
                        }
                    }
                ]
            })
        })

        setItems(itemsBuffer)

        initialized.current = true

    }, [
        navigate,
        dispatch,
        sessionToken,
        openAIThreadsStatus,
        openAIAssistantsStatus,
        openAIThreadsData,
        openAIAssistantsData,
        openAIAssistantsDataInitialized,
        openAIThreadsDataInitialized,
    ])

    useEffect(() => {
        setIsReady(initialized.current)
    }, [items])

    return {items, isReady}
}

export default useChatCenterSidePanelItems;