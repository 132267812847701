import {useDispatch, useSelector} from "react-redux";
import {selectSessionToken} from "features/session/sessionSelectors";
import {toast} from "react-toastify";
import {
    downloadCitedOpenAIVectorStoreFile
} from "features/entities/openAIVectorStoreFiles/openAIVectorStoreFilesThunks";
import React from "react";
import './CitationLink.css'

const CitationLink = ({ href, children, openAIThreadId, annotations}) => {

    const sessionToken = useSelector(selectSessionToken);
    const dispatch = useDispatch();

    const handleOnCitationClick = (e, index, annotation) => {
        e.stopPropagation()
        e.preventDefault()
        if (!annotation) {
            toast.info('Cited file not provided by AI.')
        }
        else {
            const fileId = annotation?.file_citation?.file_id
            dispatch(downloadCitedOpenAIVectorStoreFile({
                token: sessionToken,
                openAIThreadId: openAIThreadId,
                openAIVectorStoreFileInstanceId: fileId
            }))
        }
    }

    if (href === '#') {
        const citationText = String(children)
        let index = -1;
        let annotation = null;
        if (Array.isArray(annotations)) {
            index = annotations.findIndex(annotation => annotation.text === citationText)
            annotation = annotations[index]
        }
        return (
            <span
                className={'citation-link no-select'}
                onClick={(e) => {
                    handleOnCitationClick(e, index, annotation)
                }}
            >
                {annotation ? `[${citationText.replace(/【(.*?)†.*?】/g, '$1')}]` : `[X]`}
            </span>
        );
    }
    return (
        <a href={href}>{children}</a>
    );
};

export default CitationLink;