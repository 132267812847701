import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.00004 12L3.26904 3.12601C9.80185 5.02568 15.9624 8.0268 21.485 12C15.9628 15.9738 9.80262 18.9756 3.27004 20.876L6.00004 12ZM6.00004 12H13.5"
            strokeOpacity={1}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default SVGComponent;
