import './ChatbotGalleryMainPanel.css'
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import ChatbotPreviewCard from "components/shared/fields/ChatbotPreviewCard/ChatbotPreviewCard";
import {
    selectOpenAIAssistantsData,
    selectOpenAIAssistantsStatus
} from "features/entities/openAIAssistants/openAIAssistantsSelectors";
import {useSelector} from "react-redux";
import {AsyncStatus} from "constants/asyncStatus";
import {useEffect, useState} from "react";
import {
    selectUserFavoriteOpenAIAssistantsData,
    selectUserFavoriteOpenAIAssistantsStatus
} from "features/entities/userFavoriteOpenAIAssistants/userFavoriteOpenAIAssistantsSelectors";
import MainPanelTitle from "components/shared/fields/MainPanelTitle/MainPanelTitle";

const ChatbotGalleryMainPanel = () => {

    const tags = ['ALL', 'CAES', 'CLEP']

    const [selectedTag, setSelectedTag] = useState(tags[0])

    const [chatbotPreviews, setChatbotPreviews] = useState([])

    const openAIAssistantsData = useSelector(selectOpenAIAssistantsData)
    const openAIAssistantsStatus = useSelector(selectOpenAIAssistantsStatus)

    const userFavoriteOpenAIAssistantsData = useSelector(selectUserFavoriteOpenAIAssistantsData)
    const userFavoriteOpenAIAssistantsStatus = useSelector(selectUserFavoriteOpenAIAssistantsStatus)

    useEffect(() => {
        if (openAIAssistantsStatus === AsyncStatus.SUCCESS && userFavoriteOpenAIAssistantsStatus === AsyncStatus.SUCCESS) {
            const userFavoriteOpenAIAssistantIds = userFavoriteOpenAIAssistantsData.map(item => item.openai_assistant_id)
            let items = openAIAssistantsData.map(
                item => ({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    tags: item.tags,
                    isFavorite: userFavoriteOpenAIAssistantIds.includes(item.id)
                }
            ))
            if (selectedTag !== 'ALL') {
                items = items.filter(item => item.tags.includes(selectedTag))
            }
            setChatbotPreviews(items)
        }
    }, [openAIAssistantsStatus, userFavoriteOpenAIAssistantsStatus, openAIAssistantsData, userFavoriteOpenAIAssistantsData, selectedTag])

    return (
        <MainPanelLayout
            titleArea={
                <MainPanelTitle title={'Chatbot Gallery'}/>
            }
            contentArea={
                <div className="content-area chatbot-gallery">
                    <div className="tags">
                        Tags:
                        {
                            tags.map(tag => (
                                <span
                                    className={`tag ${tag===selectedTag? 'selected': ''}`}
                                    key={tag}
                                    onClick={() => setSelectedTag(tag)}
                                >
                                    {tag}
                                </span>
                            ))
                        }
                    </div>
                    <div className={'chatbot-preview-cards'}>
                        {
                            chatbotPreviews.map(
                                chatbotPreview => (
                                    <ChatbotPreviewCard
                                        key={chatbotPreview.id}
                                        openAIAssistantId={chatbotPreview.id}
                                        openAIAssistantName={chatbotPreview.name}
                                        openAIAssistantDescription={chatbotPreview.description}
                                    />
                                )
                            )
                        }
                    </div>
                </div>
            }
        />
    )
}

export default ChatbotGalleryMainPanel;