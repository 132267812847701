import {createSelector} from "@reduxjs/toolkit";

const selectUserFollowers = state => state.entities.userFollowers;

const selectUserFollowersData = state => selectUserFollowers(state).data;

const selectUserFollowersDataInitialized = createSelector(
    [selectUserFollowersData],
    userFollowersData => (userFollowersData !== null)
);

const selectUserFollowersStatus = state => selectUserFollowers(state).status;

export {
    selectUserFollowers,
    selectUserFollowersData,
    selectUserFollowersDataInitialized,
    selectUserFollowersStatus
}