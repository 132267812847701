import './UserInvitationMainPanel.css'
import MainPanelLayout from "components/shared/layouts/MainPanelLayout/MainPanelLayout";
import React from "react";
import UserInvitationForm from "components/shared/forms/editForms/UserInvitationForm";
import MainPanelTitle from "../../../shared/fields/MainPanelTitle/MainPanelTitle";

const UserInvitationMainPanel = () => {

        return (
            <MainPanelLayout
                titleArea={
                    <MainPanelTitle title={"User Invitation"}/>
                }
                contentArea={
                    <div className={"content-area user-invitation"}>
                        <UserInvitationForm/>
                    </div>
                }
            />
        )
}

export default UserInvitationMainPanel;