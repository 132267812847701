import './ChatbotGalleryPage.css'
import {Outlet} from "react-router-dom";
import AppPageLayout from "components/shared/layouts/AppPageLayout/AppPageLayout";
import ChatbotGallerySidePanel from "components/features/chatbotGallery/ChatbotGallerySidePanel/ChatbotGallerySidePanel";

const ChatbotGalleryPage = () => {

    return (
        <AppPageLayout
            SidePanel={
                <ChatbotGallerySidePanel />
            }
            MainPanel={(
                <Outlet />
            )}
        />
    );
}

export default ChatbotGalleryPage;