import React, { useEffect, useState } from 'react';

const SitemapPage = () => {
    const [sitemapContent, setSitemapContent] = useState('');

    useEffect(() => {
      fetch(`${process.env.PUBLIC_URL}/sitemap.xml`)
        .then(response => response.text())
        .then(data => setSitemapContent(data))
        .catch(error => console.error('Error fetching sitemap:', error));
    }, []);

    return (
      <>
        {sitemapContent}
      </>
    );
};

export default SitemapPage;