import React, { useRef, useEffect, forwardRef } from 'react';
import './SecondaryButton.css'

const SecondaryButton = forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    const buttonRef = useRef(null);

    useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(buttonRef.current);
            } else {
                ref.current = buttonRef.current;
            }
        }
    }, [ref]);

    return (
        <button
            {...otherProps}
            ref={buttonRef}
            className={`secondary-button ${className || ''}`}
        >
            {props.children}
        </button>
    );
});

export default SecondaryButton;