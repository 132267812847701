import SiteConfig from 'configs/siteConfig';

const OPENAI_VECTOR_STORE_SERVICE_BASE_URL = `${SiteConfig.API_BASE_URL}/openai-vector-stores`;

const openAIVectorStoreService = {

    getVectorStoresForCurrentUser: async ({token}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to get openAI vector stores data.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    createVectorStoreForCurrentUser: async ({token, name, description, accessLevel}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_SERVICE_BASE_URL}/me`;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: name,
                    description: description,
                    access_level: accessLevel
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to create openAI vector store.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    updateVectorStoreForCurrentUser: async ({token, openAIVectorStoreId, name, description, expiresAfterDays, accessLevel, enableSketchEngineExtension}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_SERVICE_BASE_URL}/me/${openAIVectorStoreId}`;
        try {
            const response = await fetch(endpoint, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: name,
                    description: description,
                    expires_after_days: expiresAfterDays,
                    access_level: accessLevel,
                    enable_sketch_engine_extension: enableSketchEngineExtension
                })
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to update openAI vector store.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    },

    deleteVectorStoreForCurrentUser: async ({token, openAIVectorStoreId}) => {
        const endpoint = `${OPENAI_VECTOR_STORE_SERVICE_BASE_URL}/me/${openAIVectorStoreId}`;
        try {
            const response = await fetch(endpoint, {
                method: "DELETE",
                headers: {
                    "Content-Type": "text/plain",
                    "Authorization": `Bearer ${token}`
                }
            });
            return await response.json();
        } catch (error) {
            const result = {
                error: 'Fail to delete openAI vector store.',
                details: error.message
            };
            if (error instanceof SyntaxError) {
                result.error = 'Fail to parse response.';
            }
            return result;
        }
    }

}

export default openAIVectorStoreService;